<template>
	<f7-page name="labs">
		<!-- Top Navbar -->
		<f7-navbar>
			<div
				id="pageMenu"
				slot="before-inner">
				<f7-menu id="pageMenu">
					<f7-menu-item
						:animate="false"
						href="/"
						text="Análisis"></f7-menu-item>
					<f7-menu-item
						:animate="false"
						href="/labs/"
						current
						text="Laboratorios"></f7-menu-item>
					<f7-menu-item
						:animate="false"
						href="/params/"
						text="Parámetros"></f7-menu-item>
					<f7-menu-item
						:animate="false"
						href="/methods/"
						text="Métodos"></f7-menu-item>
				</f7-menu>
			</div>
			<div slot="right">
				<f7-link
					icon-only
					icon-f7="gear_alt"
					popover-open="#optionsPopover"></f7-link>
			</div>
		</f7-navbar>
		<!-- Toolbar-->
		<f7-toolbar bottom>
			<f7-link
				><img
					:src="'./static/logotipo.png'"
					height="40"
			/></f7-link>
			<span class="last_version">{{ lastVersion }}</span>
		</f7-toolbar>
		<!-- Toolbar-->
		<f7-popover
			id="optionsPopover"
			class="popover-menu"
			:backdrop="false">
			<f7-list>
				<f7-list-item
					@click="doLogout"
					link="#"
					popover-close
					title="Cerrar sessión"></f7-list-item>
			</f7-list>
		</f7-popover>
		<f7-popover
			id="actionsPopover"
			class="popover-menu"
			:backdrop="false">
			<f7-list>
				<f7-list-item
					popover-close
					title="Nuevo Análisis"></f7-list-item>
			</f7-list>
		</f7-popover>
		<!-- Toolbar-->
		<!-- Page content-->
		<f7-block strong>
			<f7-block-title>
				{{ title }}
			</f7-block-title>
			<f7-row>
				<f7-col width="5">
					<f7-segmented>
						<f7-button
							small
							title="Volver"
							icon-f7="arrow_left"
							back></f7-button>
					</f7-segmented>
				</f7-col>
				<f7-col width="90"></f7-col>
				<f7-col width="5">
					<f7-segmented>
						<f7-button
							small
							title="Guardar"
							form="editForm"
							type="submit"
							icon-f7="floppy_disk"></f7-button>
					</f7-segmented>
				</f7-col>
			</f7-row>
			<f7-block class="pageContent">
				<form
					id="editForm"
					@submit="sendForm">
					<f7-block-title> Información </f7-block-title>
					<f7-block>
						<f7-list no-hairlines>
							<f7-list-input
								label="Id lab."
								type="text"
								placeholder="Id del laboratorio"
								clear-button
								v-model:value="form_values.id_lab"></f7-list-input>
							<f7-list-input
								label="Nombre del laboratorio"
								type="text"
								placeholder="Nombre del laboratorio"
								clear-button
								v-model:value="form_values.name"></f7-list-input>
							<f7-list-input
								label="Nombre oficial"
								type="text"
								placeholder="Nombre oficial"
								clear-button
								v-model:value="form_values.oficial_name"></f7-list-input>
							<f7-list-input
								label="Nombre completo"
								type="text"
								placeholder="Nombre completo"
								clear-button
								v-model:value="form_values.fullname"></f7-list-input>
							<f7-list-item>
								<span>Estado</span>
								<f7-toggle v-model:checked="form_values.status"></f7-toggle>
							</f7-list-item>
						</f7-list>
					</f7-block>

					<f7-block
						v-if="form_values.contacts !== undefined && form_values.contacts.length > 0"
						v-for="(cnt, index) in form_values.contacts">
						<f7-block-title>Contacto {{ index + 1 }}</f7-block-title>
						<f7-list no-hairlines>
							<f7-list-input
								label="Persona de contacto"
								type="text"
								placeholder="Nombre de la persona"
								clear-button
								v-model:value="form_values.contacts[index].name"></f7-list-input>
							<f7-list-input
								label="Teléfono"
								type="tel"
								placeholder="Número de teléfono"
								clear-button
								v-model:value="form_values.contacts[index].phone"></f7-list-input>
							<f7-list-input
								label="Móvil"
								type="tel"
								placeholder="Número de movil"
								clear-button
								v-model:value="form_values.contacts[index].mobile"></f7-list-input>
							<f7-list-input
								label="Fax"
								type="tel"
								placeholder="Número de fax"
								clear-button
								v-model:value="form_values.contacts[index].fax"></f7-list-input>
							<f7-list-input
								label="Correo electrónico"
								type="email"
								placeholder="Correo electrónico"
								clear-button
								v-model:value="form_values.contacts[index].email"></f7-list-input>
							<f7-list-input
								label="Web"
								type="text"
								placeholder="Url web ej. https://..."
								clear-button
								v-model:value="form_values.contacts[index].web"></f7-list-input>

							<f7-list-item
								title="Principal"
								radio
								name="default"
								:checked="parseInt(form_values.contacts[index].primary) == 1"
								:value="parseInt(form_values.contacts[index].primary)"
								@change="ch"
								:data-index="index"></f7-list-item>
						</f7-list>
					</f7-block>

					<f7-block-title> Dirección </f7-block-title>
					<f7-block>
						<f7-list no-hairlines>
							<f7-list-input
								label="Dirección"
								type="text"
								placeholder="Dirección"
								clear-button
								v-model:value="form_values.address"></f7-list-input>
							<f7-list-input
								label="Ciudad"
								type="text"
								placeholder="Ciudad"
								clear-button
								v-model:value="form_values.city"></f7-list-input>
							<f7-list-input
								label="Próvincia"
								type="text"
								placeholder="Próvincia"
								clear-button
								v-model:value="form_values.state"></f7-list-input>
							<f7-list-input
								label="Código postal"
								type="text"
								placeholder="Código postal"
								clear-button
								v-model:value="form_values.postal_code"></f7-list-input>
							<f7-list-input
								label="País"
								type="text"
								placeholder="País"
								clear-button
								v-model:value="form_values.country"></f7-list-input>
						</f7-list>
					</f7-block>
				</form>
			</f7-block>
		</f7-block>
	</f7-page>
</template>
<script>
	import { f7, useStore } from "framework7-vue";
	import Ajax from "../../../js/classes/Ajax";

	export default {
		setup() {
			const doLogout = () => f7.store.dispatch("doLogout");
			const labs = useStore("getAllLabs");
			const getLabs = () => f7.store.dispatch("getAllLabs");

			const lastVersion = useStore("lastVersion");

			return {
				doLogout,
				labs,
				getLabs,
				lastVersion,
			};
		},
		data() {
			return {
				form_values: {},
				title: "",
			};
		},
		props: {
			id: null,
			action: null,
			f7route: Object,
			f7router: Object,
		},
		methods: {
			ch(e) {
				const self = this;
				const _this = e.target.closest("[data-index]");
				const data = _this.dataset;

				var vals = this.form_values;

				for (let i = 0; i < vals.contacts.length; i++) {
					if (i == data.index) {
						this.form_values.contacts[i].primary = 1;
						break;
					}
					this.form_values.contacts[i].primary = 0;
				}
			},
			async sendForm(e) {
				e.preventDefault();
				const values = this.form_values;

				if (this.action == "edit") {
					values.method = "PUT";
				} else {
					values.method = "POST";
				}

				if (values.role_name !== undefined) delete values.role_name;
				if (values.access !== undefined) delete values.access;

				try {
					const q = await Ajax(`${window.api.gab360}/customers/`).Post(values);
					if (q.data) f7.dialog.alert("", "Guardado", () => self.f7router.back());
				} catch (e) {
					console.error(e);
				}
				return false;
			},
		},
		mounted() {
			const self = this;
			const lab = self.labs[self.id];
			self.form_values = lab;

			const action = self.action;

			switch (action) {
				case "add":
					self.title = "Nuevo Laboratorio";
					self.form_values = {
						id_lab: "",
						name: "",
						oficial_name: "",
						fullname: "",
						status: false,
						contacts: [
							{
								name: "",
								phone: "",
								mobile: "",
								fax: "",
								email: "",
								web: "",
								primary: 1,
							},
							{
								name: "",
								phone: "",
								mobile: "",
								fax: "",
								email: "",
								web: "",
								primary: 0,
							},
							{
								name: "",
								phone: "",
								mobile: "",
								fax: "",
								email: "",
								web: "",
								primary: 0,
							},
						],
						contact: "",
						phone: "",
						mobile: "",
						fax: "",
						email: "",
						web: "",
						address: "",
						city: "",
						postal_code: "",
						country: "",
						state: "",
					};
					break;
				case "edit":
					self.title = `Editar Laboratorio ${lab.name}`;
					if (self.form_values.contacts.length < 1)
						self.form_values.contacts = [
							{
								name: "",
								phone: "",
								mobile: "",
								fax: "",
								email: "",
								web: "",
								primary: 1,
							},
							{
								name: "",
								phone: "",
								mobile: "",
								fax: "",
								email: "",
								web: "",
								primary: 0,
							},
							{
								name: "",
								phone: "",
								mobile: "",
								fax: "",
								email: "",
								web: "",
								primary: 0,
							},
						];
					break;
			}
		},
	};
</script>
