<template>
    <f7-page name="labs">
        <!-- Top Navbar -->
        <f7-navbar>
            <div id="pageMenu" slot="before-inner">
                <f7-menu id="pageMenu">
                    <f7-menu-item :animate="false" href="/" text="Análisis"></f7-menu-item>
                    <f7-menu-item :animate="false" href="/labs/" text="Laboratorios"></f7-menu-item>
                    <f7-menu-item :animate="false" href="/params/" current text="Parámetros"></f7-menu-item>
                    <f7-menu-item :animate="false" href="/methods/" text="Métodos"></f7-menu-item>
                </f7-menu>
            </div>
            <div slot="right">
                <f7-link icon-only icon-f7="gear_alt" popover-open="#optionsPopover"></f7-link>
            </div>
        </f7-navbar>
        <!-- Toolbar-->
        <f7-toolbar bottom>
            <f7-link><img :src="'./static/logotipo.png'" height="40"></f7-link>
            <span class="last_version">{{lastVersion}}</span>
        </f7-toolbar>
        <!-- Toolbar-->
        <f7-popover id="optionsPopover" class="popover-menu" :backdrop="false">
            <f7-list>
                <f7-list-item @click="doLogout" link="#" popover-close title="Cerrar sessión"></f7-list-item>
            </f7-list>
        </f7-popover>
        <!-- Toolbar-->
        <!-- Page content-->
        <f7-block strong>
            <f7-block-title>
                Listado de parámetros
            </f7-block-title>
            <f7-block-header class="">
                <f7-row>
                    <f7-col width="90">
                        <f7-searchbar
                            class="page-searchbar"
                            search-container=".search-list"
                            search-in=".item-title"
                            :disable-button="false"
                            :no-hairline="true"
                        ></f7-searchbar>
                    </f7-col>
                    <f7-col width="5">
                        <f7-segmented>
                            <f7-button small title="Refrescar" @click="reload" icon-material="autorenew"></f7-button>
                        </f7-segmented>
                    </f7-col>
                    <f7-col width="5">
                        <f7-segmented>
                            <f7-button small title="Nuevo laboratorio" icon-material="add" :animate="false" href="./add/"></f7-button>
                        </f7-segmented>
                    </f7-col>
                </f7-row>
                <f7-row class="table-header">
                    <f7-col width="90">
                        Nombre
                    </f7-col>
                    <f7-col width="10"></f7-col>
                </f7-row>
            </f7-block-header>
            <f7-block class="pageContent" style="height: calc(100% - 100px);">
                 <f7-list class="searchbar-not-found">
                    <f7-list-item title="Resultados no enctontrados"></f7-list-item>
                </f7-list>
                <f7-list class="search-list searchbar-found sortableSort mt-0" media-list>
                    <f7-list-item v-for="(item, index) in params">
                        <f7-row>
                            <f7-col class="item-title" width="90">
                                {{item.id}} {{item.name}}
                            </f7-col>
                            <f7-col width="10">
                                <f7-segmented>
                                    <f7-button title="Editar" icon-material="edit" :animate="false" :href="`./edit/${index}/`"></f7-button>
                                    <f7-button @click="delete"  :data-id="item.id" title="Eliminar" icon-material="delete"></f7-button>
                                </f7-segmented>
                            </f7-col>
                        </f7-row>
                    </f7-list-item>
                </f7-list>
            </f7-block>
        </f7-block>
    </f7-page>
</template>
<script>
    import {
        f7,
        useStore
    }
    from 'framework7-vue';
    export default {
        setup() {
            const doLogout = () => f7.store.dispatch( 'doLogout' );
            const params = useStore( 'getParams' );
            const getParams = () => f7.store.dispatch( 'getParams' );
            const preloader = ( status ) => f7.store.dispatch( 'preloader', status );
            const lastVersion = useStore('lastVersion');
            return {
                doLogout,
                preloader,
                params,
                getParams,
                lastVersion
            }
        },
        data() {
            return {
                current: null,
            }
        },
        props: {
            id: null,
            f7route: Object,
            f7router: Object
        },
        methods: {
            selectCurrent( e ) {
                const self = this;
                const _this = e.target.closest( '.options' );
                const index = parseInt( _this.dataset.index );
                self.current = index;
            },
            reload() {
                const self = this;
                    self.getParams();
            },
            delete(e) {
                const self = this;
                const _this = e.target.closest('[data-id]');
                const id = parseInt(_this.dataset.id);

                f7.dialog.confirm('¿Estás seguro?<br>Esta acción es irreversible.', '¡ALERTA!', () => {

                    f7.store.dispatch('ajax', {action : 'deleteParam', id : id}).then((result) => {
                        if (result.code == 200)
                            f7.dialog.alert('¡Eliminado correctamente!', 'ELIMINAR', () => self.f7router.refreshPage());
                        else
                            f7.dialog.alert(result.msg);
                    })

                }, () => false);
            }
        },
        mounted() {
            const self = this;
                self.getParams();
        }
    }
</script>