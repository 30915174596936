import { createStore } from "framework7/lite";
import { f7 } from "framework7-vue";

// GLOBALS
function countDecimals(num) {
	return -Math.floor(Math.log10(num) + 1) + 1;
}

function fixDecimals(num) {
	return parseFloat(num.toPrecision(12));
}

function round(number, decimal) {
	const factorOfTen = Math.pow(10, decimal);
	const r = Math.round((number + Number.EPSILON) * factorOfTen) / factorOfTen;
	return r.toFixed(decimal);
}

function fn_mediana(values) {
	if (values.length === 0) return 0;

	values.sort(function (a, b) {
		return a - b;
	});

	var half = Math.floor(values.length / 2);

	if (values.length % 2) return values[half];

	return (values[half - 1] + values[half]) / 2.0;
}

function fn_promedio(values) {
	// Clean 0
	var clean = [];
	var total_sum = 0;

	for (var i = 0; i < values.length; i++) {
		if (values[i] !== "" && !Number.isNaN(values[i])) {
			clean.push(values[i]);
			total_sum += parseFloat(values[i]);
		}
	}

	// Length
	length = clean.length;

	// Promedio
	return parseFloat(total_sum) / parseInt(length);
}

function fn_desvest(values) {
	const prom = fn_promedio(values); // μ

	var prom_arr = [];
	var sum = 0;

	for (var i = 0; i < values.length; i++) {
		var v = (values[i] - prom) * (values[i] - prom); // ∣x−μ∣2
		sum += parseFloat(v); // ∑∣x−μ∣ 2
		prom_arr.push(v);
	}

	return Math.sqrt(sum / (values.length - 1)); // v/ (∣x−μ∣2)/2
}

function replaceString(r_params, string) {
	if (typeof r_params !== "object") return false;

	for (let find in r_params) {
		if (string.indexOf(find) !== -1) string = string.replace(new RegExp(`${find}`, "ig"), r_params[find]);
	}

	return string;
}

function findKeyInObject(array, param, value) {
	//
	return Object.keys(array).find((key) => (array[key].params !== undefined ? array[key].params[param] == value : 0));
}
function findKeyInObject_s(array, value) {
	//
	return Object.keys(array).find((key) => (array[key] !== undefined ? array[key] == value : 0));
}

const calc = createStore({
	state: {
		samples: null,
		samples_clean: null,
		samples_clean_sorted: null,
		samples_clean_novars: null,
		medianas: null, // fn_mediana( samples )
		abs: null, // ABS( samples - fn_mediana( samples ) )
		medianas_abs: null, // fn_mediana( abs )
		disc: null, // abs/mediana_abs
		ce: null, //
		calcs: null, //
		calcs_zscore: null, //
		config: {
			"Grado Alcohólico": {
				format: "%vol",
				decimals: 2,
			},
			pH: {
				format: "-",
				decimals: 2,
			},
			"Densidad Relativa": {
				format: "%vol",
				decimals: 5,
			},
			"Masa Volúmica": {
				format: "%vol",
				decimals: 5,
			},
			AV: {
				format: "%vol",
				decimals: 2,
			},
			"Ac. Acético": {
				format: "%vol",
				decimals: 2,
			},
			ATT: {
				format: "%vol",
				decimals: 2,
			},
			"Mat. Reductoras": {
				format: "%vol",
				decimals: 2,
			},
			"Gluc/Fruc": {
				format: "%vol",
				decimals: 2,
			},
			"L-Málico": {
				format: "g/l",
				decimals: 2,
			},
			"L-Láctico": {
				format: "%vol",
				decimals: 2,
			},
			Glucónico: {
				format: "%vol",
				decimals: null,
			},
			"NH4-Amonio": {
				format: "mg/l",
				decimals: null,
			},
			"NH2-PAN": {
				format: "%vol",
				decimals: null,
			},
			NFA: {
				format: "mg/l",
				decimals: null,
			},
		},
		units: [
			["kg/cm3", "hg/cm3", "dag/cm3", "g/cm3", "da/cm3", "cg/cm3", "mg/cm3"],
			["kg/l", "hg/l", "dag/l", "g/l", "da/l", "cg/l", "mg/l"],
		],
		dr: 1.0018,
		k: 3,
		min_mad: 0.001,
		ats: 1.53,
		tstudent: {
			1: "12.70620474",
			2: "4.30265273",
			3: "3.182446305",
			4: "2.776445105",
			5: "2.570581836",
			6: "2.446911851",
			7: "2.364624252",
			8: "2.306004135",
			9: "2.262157163",
			10: "2.228138852",
			11: "2.20098516",
			12: "2.17881283",
			13: "2.160368656",
			14: "2.144786688",
			15: "2.131449546",
			16: "2.119905299",
			17: "2.109815578",
			18: "2.10092204",
			19: "2.093024054",
			20: "2.085963447",
			21: "2.079613845",
			22: "2.073873068",
			23: "2.06865761",
			24: "2.063898562",
			25: "2.059538553",
			26: "2.055529439",
			27: "2.051830516",
			28: "2.048407142",
			29: "2.045229642",
			30: "2.042272456",
			31: "2.039513446",
			32: "2.036933343",
			33: "2.034515297",
			34: "2.032244509",
			35: "2.030107928",
			36: "2.028094001",
			37: "2.026192463",
			38: "2.024394164",
			39: "2.02269092",
			40: "2.02107539",
			41: "2.01954097",
			42: "2.018081703",
			43: "2.016692199",
			44: "2.015367574",
			45: "2.014103389",
			46: "2.012895599",
			47: "2.011740514",
			48: "2.010634758",
			49: "2.009575237",
			50: "2.008559112",
			51: "2.00758377",
			52: "2.006646805",
			53: "2.005745995",
			54: "2.004879288",
			55: "2.004044783",
			56: "2.003240719",
			57: "2.002465459",
			58: "2.001717484",
			59: "2.000995378",
			60: "2.000297822",
			61: "1.999623585",
			62: "1.998971517",
			63: "1.998340543",
			64: "1.997729654",
			65: "1.997137908",
			66: "1.996564419",
			67: "1.996008354",
			68: "1.995468931",
			69: "1.994945415",
			70: "1.994437112",
			71: "1.993943368",
			72: "1.993463567",
			73: "1.992997126",
			74: "1.992543495",
			75: "1.992102154",
			76: "1.99167261",
			77: "1.991254395",
			78: "1.990847069",
			79: "1.99045021",
			80: "1.990063421",
			81: "1.989686323",
			82: "1.989318557",
			83: "1.98895978",
			84: "1.988609667",
			85: "1.988267907",
			86: "1.987934206",
			87: "1.987608282",
			88: "1.987289865",
			89: "1.9869787",
			90: "1.986674541",
			91: "1.986377154",
			92: "1.986086317",
			93: "1.985801814",
			94: "1.985523442",
			95: "1.985251004",
			96: "1.984984312",
			97: "1.984723186",
			98: "1.984467455",
			99: "1.984216952",
			100: "1.983971519",
			101: "1.983731003",
			102: "1.983495259",
			103: "1.983264145",
			104: "1.983037526",
			105: "1.982815274",
			106: "1.982597262",
			107: "1.98238337",
			108: "1.982173483",
			109: "1.98196749",
			110: "1.981765282",
			111: "1.981566757",
			112: "1.981371815",
			113: "1.981180359",
			114: "1.980992298",
			115: "1.980807541",
			116: "1.980626002",
			117: "1.980447599",
			118: "1.980272249",
			119: "1.980099876",
			120: "1.979930405",
		},
		vars: null,
		labs: null,
		params: null,
		heads: null,
		formatted: false,
		ce_meds: null,
		n_labs: null,
		sample_labs: {},
	},
	getters: {
		getS({ state }) {
			return state.samples;
		},
		getSamples({ state }) {
			return state.samples_clean;
		},
		getMedianas({ state }) {
			return state.medianas;
		},
		getAbs({ state }) {
			return state.abs;
		},
		getMedianasAbs({ state }) {
			return state.medianas_abs;
		},
		getDisc({ state }) {
			return state.disc;
		},
		getCE({ state }) {
			return state.ce;
		},
		getCalcs({ state }) {
			return state.calcs;
		},
		getZScore({ state }) {
			return state.calcs_zscore;
		},
		getHeads({ state }) {
			return state.heads;
		},
		getVars({ state }) {
			return state.vars;
		},
		getCeMeds({ state }) {
			return state.ce_meds;
		},
		getK({ state }) {
			return state.k;
		},
		getNLabs({ state }) {
			return state.n_labs;
		},
	},
	actions: {
		// FORMAT
		formatSamples({ state, dispatch }) {
			const self = state;

			const samples = self.samples;
			const config = self.config;
			const params = self.params;
			const units = self.units;
			const DR = self.dr;
			const ats = self.ats;
			const vars = self.vars;

			var new_samples = [];
			var n_labs = {};

			const getUnits = (unit) => {
				var target_index = units.findIndex((a) => {
					return a.includes(unit);
				});

				if (target_index < 0) return [];

				var target = units[target_index];

				var pos = target.findIndex((a) => {
					return a == unit;
				});

				var range = {};

				var max = Math.pow(10, pos);

				for (var i = 0; i < target.length; i++) {
					range[target[i]] = max;
					max = parseFloat(max) / 10;
				}

				return range;
			};

			var prm = {};
			for (var order in params) {
				var par_id = params[order];
				var k = Object.keys(config).find((key) => config[key].id == par_id);
				var cn = config[k];
				cn.order = order;
				prm[order] = cn;
			}

			for (var i = 0; i < samples.length; i++) {
				var tmp = {};
				var smpl = { ...samples[i] };
				var cnf = { ...prm };
				var to_replace = { "{dr}": DR };
				const mass = vars.mass !== undefined && vars.mass[i] !== undefined && `${vars.mass[i]}` == "true" ? true : false;
				const att = vars.att ? (vars.att[i] && `${vars.att[i]}` == "true" ? true : false) : true;

				tmp["name"] = smpl.name;
				delete smpl.name;
				tmp["id_lab"] = smpl.id_lab;
				delete smpl.id_lab;

				for (var param_order in smpl) {
					var c = smpl[param_order];
					var current_conf = c.params;
					var current_conf_format = current_conf.format;
					var order = current_conf.order;
					var unit_conv = getUnits(current_conf_format);
					var mediana = 0;
					var v1 = parseFloat(c.rep1.value);
					var v2 = parseFloat(c.rep2.value);
					var v1_unit = c.rep1.unit;
					var v2_unit = c.rep2.unit;

					// SIMPLE PARAMS
					if (Number.isNaN(v1)) {
						mediana = NaN;
						if (!Number.isNaN(v2)) {
							mediana = v2;

							if (v2_unit != current_conf_format && current_conf_format !== "") {
								if (v2_unit == "g/l ATS") mediana = v2 * ats;
								else mediana = v2 * parseFloat(unit_conv[v2_unit] !== undefined ? unit_conv[v2_unit] : 1);
							}
						}
					} else {
						mediana = v1;

						if (v1_unit != current_conf_format && current_conf_format !== "") {
							if (v1_unit == "g/l ATS") mediana = v1 * ats;
							else mediana = v1 * parseFloat(unit_conv[v1_unit] !== undefined ? unit_conv[v1_unit] : 1);
						}

						if (!Number.isNaN(v2)) {
							if (v1_unit != current_conf_format && current_conf_format !== "") {
								if (v1_unit == "g/l ATS") mediana = v1 * ats;
								else v1 = v1 / parseFloat(unit_conv[v1_unit] ? unit_conv[v1_unit] : 1);
							}

							if (v2_unit != current_conf_format && current_conf_format !== "") {
								if (v2_unit == "g/l ATS") mediana = v2 * ats;
								else v2 = v2 / parseFloat(unit_conv[v2_unit] ? unit_conv[v2_unit] : 1);
							}
							//console.log(v1, v2)
							mediana = fn_mediana([v1, v2]);
						}
					}

					// PUT INTO TMP
					tmp[order] = {
						value: mediana,
						rounded: round(mediana, current_conf.decimals),
						params: current_conf,
						mass: mass,
						att: att,
						unit: v1_unit,
						raw: c.rep1.value,
						method: c.method,
					};

					// PUT INTO REPLACE ARRAY
					to_replace[`{id${current_conf.id}}`] = mediana;

					// IF HAS VALUE REMOVE FROM PARAMS
					if (!Number.isNaN(mediana)) delete cnf[param_order];

					if (n_labs[order] === undefined) n_labs[order] = [];

					n_labs[order].push(v1);
				}

				// PARAMS -> DR/MV
				var param_3 = findKeyInObject(tmp, "id", 3);
				var param_14 = findKeyInObject(tmp, "id", 14);

				if (param_3 && param_14 && !Number.isNaN(tmp[param_14].value) && Number.isNaN(tmp[param_3].value)) {
					var calc_3 = parseFloat(tmp[param_14].value) / parseFloat(DR);
					var calc_14 = parseFloat(tmp[param_14].value) * parseFloat(DR);
					var mth = tmp[param_14].method;

					if (mass) {
						// 14 -> 3
						tmp[param_3].value = calc_3;
						tmp[param_3].rounded = round(calc_3, tmp[param_3].params.decimals);
						tmp[param_3].raw = calc_3;
					} else {
						// 14 -> 3 -> 14
						tmp[param_3].value = tmp[param_14].value;
						tmp[param_3].rounded = round(tmp[param_14].value, tmp[param_14].params.decimals);
						tmp[param_3].raw = tmp[param_14].value;

						tmp[param_14].value = calc_14;
						tmp[param_14].rounded = round(calc_14, tmp[param_14].params.decimals);
						tmp[param_14].method = "";
						tmp[param_3].method = mth;
					}
				}

				if (param_3 && param_14 && !Number.isNaN(tmp[param_3].value) && Number.isNaN(tmp[param_14].value)) {
					var calc_3 = parseFloat(tmp[param_3].value) / parseFloat(DR);
					var calc_14 = parseFloat(tmp[param_3].value) * parseFloat(DR);
					var mth = tmp[param_3].method;

					if (mass) {
						// 3 -> 14 -> 3
						tmp[param_14].value = tmp[param_3].value;
						tmp[param_14].rounded = round(tmp[param_3].value, tmp[param_3].params.decimals);
						tmp[param_14].raw = tmp[param_3].value;

						tmp[param_3].value = calc_3;
						tmp[param_3].rounded = round(calc_3, tmp[param_3].params.decimals);

						tmp[param_3].method = "";
						tmp[param_14].method = mth;
					} else {
						// 3 -> 14
						tmp[param_14].value = calc_14;
						tmp[param_14].rounded = round(calc_14, tmp[param_14].params.decimals);
						tmp[param_14].raw = calc_14;

						//tmp[param_14].method = prm_tmp[param_3].method;
						//tmp[param_3].method = "";
					}
				}

				if (cnf[param_3] !== undefined) delete cnf[param_3];
				if (cnf[param_14] !== undefined) delete cnf[param_14];

				// PARAMS -> ATT/ATS
				var param_6 = findKeyInObject(tmp, "id", 6);
				var param_29 = findKeyInObject(tmp, "id", 29);

				if (param_6 && param_29) {
					const has_param_6 = !Number.isNaN(parseFloat(tmp[param_6].value));
					const has_param_29 = !Number.isNaN(parseFloat(tmp[param_29].value));

					// ATT is given
					if (has_param_6) {
						const param_6_value = parseFloat(tmp[param_6].value);

						if (att) {
							// Is ATT
							tmp[param_6].value = param_6_value;
							tmp[param_6].rounded = round(param_6_value, tmp[param_6].params.decimals);
							tmp[param_6].raw = tmp[param_6].value;

							tmp[param_29].value = param_6_value / 1.53;
							tmp[param_29].rounded = round(param_6_value / 1.53, tmp[param_29].params.decimals);
							tmp[param_29].raw = tmp[param_29].value;
						} else {
							// Is ATS
							// Move ATT value to ATS
							tmp[param_29].value = param_6_value;
							tmp[param_29].rounded = round(param_6_value, tmp[param_6].params.decimals);
							tmp[param_29].raw = tmp[param_6].value;

							// Calc and set ATT
							tmp[param_6].value = param_6_value * 1.53;
							tmp[param_6].rounded = round(param_6_value * 1.53, tmp[param_6].params.decimals);
							tmp[param_6].raw = tmp[param_6].value * 1.53;
						}
					}

					// ATS is given
					if (has_param_29) {
						const param_29_value = parseFloat(tmp[param_29].value);

						// Calc and set ATT
						tmp[param_6].value = param_29_value * 1.53;
						tmp[param_6].rounded = round(param_29_value * 1.53, tmp[param_6].params.decimals);
						tmp[param_6].raw = tmp[param_29].value * 1.53;
					}
				}

				if (cnf[param_6] !== undefined) delete cnf[param_6];
				if (cnf[param_29] !== undefined) delete cnf[param_29];

				// CALCULATED PARAMS
				for (let k in cnf) {
					var c = cnf[k];
					if (c.is_calc == 1) {
						var vl = replaceString(to_replace, c.calc);
						vl = parseFloat(eval(vl));

						tmp[c.order] = {
							value: Number.isNaN(vl) ? NaN : vl,
							rounded: Number.isNaN(vl) ? NaN : round(vl, c.decimals),
							params: c,
							mass: mass,
							att: att,
							raw: Number.isNaN(vl) ? "" : vl,
						};

						if (!Number.isNaN(vl)) n_labs[k].push(vl);
					}
				}

				// PUSH
				new_samples.push(tmp);
			}

			// console.log(new_samples)

			self.n_labs = n_labs;
			self.samples_clean = new_samples;
			self.formatted = true;
		},

		// SET
		setConfig({ state }, sConfig) {
			var nConfig = {};
			for (let id_config in sConfig) {
				nConfig[id_config] = {
					id: sConfig[id_config].id,
					order: sConfig[id_config].p_order,
					name: sConfig[id_config].name,
					name_en: sConfig[id_config].name_en,
					name_fr: sConfig[id_config].name_fr,
					shortname: sConfig[id_config].shortname,
					name_cert: sConfig[id_config].name_cert,
					format: sConfig[id_config].unit,
					decimals: sConfig[id_config].decimals,
					calc: sConfig[id_config].calc,
					is_calc: sConfig[id_config].is_calc,
				};
			}

			state.config = nConfig;
		},
		setParams({ state }, params) {
			state.params = params;
		},
		setSampleLabs({ state }, params) {
			state.sample_labs = params;
		},
		setSamples({ state }, samples) {
			const labs = state.labs;
			var nConfig = state.config;
			var params = state.params;
			var nSamples = [];
			var sLabs = state.sample_labs;
			//samples = {...samples};

			var tmp_s = {};

			for (let x in sLabs) {
				if (samples[sLabs[x]] !== undefined) {
					samples[sLabs[x]].id = sLabs[x];
					tmp_s[x] = samples[sLabs[x]];
				}
			}

			var n = 1;
			for (let x in tmp_s) {
				let id_lab = tmp_s[x].id;
				const current_lab_sample = samples[id_lab];
				const lab_key = Object.keys(labs).find((key) => labs[key].id === parseInt(id_lab));

				if (Object.keys(current_lab_sample).length > 0) {
					if (lab_key !== undefined) {
						var tmp = {};
						tmp["name"] = labs[lab_key].name;
						tmp["id_lab"] = id_lab;

						for (let order in params) {
							const current_conf = nConfig[params[order]];
							const current_conf_id = current_conf.id;

							if (samples[id_lab][current_conf_id] !== undefined) {
								if (tmp[order] === undefined) tmp[order] = { ...samples[id_lab][current_conf_id] };

								tmp[order]["params"] = current_conf;
							} else {
								tmp[order] = {
									rep1: { value: "", unit: current_conf.format },
									rep2: { value: "", unit: current_conf.format },
									media: "",
									method: "", //( current_conf_id == 14 ? samples[id_lab][3].method : '' )
									params: current_conf,
								};
							}
						}

						nSamples.push(tmp);
					}
				}
			}
			//console.log(nSamples);
			state.samples = nSamples;
		},
		setVars({ state }, vars) {
			state.vars = vars;
		},
		setLabs({ state }, labs) {
			state.labs = labs;
		},

		// CALC
		calcResults({ state, dispatch }) {
			const self = state;

			dispatch("formatSamples");

			dispatch("calcMedianas");
			dispatch("calcAbs");
			dispatch("calcMedianasAbs");
			dispatch("calcDisc");

			const samples = self.samples_clean;
			const K = self.k;
			const vars = self.vars.ignore || {};

			// GET VALUES ARRAY
			var array_values = {};
			var array_by_lab = {};
			var array_calc = {};

			// ORDER AND GET SAMPLES ARE NOT IGNORED OR NAN
			for (let i = 0; i < samples.length; i++) {
				for (let id_param in samples[i]) {
					if (id_param !== "name" && id_param !== "id_lab") {
						if (samples[i][id_param].params.id != 29) {
							if (array_values[id_param] === undefined) array_values[id_param] = {};
							if (array_values[id_param]["samples"] === undefined) array_values[id_param]["samples"] = [];
							if (array_values[id_param]["abs"] === undefined) array_values[id_param]["abs"] = [];
							if (array_values[id_param]["disc"] === undefined) array_values[id_param]["disc"] = [];

							if (array_calc[id_param] === undefined) array_calc[id_param] = {};
							if (array_calc[id_param]["samples"] === undefined) array_calc[id_param]["samples"] = [];
							if (array_calc[id_param]["abs"] === undefined) array_calc[id_param]["abs"] = [];
							if (array_calc[id_param]["disc"] === undefined) array_calc[id_param]["disc"] = [];

							var ignore = vars[id_param] !== undefined && vars[id_param][i] !== undefined ? (`${vars[id_param][i]}` === "true" ? true : false) : false;

							if (samples[i][id_param].params !== undefined) {
								array_values[id_param]["samples"].push({
									id: i,
									name: samples[i].name,
									id_lab: samples[i].id_lab,

									order: parseInt(i) + 1,

									value: samples[i][id_param].value,
									rounded: round(parseFloat(samples[i][id_param].value), samples[i][id_param].params.decimals),

									ignore: ignore,
									raw: samples[i][id_param].raw,
									method: samples[i][id_param].method,
								});

								if (!ignore && !Number.isNaN(samples[i][id_param].value)) array_calc[id_param]["samples"].push(samples[i][id_param].value);

								if (samples[i][id_param].params !== undefined) array_values[id_param]["params"] = { ...samples[i][id_param].params };
							}
						}
					}
				}
			}

			// SAMPLES MEDIANA
			for (let id_param in array_calc) {
				var med = fn_mediana(array_calc[id_param].samples);
				array_calc[id_param]["mediana"] = {
					value: med,
					rounded: round(med, array_values[id_param].params.decimals),
				};
			}

			// ABS
			for (let id_param in array_values) {
				for (let x = 0; x < array_values[id_param].samples.length; x++) {
					var value = Math.abs(parseFloat(array_values[id_param].samples[x].value) - parseFloat(array_calc[id_param]["mediana"].value));
					value = fixDecimals(value);
					var ignore = array_values[id_param].samples[x].ignore;
					array_values[id_param].abs.push({
						id: array_values[id_param].samples[x].id,
						name: array_values[id_param].samples[x].name,

						value: value,
						rounded: round(value, 4),

						ignore: ignore,
					});

					if (!ignore && !Number.isNaN(array_values[id_param].samples[x].value)) array_calc[id_param]["abs"].push(value);
				}
			}

			// MAD
			for (let id_param in array_calc) {
				var abs = fn_mediana(array_calc[id_param].abs);
				abs = abs == 0 ? self.min_mad : abs;
				abs = fixDecimals(abs);

				var dc = countDecimals(abs);
				dc = dc > 0 ? dc : array_values[id_param].params.decimals;

				array_calc[id_param]["mad"] = {
					value: abs,
					rounded: round(abs, dc),
				};
			}

			// DISCREPANT
			for (let id_param in array_values) {
				for (let x = 0; x < array_values[id_param].abs.length; x++) {
					const abs = parseFloat(array_values[id_param].abs[x].value);
					const mad = parseFloat(array_calc[id_param]["mad"].value);
					const res = abs / mad;
					var value = abs == 0 ? 0 : res;

					array_values[id_param].disc.push({
						id: array_values[id_param].abs[x].id,
						name: array_values[id_param].abs[x].name,

						value: value,
						rounded: round(value, 2),

						disc: value >= K,

						ignore: array_values[id_param].abs[x].ignore,
					});
				}
			}

			// SORT
			for (let x in array_values) {
				var array_s = [...array_values[x].samples];
				var array_a = [...array_values[x].abs];
				var array_d = [...array_values[x].disc];

				const s = (a, b) => a.value - b.value || isNaN(a.value) - isNaN(b.value);

				array_s.sort(s);

				const sorter = (a, b) => {
					return array_s.findIndex((x) => x.name == a.name) - array_s.findIndex((x) => x.name == b.name);
				};

				array_a.sort(sorter);
				array_d.sort(sorter);

				array_values[x]["sorted"] = array_s;
				array_values[x].abs = array_a;
				array_values[x].disc = array_d;
			}

			// RETURN
			state.ce = array_values;
			state.ce_meds = array_calc;

			dispatch("calcCE");

			// HEADS
			var heads = [];

			samples.forEach((sample, index) => {
				if (index == 0) {
					for (let id_param in sample) {
						if (id_param !== "name" && id_param !== "id_lab") {
							heads.push({
								id: sample[id_param].params.id,
								name: sample[id_param].params.shortname,
							});
						}
					}
				}
			});
			//for (let el in array_values) {
			//    if (array_values[el].params !== undefined)
			//        heads.push({
			//            id : array_values[el].params.id,
			//            name : array_values[el].params.shortname
			//        });
			//}

			self.heads = heads;
		},
		calcCE({ state, dispatch }) {
			const self = state;
			const ce = self.ce;
			const tstudent_array = self.tstudent;
			const vars = self.vars;
			const n_labs = Object.keys(self.sample_labs).length;

			var clean_lab_array = {};
			var base_lab_array = {};
			var raw_lab_array = {};
			var params_array = {};

			for (let n in ce) {
				var tmp1 = {};
				var tmp1_1 = {};
				var tmp1_2 = {};

				for (let x in ce[n]) {
					if (ce[n][x].length) {
						var tmp2 = [];
						var tmp2_1 = [];
						var tmp2_2 = [];
						for (var i = 0; i < ce[n][x].length; i++) {
							if (!Number.isNaN(ce[n][x][i].value)) tmp2_1.push(ce[n][x][i].value);

							if (!ce[n][x][i].ignore && !Number.isNaN(ce[n][x][i].value)) tmp2.push(ce[n][x][i].value);

							if (ce[n][x][i].raw && ce[n][x][i].raw !== "") tmp2_2.push(ce[n][x][i].value);
						}
						tmp1[x] = tmp2;
						tmp1_1[x] = tmp2_1;
						tmp1_2[x] = tmp2_2;
					}
				}

				clean_lab_array[n] = tmp1;
				base_lab_array[n] = tmp1_1;
				raw_lab_array[n] = tmp1_2;
				params_array[n] = ce[n].params.id;
			}

			var calcs = {};
			for (let x in clean_lab_array) {
				var prom = fn_promedio(clean_lab_array[x].sorted);
				var o = fn_desvest(clean_lab_array[x].sorted);
				var o2 = o * o;
				var labs = raw_lab_array[x].samples.length; // no deleted
				var p = clean_lab_array[x].disc.length;
				var alfa = 0.05;
				var o2p = parseFloat(o2) / parseInt(p);
				var raiz = Math.sqrt(o2p);
				var p1 = parseInt(p) - 1;
				var tstudent = tstudent_array[p1];
				var uKu = parseFloat(raiz) * parseFloat(tstudent);

				var sigma1_1 = parseFloat(prom) - parseFloat(o);
				var sigma1_2 = parseFloat(prom) + parseFloat(o);

				var sigma2 = parseFloat(o) * 2;
				var sigma2_1 = parseFloat(prom) - parseFloat(o) * 2;
				var sigma2_2 = parseFloat(prom) + parseFloat(o) * 2;

				calcs[x] = {
					id_param: params_array[x],
					promedio: {
						name: "Promedio = Valor asignado",
						value: prom,
						rounded: round(prom, ce[x].params.decimals > 4 ? 4 : ce[x].params.decimals),
						strong: true,
					},
					o: {
						name: "σ = Desvest (Suma d'incerteses patró+labs+metodes+…)",
						value: o,
						rounded: round(o, ce[x].params.decimals > 4 ? 4 : ce[x].params.decimals),
						strong: false,
					},
					o2: {
						name: "σ2",
						value: o2,
						rounded: round(o2, ce[x].params.decimals > 4 ? 4 : ce[x].params.decimals),
						strong: false,
					},
					labs: {
						name: "Labs participantes",
						value: labs + ` (${round((parseInt(p) * 100) / parseInt(labs), 1)}%)`,
						rounded: labs + ` (${round((parseInt(p) * 100) / parseInt(labs), 1)}%)`,
						strong: false,
					},
					p: {
						name: "P = Labs sobrevivientes NO eliminados o aceptados per el TEST Mediana Robusta",
						value: p,
						rounded: p,
						strong: true,
					},
					alfa: {
						name: "Alfa=95%",
						value: alfa,
						rounded: round(alfa, ce[x].params.decimals > 4 ? 4 : ce[x].params.decimals),
						strong: false,
					},
					o2p: {
						name: "σ2/P",
						value: o2p,
						rounded: o2p > 1000 || o2p < 0.0001 ? o2p.toExponential(1) : round(parseFloat(o2p), ce[x].params.decimals > 4 ? 4 : ce[x].params.decimals),
						strong: false,
					},
					raiz: {
						name: "raiz()",
						value: raiz,
						rounded: raiz > 1000 || raiz < 0.0001 ? raiz.toExponential(1) : round(parseFloat(raiz), ce[x].params.decimals > 4 ? 4 : ce[x].params.decimals),
						strong: false,
					},
					p1: {
						name: "P-1",
						value: p1,
						rounded: p1,
						strong: false,
					},
					tstudent: {
						name: "t student 95% P-1",
						value: tstudent,
						rounded: round(parseFloat(tstudent), ce[x].params.decimals > 4 ? 4 : ce[x].params.decimals),
						strong: false,
					},
					uKu: {
						name: "U=+/-… (U=Ku)?",
						value: uKu,
						rounded: round(uKu, ce[x].params.decimals > 4 ? 4 : ce[x].params.decimals),
						strong: true,
					},
					sigma2: {
						name: "2*σ (95%)=margen de aceptación",
						value: sigma2,
						rounded: round(sigma2, ce[x].params.decimals > 4 ? 4 : ce[x].params.decimals),
						strong: true,
					},
					sigma1_1: {
						name: "- σ",
						value: sigma1_1,
						rounded: round(sigma1_1, ce[x].params.decimals > 4 ? 4 : ce[x].params.decimals),
						strong: false,
					},
					sigma1_2: {
						name: "+ σ",
						value: sigma1_2,
						rounded: round(sigma1_2, ce[x].params.decimals > 4 ? 4 : ce[x].params.decimals),
						strong: false,
					},
					sigma2_1: {
						name: "- 2*σ",
						value: sigma2_1,
						rounded: round(sigma2_1, ce[x].params.decimals > 4 ? 4 : ce[x].params.decimals),
						strong: true,
					},
					sigma2_2: {
						name: "+ 2*σ",
						value: sigma2_2,
						rounded: round(sigma2_2, ce[x].params.decimals > 4 ? 4 : ce[x].params.decimals),
						strong: true,
					},
					sigma: {
						param: ce[x].params.name,
						params: ce[x].params,
						promedio: {
							value: prom,
							rounded: round(prom, ce[x].params.decimals > 4 ? 4 : ce[x].params.decimals),
							strong: false,
						},
						uKu: {
							value: uKu,
							rounded: round(uKu, ce[x].params.decimals > 4 ? 4 : ce[x].params.decimals),
							strong: false,
						},
						n: p,
						o: {
							s: {
								value: sigma1_2,
								rounded: round(sigma1_2, ce[x].params.decimals > 4 ? 4 : ce[x].params.decimals),
							},
							d: {
								value: sigma1_1,
								rounded: round(sigma1_1, ce[x].params.decimals > 4 ? 4 : ce[x].params.decimals),
							},
						},
						o2: {
							s: {
								value: sigma2_2,
								rounded: round(sigma2_2, ce[x].params.decimals > 4 ? 4 : ce[x].params.decimals),
							},
							d: {
								value: sigma2_1,
								rounded: round(sigma2_1, ce[x].params.decimals > 4 ? 4 : ce[x].params.decimals),
							},
							r: {
								value: (sigma2_2 < 0 ? 0 : sigma2_2) - (sigma2_1 < 0 ? 0 : sigma2_1),
								rounded: round((sigma2_2 < 0 ? 0 : sigma2_2) - (sigma2_1 < 0 ? 0 : sigma2_1), ce[x].params.decimals > 4 ? 4 : ce[x].params.decimals),
							},
							r2: {
								value: ((sigma2_2 < 0 ? 0 : sigma2_2) - (sigma2_1 < 0 ? 0 : sigma2_1)) / 2,
								rounded: round(((sigma2_2 < 0 ? 0 : sigma2_2) - (sigma2_1 < 0 ? 0 : sigma2_1)) / 2, ce[x].params.decimals > 4 ? 4 : ce[x].params.decimals),
							},
						},
						indicative: vars.indicative !== undefined && vars.indicative[x] !== undefined ? (`${vars.indicative[x]}` == "true" ? true : false) : false,
					},
				};
			}

			self.calcs = calcs;

			// CALC 2
			var calcs_zscore = {};
			for (let n in ce) {
				var c_calc = calcs[n];
				var rs = {
					st: {
						value: 0,
						name: "Z-Satisfactorio",
					},
					cu: {
						value: 0,
						name: "Z-Cuestionable",
					},
					ns: {
						value: 0,
						name: "Z-No Satisfactorio",
					},
					ne: {
						value: 0,
						name: "No evaluados",
					},
					total: {
						value: 0,
						name: "Total",
					},
				};

				if (calcs_zscore[n] === undefined) calcs_zscore[n] = {};
				if (calcs_zscore[n]["values"] === undefined) calcs_zscore[n]["values"] = [];

				for (var i = 0; i < ce[n].sorted.length; i++) {
					var v1 = parseFloat(ce[n].sorted[i].value) - parseFloat(c_calc.promedio.value);
					var v2 = v1 / parseFloat(c_calc.o.value);

					var v_abs = Math.abs(v2);

					var type = v_abs <= 2 ? 0 : v_abs > 2 && v_abs <= 3 ? 1 : v_abs > 3 ? 2 : NaN;

					switch (type) {
						case 0:
							rs.st.value++;
							break;
						case 1:
							rs.cu.value++;
							break;
						case 2:
							rs.ns.value++;
							break;
						default:
							rs.ne.value++;
							break;
					}

					//console.log(self.sample_labs);
					//console.log(ce[n].sorted[i].id_lab);

					//console.log(" --- ");

					rs.total.value++;
					calcs_zscore[n]["values"].push({
						name: ce[n].sorted[i].name,
						id_lab: ce[n].sorted[i].id_lab,
						lab_order: findKeyInObject_s(self.sample_labs, ce[n].sorted[i].id_lab),
						value: {
							value: ce[n].sorted[i].value,
							rounded: ce[n].sorted[i].rounded,
						},
						vlab_vmig: {
							value: v1,
							rounded: round(v1, 4),
						},
						disc: {
							value: v2,
							rounded: round(v2, 2),
							type: type,
						},
						calcs: {
							full: calcs,
							p: c_calc.promedio.value,
							o: c_calc.o.value,
						},
					});
				}

				//console.log(" // ---------- // ");
				if (calcs_zscore[n]["results"] === undefined) calcs_zscore[n]["results"] = {};
				if (calcs_zscore[n]["results"] === undefined) calcs_zscore[n]["results"] = [];

				calcs_zscore[n]["results"] = rs;
				calcs_zscore[n]["params"] = ce[n].params;
			}

			state.calcs_zscore = calcs_zscore;
		},

		// FN
		// MEDIANA
		calcMedianas({ state }) {
			// fn_mediana( samples )
			const self = state;
			const samples_tmp = [...self.samples_clean];
			var samples = [];

			// Remove ATS value
			console.log(samples_tmp);
			samples_tmp.forEach((item, index) => {
				const param_29 = Object.keys(item).find((key) => item[key].params && item[key].params.id === 29);

				for (var key in item) {
					if (key != param_29) {
						if (!samples[index]) samples[index] = {};

						samples[index][key] = typeof item[key] == "object" ? { ...item[key] } : item[key];
					}
				}
			});

			self.samples_clean_novars = [...samples];

			var tmp1 = {};
			for (var i = 0; i < samples.length; i++) {
				for (let id_param in samples[i]) {
					if (id_param !== "name" && id_param !== "id_lab") {
						if (tmp1[id_param] === undefined) tmp1[id_param] = [];
						if (!Number.isNaN(samples[i][id_param].value)) tmp1[id_param].push(samples[i][id_param].value);
					}
				}
			}

			var medianas = {};
			for (let id_param in tmp1) {
				var md = fn_mediana(tmp1[id_param]);

				if (medianas[id_param] === undefined) medianas[id_param] = {};

				medianas[id_param]["value"] = md;
				medianas[id_param]["rounded"] = round(md, samples[0][id_param]["params"].decimals || 2);
			}

			self.medianas = medianas;

			return medianas;
		},

		// ABS
		calcAbs({ state }) {
			// ABS( samples - fn_mediana( samples ) )
			const self = state;
			const samples = self.samples_clean_novars;
			const medianas = self.medianas;
			var abs = [];

			for (var i = 0; i < samples.length; i++) {
				var tmp1 = {};
				tmp1["name"] = samples[i].name;

				for (let id_param in samples[i]) {
					if (id_param !== "name" && id_param !== "id_lab") {
						if (tmp1[id_param] === undefined) tmp1[id_param] = {};

						var calc = Number.isNaN(parseFloat(samples[i][id_param].value)) ? samples[i][id_param].value : Math.abs(parseFloat(samples[i][id_param].value) - parseFloat(medianas[id_param].value));

						tmp1[id_param]["value"] = calc;
						tmp1[id_param]["rounded"] = round(calc, parseInt(samples[i][id_param].params.decimals) + 1);

						tmp1[id_param]["raw"] = samples[i][id_param].raw;
						tmp1[id_param]["display"] = Number.isNaN(parseFloat(samples[i][id_param].raw)) ? (samples[i][id_param].raw === "" ? "" : "-") : round(calc, parseInt(samples[i][id_param].params.decimals) + 1);

						tmp1[id_param]["params"] = samples[i][id_param].params;
					}
				}

				abs.push(tmp1);
			}

			self.abs = abs;

			return abs;
		},

		// MEDIANAS_ABS
		calcMedianasAbs({ state }) {
			// fn_mediana( samples )
			const self = state;
			const abs = self.abs;

			var tmp1 = {};
			for (var i = 0; i < abs.length; i++) {
				for (let id_param in abs[i]) {
					if (id_param !== "name" && id_param !== "id_lab") {
						if (tmp1[id_param] === undefined) tmp1[id_param] = [];
						if (!Number.isNaN(abs[i][id_param].value)) tmp1[id_param].push(abs[i][id_param].value);
					}
				}
			}

			var medianas_abs = {};
			for (let id_param in tmp1) {
				var md = fn_mediana(tmp1[id_param]);
				if (medianas_abs[id_param] === undefined) medianas_abs[id_param] = {};
				medianas_abs[id_param]["value"] = md;
				medianas_abs[id_param]["rounded"] = round(md, abs[0][id_param]["params"].decimals || 2);
			}
			self.medianas_abs = medianas_abs;
			return medianas_abs;
		},

		// DISCREPANT
		calcDisc({ state }) {
			// ABS( samples - fn_mediana( samples ) )
			const self = state;
			const abs = self.abs;
			const medianas_abs = self.medianas_abs;
			const K = self.k;
			var disc = [];

			for (var i = 0; i < abs.length; i++) {
				var tmp1 = {};
				tmp1["name"] = abs[i].name;

				for (let id_param in abs[i]) {
					if (id_param !== "name" && id_param !== "id_lab") {
						if (tmp1[id_param] === undefined) tmp1[id_param] = { ...abs[i][id_param] };

						const n_abs = Number.isNaN(abs[i][id_param].value) ? 0 : abs[i][id_param].value;
						const n_med = medianas_abs[id_param].value;

						var value = Math.abs(n_abs / n_med);
						value = medianas_abs[id_param].value == 0 && (Number.isNaN(abs[i][id_param].value) ? 0 : abs[i][id_param].value) == 0 ? 0 : value;
						value = value == "Infinity" ? n_abs * 1000 : value;

						tmp1[id_param]["value"] = value;
						tmp1[id_param]["rounded"] = Number.isNaN(value) ? value : round(value, abs[i][id_param].params.decimals);
						tmp1[id_param]["disc"] = parseFloat(value) > parseFloat(K);

						tmp1[id_param]["raw"] = abs[i][id_param].raw;
						tmp1[id_param]["display"] = Number.isNaN(parseFloat(abs[i][id_param].raw)) ? (abs[i][id_param].raw === "" ? "" : "-") : round(value, abs[i][id_param].params.decimals);
					}
				}
				disc.push(tmp1);
			}

			self.disc = disc;

			return disc;
		},
	},
});
export default calc;
