<template>
    <f7-page name="home" @page:init="loadPage" @page:reinit="loadPage">
        <!-- Top Navbar -->
        <f7-navbar>
            <div id="pageMenu" slot="before-inner">
                <f7-menu id="pageMenu">
                    <f7-menu-item :animate="false" href="/" current text="Análisis"></f7-menu-item>
                    <f7-menu-item :animate="false" href="/labs/" text="Laboratorios"></f7-menu-item>
                    <f7-menu-item :animate="false" href="/params/" text="Parámetros"></f7-menu-item>
                    <f7-menu-item :animate="false" href="/methods/" text="Métodos"></f7-menu-item>
                </f7-menu>
            </div>
            <div slot="right">
                <f7-link icon-only icon-f7="gear_alt" popover-open="#optionsPopover"></f7-link>
            </div>
        </f7-navbar>
        <!-- Toolbar-->
        <f7-toolbar bottom>
            <f7-link><img :src="'./static/logotipo.png'" height="40"></f7-link>
            <span class="last_version">{{lastVersion}}</span>
        </f7-toolbar>
        <!-- Toolbar-->
        <f7-popover id="optionsPopover" class="popover-menu" :backdrop="false">
            <f7-list>
                <f7-list-item @click="doLogout" link="#" popover-close title="Cerrar sessión"></f7-list-item>
            </f7-list>
        </f7-popover>
        <!-- Toolbar-->
        <!-- Page content-->
        <f7-block strong>
            <f7-block-title>
                Análisis
            </f7-block-title>
            <f7-block-header class="">
                <f7-row>
                    <f7-col width="90">
                        <f7-searchbar
                            class="page-searchbar"
                            search-container=".search-list"
                            search-in=".item-title"
                            :disable-button="false"
                            :no-hairline="true"
                        ></f7-searchbar>
                    </f7-col>
                    <f7-col width="5">
                        <f7-segmented>
                            <f7-button small title="Refrescar" @click="getSamples" icon-material="autorenew"></f7-button>
                        </f7-segmented>
                    </f7-col>
                    <f7-col width="5">
                        <f7-segmented>
                            <f7-button small title="Nueva muestra" icon-material="add" :animate="false" href="/samples/add/"></f7-button>
                        </f7-segmented>
                    </f7-col>
                </f7-row>
            </f7-block-header>
            
            <f7-block class="pageContent" style="height: calc(100% - 50px);">
                <f7-list class="searchbar-not-found">
                    <f7-list-item title="Resultados no enctontrados"></f7-list-item>
                </f7-list>
                <f7-list class="search-list searchbar-found" media-list>
                    <f7-list-item v-for="(item, index) in samples">
                        <template #header>
                        </template>
                        <template #title>
                            {{index+1}} . {{item.name}} {{item.lot}}
                        </template>
                        <template #subtitle></template>
                        <template #after>
                            <f7-segmented>
                                <f7-button :animate="false" :href="`/samples/edit/${index}/`" title="Editar" icon-material="edit"></f7-button>
                                <f7-button :animate="false" :href="`/samples/info/${index}/`" icon-f7="doc_text_search" title="Ver muestreos"></f7-button>
                                <f7-button :animate="false" :href="`/samples/results/${index}/`" icon-f7="doc_richtext" title="Ver informe"></f7-button>
                                <f7-button @click="popupImport" :animate="false" href="" :data-id="item.id" icon-f7="cloud_upload" popup-open=".popup-test" title="Importar"></f7-button>
                                <f7-button @click="duplicate" :animate="false" :data-id="item.id" title="Duplicar" icon-f7="rectangle_on_rectangle_angled"></f7-button>
                                <f7-button @click="delete" :animate="false" :data-id="item.id" title="Eliminar" icon-material="delete"></f7-button>
                            </f7-segmented>
                        </template>
                    </f7-list-item>
                </f7-list>
            </f7-block>
            
            <div class="popup popup-test">
                <div class="page">
                    <div class="navbar">
                        <div class="navbar-bg"></div>
                        <div class="navbar-inner">
                            <div class="title">Importar CSV</div>
                            <div class="right"><a :animate="false" href="#" class="link popup-close"><i class="icon f7-icons">multiply</i></a></div>
                        </div>
                    </div>
                    <div class="page-content">
                        <f7-block v-if="!csv">
                            <form id="importForm" enctype="multipart/form-data">
                                <div class="item-input-wrap padding text-align-center col-90">
                                    <input type="file" name="file">
                                </div>
                                <div class="padding-right padding-top col-10">
                                    <button type="submit" class="button button-small outlined"> 
                                        <i class="icon f7-icons">cloud_upload</i>
                                    </button>
                                </div>
                            </form>
                        </f7-block>
                        <div v-else>
                            <form @submit="importFormValues">
                                <f7-block>
                                    <f7-block-title>Enlazar Parámetros</f7-block-title>
                                    <f7-list simple-list no-hairlines no-hairlines-between>
                                        <f7-list-item class="row" v-for="(el, index) in csv.headers">
                                            <f7-col>
                                                <a 
                                                    :animate="false"
                                                    href="#" 
                                                    data-searchbar="true" 
                                                    @smartselect:opened="smartOpened" 
                                                    data-open-in="popup" 
                                                    data-searchbar-placeholder="Buscar parametro" 
                                                    class="item-link smart-select" 
                                                    data-close-on-select="true"
                                                    >
                                                <select name="params" :data-index="el">
                                                    <option value="" selected>Elige un parámetro</option>
                                                   <option v-for="(e, i) in param_list" :value="e !== undefined ? e.id : 0">{{e !== undefined ? e.name : ''}}</option>
                                                </select>
                                                <div class = "item-content">
                                                    <div class = "item-inner">
                                                       <div class = "item-title">{{el}}</div>
                                                    </div>
                                                </div>
                                              </a>
                                            </f7-col>
                                        </f7-list-item>
                                    </f7-list>
                                </f7-block>
                                <f7-block>
                                    <f7-block-title>Enlazar Laboratorios</f7-block-title>
                                    <f7-list simple-list no-hairlines no-hairlines-between>
                                        <f7-list-item class="row" v-for="(el, index) in csv.labs">
                                            <f7-col>
                                                 <a 
                                                    :animate="false"
                                                    href="#" 
                                                    data-searchbar="true" 
                                                    @smartselect:opened="smartOpened" 
                                                    data-open-in="popup" 
                                                    data-searchbar-placeholder="Buscar laboratorio" 
                                                    class="item-link smart-select" 
                                                    data-close-on-select="true"
                                                    >
                                                <select name="labs" :data-index="index">
                                                    <option value="" selected>Elige un laboratorio</option>
                                                   <option v-for="(e, i) in labs" :value="e !== undefined ? e.id : 0">{{e !== undefined ? e.name : ''}}</option>
                                                </select>
                                                <div class = "item-content">
                                                    <div class = "item-inner">
                                                       <div class = "item-title">{{el}}</div>
                                                    </div>
                                                </div>
                                              </a>
                                            </f7-col>

                                        </f7-list-item>
                                        <f7-list-item>
                                            <f7-button type="submit" icon-f7="floppy_disk"></f7-button>
                                        </f7-list-item>
                                    </f7-list>
                                </f7-block>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </f7-block>
    </f7-page>
</template>
<script>
import {
    f7,
    useStore
}
from 'framework7-vue';

export default {
    setup() {
        const doLogout = () => f7.store.dispatch('doLogout');
        const samples = useStore('getSamples');
        const getSamples = () => f7.store.dispatch('getSamples', { page: 1, count: 100 });

        const sConfig = useStore('getCalcConfig');
        const getSamplesConfig = () => f7.store.dispatch('getSamplesConfig');

        const labs = useStore('getAllLabs');
        const getAllLabs = () => f7.store.dispatch('getAllLabs');

        const lastVersion = useStore('lastVersion');

        return {
            doLogout,
            samples,
            getSamples,
            sConfig,
            getSamplesConfig,
            labs,
            getAllLabs,
            lastVersion
        }
    },
    data() {
        return {
            current: null,
            current_is_token: false,
            current_is_sent: false,
            popup: null,
            popupOpened: false,
            
            imp_load : false,

            popup: null,
            csv_id : null,
            csv : null,
            csv_values : {
                params : {},
                labs : {},
                csv : {}
            }
        }
    },
    props: {
        f7route: Object,
        f7router: Object,
    },
    computed : {
        param_list () {
            const self = this;
            const params_tmp = {...self.sConfig};
            var params = {};
            var csv = self.csv_values;

            for( let i in params_tmp )
                if( params_tmp[i].name !== "" ) params[i] = params_tmp[i];

            //
            /*
            if( csv !== undefined ) {
                csv = csv.params;
                if( Object.keys(csv).length > 0 ) {
                    for ( let k in csv ) {
                        var key = Object.keys(params).find(ky => params[ky].id == csv[k]);

                        if ( key )
                            delete params[key];
                    }

                }
            }
            */

            return params;
        }
    },
    methods: {
        duplicate(e) {
            const self = this;
            const _this = e.target.closest('[data-id]');
            const id = _this.dataset.id;

            f7.store.dispatch('ajax', { action: 'duplicateSample', id: id }).then((r) => {
                self.loadPage();
            });
        },
        smartOpened(smartSelect) {
            const ss = f7.smartSelect.get(smartSelect.target);
            
            document.querySelector('.popup .searchbar-input-wrap input').focus();

            smartSelect.target.querySelector('select').addEventListener('change', (e) => {
                const el = e.target;
                this.csv_values[el.name][el.dataset.index] = ss.getValue()
            }, { once: true });
        },
        importFormValues (e) {
            const self = this;
            const popup = f7.popup.get('.popup');

            self.csv_values['data'] =  self.csv.data;

            const vars = {
                id : self.csv_id,
                params : self.csv_values,
                values : self.csv,
                action : 'importSample'
            };

            f7.store.dispatch('ajax', vars).then((result) => {
                if (result.code == 200)
                    f7.dialog.alert('', 'IMPORTADO CORRECTAMENTE', () => popup.close());
                else
                    f7.dialog.alert(result.msg);
            })
            

            e.preventDefault();
            return false;
        },
        selectCurrent(e) {
            const self = this;
            const _this = e.target.closest('.options');
            const index = parseInt(_this.dataset.index);

            self.current = index;
            self.current_is_token = Boolean(self.samples[index].is_token);
            self.current_is_sent = Boolean(self.samples[index].form_sent);
        },
        processData(allText) {
            var allTextLines = allText.split(/\r\n/);
            var headers = allTextLines[0].split(';');
            var headers_ok = [];

            for (var i = 0; i < headers.length; i++) {
                if( headers[i] !== "" && i > 0 ) {
                    var h = headers[i];
                        h = h.replace(/\"/g, '');
                        h = h.replace(/\n/g, '');

                    headers_ok.push(h)
                };
            }

            var labs = [];
            var lines = [];
            for (var i=1; i<allTextLines.length; i++) {
                var data = allTextLines[i].split(';');
                
                if (data.length == headers.length) {
                    var tarr = {};
                    for (var j=0; j<headers.length; j++) {

                        if( headers[j] !== "" && headers_ok[parseInt(j)-1] !== undefined ) {
                            tarr[headers_ok[parseInt(j)-1]] = data[j];
                        } 

                        if( j == 0 ) labs.push(data[j]);
                    }
                    lines.push(tarr);
                }
            }       
            return {headers : headers_ok, labs : labs, data : lines};
        },
        popupImport(e) {
            const self = this;
            const _this = e.target.closest('[data-id]');
            const id = _this.dataset.id;

            self.csv = null;
            self.csv_id = id;

            const popup = f7.popup.get('.popup');

            if( !self.imp_load ) {
                self.imp_load = true;
                document.querySelector('#importForm').addEventListener('submit', function(e) {
                    const form = this;
                    const files = form.querySelector('input[type="file"]');
                    const file = files.files[0];

                	var reader = new FileReader();
    	            	reader.addEventListener('load', function (e) {
    	                	let csvdata = e.target.result; 
    		                self.csv = self.processData(csvdata)
    		          	});
    		            reader.readAsBinaryString(file);

                    e.preventDefault();
                    return false;
                });
            }
        },
        delete(e) {
            const self = this;
            const _this = e.target.closest('[data-id]');
            const id = parseInt(_this.dataset.id);
    
            f7.dialog.confirm('¿Estás seguro?<br>Esta acción es irreversible.', '¡ALERTA!', () => {

                f7.store.dispatch('ajax', {action : 'deleteSample', id : id}).then((result) => {
                    if (result.code == 200)
                        f7.dialog.alert('', 'ELIMINADO CORRECTAMENTE', () => self.f7router.refreshPage());
                    else
                        f7.dialog.alert(result.msg);
                })

            }, () => false);
        },
        loadPage() {
            const self = this;
                self.getSamples();
                self.getSamplesConfig().then((r) => {
                    var tmp = [];

                    for (var i = 0; i < r.length; i++) {
                        if( r[i] ) tmp.push(r[i]);
                    }

                   this.sConfig = tmp;
                });
                self.getAllLabs();
        }
    },
    mounted() {
        const self = this;
              self.loadPage();
              
    }   
}
</script>