import { isset } from "./Utils";

const Ajax = (url, Head) => {
	url = url || "";
	Head = Head || {};

	let ajax_url = new URL(url);
	let ajax_headers = !isset(Head.headers) ? new Headers() : Head.headers instanceof Headers ? Head.headers : new Headers({ ...Head.headers });

	if (isset(Head.token)) ajax_headers.append("Authorization", `Bearer ${Head.token}`);

	let ajax_options = {};
	// headers: ajax_headers,
	return {
		Get: async (params) => {
			params = params || {};
			ajax_options.method = "GET";
			ajax_url.search = new URLSearchParams({ ...params }).toString();
			try {
				const resp = await fetch(ajax_url, ajax_options);
				return resp.json();
			} catch (error) {
				console.error("AJAX . GET . ", error);
				throw error;
			}
		},
		Post: async (params) => {
			let body_params = new FormData();

			if (params instanceof FormData) {
				body_params = params;
			} else {
				for (let field in params) {
					if (params[field] instanceof File) {
						body_params.append(field, params[field]);
						continue;
					}

					switch (typeof params[field]) {
						case "object":
							body_params.append(field, JSON.stringify(params[field]));
							break;
						case "boolean":
							body_params.append(field, params[field] ? "1" : "0");
							break;
						default:
							body_params.append(field, params[field]);
							break;
					}
				}
			}

			ajax_options.method = "POST";
			ajax_options.body = body_params;

			try {
				const resp = await fetch(ajax_url, ajax_options);
				return resp.json();
			} catch (error) {
				console.error("AJAX . POST . ", error);
				throw error;
			}
		},
		Put: async (params) => {
			let query = Ajax(url, Head).Post(params);
			return query;
		},
		Remove: async () => {
			ajax_options.method = "REMOVE";
			try {
				const resp = await fetch(ajax_url, ajax_options);
				return resp.json();
			} catch (error) {
				console.error("AJAX . GET . ", error);
				throw error;
			}
		},
	};
};

export default Ajax;
