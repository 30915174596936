<template>
    <f7-page name="home" @page:beforein="loadPage">
        <!-- Top Navbar -->
        <f7-navbar>
            <div id="pageMenu" slot="before-inner">
                <f7-menu id="pageMenu">
                    <f7-menu-item :animate="false" href="/" current text="Análisis"></f7-menu-item>
                    <f7-menu-item :animate="false" href="/labs/" text="Laboratorios"></f7-menu-item>
                    <f7-menu-item :animate="false" href="/params/" text="Parámetros"></f7-menu-item>
                    <f7-menu-item :animate="false" href="/methods/" text="Métodos"></f7-menu-item>
                </f7-menu>
            </div>
            <div slot="right">
                <f7-link icon-only icon-f7="gear_alt" popover-open="#optionsPopover"></f7-link>
            </div>
        </f7-navbar>
        <!-- Toolbar-->
        <f7-toolbar bottom>
            <f7-link><img :src="'./static/logotipo.png'" height="40"></f7-link>
            <span class="last_version">{{lastVersion}}</span>
        </f7-toolbar>
        <!-- Toolbar-->
        <f7-popover id="optionsPopover" class="popover-menu" :backdrop="false">
            <f7-list>
                <f7-list-item @click="doLogout" link="#" popover-close title="Cerrar sessión"></f7-list-item>
            </f7-list>
        </f7-popover>
        <!-- Toolbar-->
        <!-- Page content-->
        <f7-block strong>
            <f7-block-title>
                DATA {{labName}}
            </f7-block-title>

            <f7-block-header class="">
                <f7-row>
                    <f7-col width="5">
                        <f7-segmented>
                            <f7-button small title="Volver" icon-f7="arrow_left" back></f7-button>
                        </f7-segmented>
                    </f7-col>
                    <f7-col width="90">
                    </f7-col>
                    <f7-col width="5">
                        <f7-segmented>
                            <f7-button small title="Refrescar" @click="loadPage" icon-material="autorenew"></f7-button>
                        </f7-segmented>
                    </f7-col>
                </f7-row>
            </f7-block-header>
            <f7-block class="pageContent" style="height: calc(100% - 100px);">
                <f7-block-header class="">
                    <f7-row class="table-header no-sort">
                        <f7-col class="text-align-center" width="5">
                            N
                        </f7-col>
                        <f7-col width="30">
                            Parámetro
                        </f7-col>
                        <f7-col class="text-align-right" width="15">
                            REP 1
                        </f7-col>
                        <f7-col class="text-align-right" width="15">
                            REP 2
                        </f7-col>
                        <f7-col class="text-align-right" width="15">
                            Media
                        </f7-col>
                        <f7-col class="text-align-right" width="20">
                            Método
                        </f7-col>
                    </f7-row>
                </f7-block-header>
                <f7-list media-list v-if="sample_form">
                    <f7-list-item v-for="(item, index) in sample_form">
                        <f7-row>
                            <f7-col class="text-align-center" width="5">
                                {{index+1}}
                            </f7-col>
                            <f7-col width="30">
                                <span>{{sConfig[item.id] !== undefined ? sConfig[item.id].name : ""}}</span>
                            </f7-col>
                            <f7-col class="text-align-right" width="15">
                                <span v-if="item.rep1.value !== ''">{{item.rep1.value}} {{item.rep1.unit}}</span>
                                <span v-else>-</span>
                            </f7-col>
                            <f7-col class="text-align-right" width="15">
                                <span v-if="item.rep2.value !== ''">{{item.rep2.value}} {{item.rep2.unit}}</span>
                                <span v-else>-</span>
                            </f7-col>
                            <f7-col class="text-align-right" width="15">
                                <span v-if="item.media !== ''">{{item.media}} {{item.rep1.unit}}</span>
                                <span v-else>-</span>
                            </f7-col>
                            <f7-col class="text-align-right" width="20">
                                <span v-if="item.method !== ''">{{item.method}}</span>
                                <span v-else>-</span>
                            </f7-col>
                        </f7-row>
                    </f7-list-item>
                </f7-list>

                <f7-block-header class="">
                    <f7-row class="table-header no-sort">
                        <f7-col>
                            Notas
                        </f7-col>
                        <f7-col class="text-align-right" width="5">
                            <f7-button @click="saveNotes">Guardar</f7-button>
                        </f7-col>
                    </f7-row>
                </f7-block-header>
                <f7-list>
                    <f7-list-input type="textarea" v-model:value="form_comment"></f7-list-input>
                </f7-list>

                <f7-block-header class="">
                    <f7-row class="table-header no-sort">
                        <f7-col>
                            Archivo
                        </f7-col>
                        <f7-col class="text-align-right" width="5"></f7-col>
                    </f7-row>
                </f7-block-header>

                <f7-list v-if="sample" media-list>
                    <f7-list-item v-for="(item, index) in sample.files[lab]">
                        <f7-row>
                            <f7-col>{{item}}</f7-col>
                            <f7-col width="10">
                                <f7-row>
                                    <f7-col><f7-button :external="true" :animate="false" :href="getApiUrl.files + item" target="_blank"  icon-f7="doc_chart"></f7-button></f7-col>
                                    <f7-col><f7-button @click="delete" :data-id="sample.id" :data-lab="lab" :data-file="index" icon-material="delete"></f7-button></f7-col>
                                </f7-row>
                            </f7-col>
                        </f7-row>                        
                    </f7-list-item>
                </f7-list>
            </f7-block>
        </f7-block>
    </f7-page>
</template>
<script>
import {
    f7,
    useStore
}
from 'framework7-vue';
export default {
    setup() {
        const doLogout = () => f7.store.dispatch('doLogout');


        const getSamples = async () => await f7.store.dispatch('getSamples', { page: 1, count: 100 });
        const getAllLabs = async () => await f7.store.dispatch('getAllLabs');
        const getSamplesConfig = async () => await f7.store.dispatch('getSamplesConfig');


        const getApiUrl = useStore('getApiUrl');
        const lastVersion = useStore('lastVersion');
        
        return {
            doLogout,

            getSamples,
            getAllLabs,
            getSamplesConfig,
            
            getApiUrl,
            lastVersion
        }
    },
    data() {
        return {
            samples : [],
            allLabs : [],
            sConfig : {},
            
            sample: false,
            this_lab : false,

            sample_form: null,
            form_comment : "",
            form_comments: ""
        }
    },
    props: {
        id: null,
        lab: null
    },
    computed : {
        labName() {
            return this.this_lab.name || '';
        }
    },
    methods: {
        async loadPage() {
            const self = this;
            
            try {
                let samples = await self.getSamples();
                let allLabs = await self.getAllLabs();
                let sConfig = await self.getSamplesConfig();

                self.samples = [...samples];
                self.allLabs = [...allLabs];
                self.sConfig = {...sConfig};

                // Lab 
                    const lab_key = Object.keys(allLabs).find(key => allLabs[key].id == self.lab );
                    const lab = allLabs[lab_key];

                    if( lab !== undefined ) {
                        self.this_lab = lab;
                    }

                // Sample
                    let this_sample = samples.filter(item => item.id == self.id)

                    if( this_sample.length > 0 ) {
                        
                        this_sample = this_sample[0];

                        self.sample = this_sample;

                        let forms = this_sample.forms[self.lab];
                        let sorted_form = [];

                        this_sample.params.forEach(param => {
                            if( forms[param] ) { 
                                forms[param].id = param;
                                sorted_form.push(forms[param]);
                            };
                        });

                        self.sample_form = sorted_form;
                        self.form_comments = this_sample.form_comments;
                        self.form_comment = self.sample.form_comments[self.lab] !== undefined ? self.sample.form_comments[self.lab] : "";

                    }

            } catch( error ) { 
                console.error(error)
            }
        },
        async saveNotes() {
            if( typeof this.form_comments == "string" ) {
                this.form_comments = {};
            }
            
            this.form_comments[this.lab] = this.form_comment;

            try {
                const query = await f7.store.dispatch('ajax', { action : 'updateSample', form_comments : JSON.stringify(this.form_comments), id : this.id });

                if( query.code == 200 ) {
                   f7.toast.create({
                      text: 'Notas guardadas',
                      position: 'center',
                      closeTimeout: 1500,
                    }).open();
                }
            } catch( error ) {
                console.error(error)
            }
            
        },
        delete(e) {
                const self = this;
                const _this = e.target.closest('[data-id]');
                const id = parseInt(_this.dataset.id);
                const lab = parseInt(_this.dataset.lab);
                const file = parseInt(_this.dataset.file);

                f7.dialog.confirm('¿Estás seguro?<br>Esta acción es irreversible.', '¡ALERTA!', () => {

                    f7.store.dispatch('ajax', {action : 'deleteFile', id : id, lab : lab, file : file}).then((result) => {
                        if (result.code == 200)
                            f7.dialog.alert('¡Eliminado correctamente!', 'ELIMINAR', () => self.loadPage());
                        else {
                            console.error(result);
                            f7.dialog.alert(result.msg);
                        }
                    })

                }, () => false);
            }
    },
    mounted() {}
}
</script>