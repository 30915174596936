<template>
	<f7-page name="labs">
		<!-- Top Navbar -->
		<f7-navbar>
			<div
				id="pageMenu"
				slot="before-inner">
				<f7-menu id="pageMenu">
					<f7-menu-item
						:animate="false"
						href="/"
						text="Análisis"></f7-menu-item>
					<f7-menu-item
						:animate="false"
						href="/labs/"
						current
						text="Laboratorios"></f7-menu-item>
					<f7-menu-item
						:animate="false"
						href="/params/"
						text="Parámetros"></f7-menu-item>
					<f7-menu-item
						:animate="false"
						href="/methods/"
						text="Métodos"></f7-menu-item>
				</f7-menu>
			</div>
			<div slot="right">
				<f7-link
					icon-only
					icon-f7="gear_alt"
					popover-open="#optionsPopover"></f7-link>
			</div>
		</f7-navbar>
		<!-- Toolbar-->
		<f7-toolbar bottom>
			<f7-link
				><img
					:src="'./static/logotipo.png'"
					height="40"
			/></f7-link>
			<span class="last_version">{{ lastVersion }}</span>
		</f7-toolbar>
		<!-- Toolbar-->
		<f7-popover
			id="optionsPopover"
			class="popover-menu"
			:backdrop="false">
			<f7-list>
				<f7-list-item
					@click="doLogout"
					link="#"
					popover-close
					title="Cerrar sessión"></f7-list-item>
			</f7-list>
		</f7-popover>
		<!-- Toolbar-->
		<!-- Page content-->
		<f7-block strong>
			<f7-block-title> Listado de laboratorios </f7-block-title>
			<f7-block-header class="">
				<f7-row>
					<f7-col width="90">
						<f7-searchbar
							class="page-searchbar"
							search-container=".search-list"
							search-in=".item-title"
							:disable-button="false"
							:no-hairline="true"></f7-searchbar>
					</f7-col>
					<f7-col width="5">
						<f7-segmented>
							<f7-button
								small
								title="Refrescar"
								@click="reloadLabs"
								icon-material="autorenew"></f7-button>
						</f7-segmented>
					</f7-col>
					<f7-col width="5">
						<f7-segmented>
							<f7-button
								small
								title="Nuevo laboratorio"
								icon-material="add"
								href="./add/"></f7-button>
						</f7-segmented>
					</f7-col>
				</f7-row>
				<f7-row class="table-header">
					<f7-col width="25"> Nombre </f7-col>
					<f7-col width="25"> Contacto </f7-col>
					<f7-col width="20"> Teléfono </f7-col>
					<f7-col width="20"> E-mail </f7-col>
					<f7-col width="10"> </f7-col>
				</f7-row>
			</f7-block-header>
			<f7-block
				class="pageContent"
				style="height: calc(100% - 100px)">
				<f7-list class="searchbar-not-found">
					<f7-list-item title="Resultados no enctontrados"></f7-list-item>
				</f7-list>
				<f7-list
					class="search-list searchbar-found sortableSort mt-0"
					media-list>
					<f7-list-item v-for="(item, index) in labs">
						<f7-row>
							<f7-col
								class="item-title"
								width="25">
								{{ item.id }} {{ item.name }}
							</f7-col>
							<f7-col width="25">
								<span class="text-overflow">{{ item.contact.name }}</span>
							</f7-col>
							<f7-col width="20">
								<span class="text-overflow">{{ item.contact.phone }}</span>
							</f7-col>
							<f7-col width="20">
								<span class="text-overflow">{{ item.contact.email }}</span>
							</f7-col>
							<f7-col width="10">
								<f7-segmented>
									<!--<f7-button @click="openContacts" :data-id="item.id" title="Contactos" icon-f7="rectangle_stack_person_crop"></f7-button>-->
									<f7-button
										@click="changeLabStatus"
										:data-id="item.id"
										data-status="0"
										v-if="item.status == 1"
										title="Desactivar"
										icon-material="close"></f7-button>
									<f7-button
										@click="changeLabStatus"
										:data-id="item.id"
										data-status="1"
										v-else
										title="Activar"
										icon-material="check"></f7-button>
									<f7-button
										title="Editar"
										icon-material="edit"
										:href="`./edit/${index}/`"></f7-button>
									<f7-button
										@click="delete"
										:data-id="item.id"
										title="Eliminar"
										icon-material="delete"></f7-button>
								</f7-segmented>
							</f7-col>
						</f7-row>
					</f7-list-item>
				</f7-list>
			</f7-block>
		</f7-block>
	</f7-page>
</template>
<script>
	import { f7, useStore } from "framework7-vue";
	import Ajax from "../../js/classes/Ajax";
	export default {
		setup() {
			const doLogout = () => f7.store.dispatch("doLogout");
			const labs = useStore("getAllLabs");
			const getLabs = () => f7.store.dispatch("getAllLabs");
			const disabledLabs = useStore("getDisabledLabs");
			const getDisabledLabs = () => f7.store.dispatch("getDisabledLabs");
			const preloader = (status) => f7.store.dispatch("preloader", status);

			const lastVersion = useStore("lastVersion");

			return {
				labs,
				getLabs,
				doLogout,
				preloader,
				disabledLabs,
				getDisabledLabs,
				lastVersion,
			};
		},
		data() {
			return {
				current: null,
			};
		},
		props: {
			id: null,
			f7router: Object,
		},
		methods: {
			selectCurrent(e) {
				const self = this;
				const _this = e.target.closest(".options");
				const index = parseInt(_this.dataset.index);
				self.current = index;
			},
			reloadLabs() {
				const self = this;
				self.getLabs();
			},
			async changeLabStatus(e) {
				const self = this;
				const _this = e.target.closest("[data-id]");
				const data = _this.dataset;

				try {
					const q = await Ajax(`${window.api.gab360}/customers/`).Post({ method: "PUT", id: data.id, status: data.status });
					if (q.data) self.reloadLabs();
				} catch (e) {
					console.error(e);
				}

				/*
				f7.store
					.dispatch("chLabStatus", {
						id: data.id,
						status: data.status,
					})
					.then((r) => {
						if (r) self.reloadLabs();
					});
					*/
			},
			openContacts(e) {
				const self = this;
				const _this = e.target.closest("[data-id]");
				const data = _this.dataset;
			},
			delete(e) {
				const self = this;
				const _this = e.target.closest("[data-id]");
				const id = parseInt(_this.dataset.id);

				f7.dialog.confirm(
					"¿Estás seguro?<br>Esta acción es irreversible.",
					"¡ALERTA!",
					async () => {
						try {
							const q = await Ajax(`${window.api.gab360}/customers/`).Post({ method: "REMOVE", id: id });
							if (q.data) f7.dialog.alert("¡Eliminado correctamente!", "ELIMINAR", () => self.f7router.refreshPage());
						} catch (e) {
							console.error(e);
						}
						/*
						f7.store.dispatch("ajax", { action: "deteleLab", id: id }).then((result) => {
							if (result.code == 200) f7.dialog.alert("¡Eliminado correctamente!", "ELIMINAR", () => self.f7router.refreshPage());
							else f7.dialog.alert(result.msg);
						});*/
					},
					() => false
				);
			},
		},
		mounted() {
			const self = this;
			self.getLabs().then((labs) => {
				const els = [];
				var x = 0;
				labs.forEach((lab, k_lab) => {
					const contact = lab.contacts.find((item) => item.primary == 1);
					self.labs[k_lab].contact = contact;
					els.push(x++);
				});
			});
		},
	};
</script>
