// --
export const dateString = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];
export const monthString = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

// --
export const getDate = (date) => new Date(date);

// --
export const delay = (time) => new Promise((resolve) => setTimeout(() => resolve(true), time || 10000));

// --
export const loadImage = (image_uri) =>
	new Promise((resolve, reject) => {
		let img = new Image();
		img.onload = () => resolve(img);
		img.onerror = reject;
		img.src = image_uri;
	});

// --
export const isset = (variable) => {
	return !(variable === false || variable == "false" || variable === null || variable == "null" || variable === undefined || variable == "undefined");
};

// --
export const empty = (variable) => {
	return (typeof variable === "string" && variable.trim() === "") || (typeof variable === "object" && ((variable.length && variable.length < 1) || (Object.keys(variable).length && Object.keys(variable).length < 1)));
};

// --
export const formatDate = (date) => {
	if (!date) return "";
	let dateFormat = getDate(date);
	return `${dateString[dateFormat.getDay()]} ${dateFormat.getDate()} de ${monthString[dateFormat.getMonth()]} del ${dateFormat.getFullYear()} a las ${fill(dateFormat.getHours(), 0, 2)}:${fill(dateFormat.getMinutes(), 0, 2)}`;
};
export const formatDateShort = (date) => {
	if (!date) return "";
	let dateFormat = getDate(date);
	return `${dateFormat.getDate()} de ${monthString[dateFormat.getMonth()]} del ${dateFormat.getFullYear()}`;
};

// --
export const fill = (value, filler, spaces, before) => {
	before = before || true;
	[...new Array(Math.max(spaces - `${value}`.length, 0))].forEach(() => {
		if (before) {
			value = `${filler}${value}`;
			return;
		}
		value += `${filler}`;
	});
	return `${value}`;
};
