import { createStore } from "framework7/lite";
import { f7 } from "framework7-vue";
import Ajax from "./classes/Ajax.js";

const credentials_default = {
	"/devices/": {
		enter: true,
		show: true,
		add: true,
		edit: true,
		remove: true,
	},
};

const dom = "https://certivin.gabsystem360.com/";

const store = createStore({
	state: {
		session: {
			loggedIn: false,
			user: null,
			user_info: null,
			credentials: credentials_default,
		},
		users: [
			{
				user: "admin",
				password: "12345",
			},
		],

		// ----

		urls: {
			domain: dom,
			api: `${dom}certivin_api.php`,
			files: `${dom}files/`,
		},

		// ----

		samples: [],
		current_sample: "",
		sample_ignored: {},

		// ----

		calc_config: [],
		labs: [],
		all_labs: [],
		disabled_labs: [],

		// ----

		methods: [],

		// ----

		params: [],

		// ----

		preload_status: "",
		preload: null,

		dialog_status: "",
		dialog: null,

		months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
		last_v: document.lastModified,
	},
	getters: {
		getSession({ state }) {
			return state.session;
		},
		getSamples({ state }) {
			return state.samples;
		},
		getCurrentSample({ state }) {
			return state.samples[state.current_sample] || "";
		},
		getCalcConfig({ state }) {
			return state.calc_config;
		},
		getLabs({ state }) {
			return state.labs;
		},
		getAllLabs({ state }) {
			return state.all_labs;
		},
		getDisabledLabs({ state }) {
			return state.disabled_labs;
		},
		getSampleVars({ state }) {
			return state.sample_ignored;
		},
		getParams({ state }) {
			return state.params;
		},
		getApiUrl({ state }) {
			return state.urls;
		},
		getMethods({ state }) {
			return state.methods;
		},

		lastVersion({ state }) {
			const last_v = state.last_v;

			var date = new Date(last_v);

			var dt = {
				day: date.getDate(),
				month: date.getMonth(),
				year: date.getFullYear(),
				hour: date.getHours(),
				minutes: date.getMinutes(),
				seconds: date.getSeconds(),
			};

			return `Actualizado el ${dt.day} de ${state.months[dt.month]} del ${dt.year} a las ${dt.hour < 10 ? `0${dt.hour}` : dt.hour}:${dt.minutes < 10 ? `0${dt.minutes}` : dt.minutes}:${dt.seconds < 10 ? `0${dt.seconds}` : dt.seconds}`;
		},
	},
	actions: {
		// ----

		getSamples({ state, dispatch }, { page, count }) {
			page = page || 1;
			count = count || 10;

			return new Promise((resolve, reject) => {
				dispatch("ajax", { action: "getAllSamples", page: page, count: count })
					.then((result) => {
						state.samples = result.msg;
						resolve(result.msg);
					})
					.catch((e) => reject(e));
			});
		},
		setCurrentSample({ state }, current) {
			state.current_sample = current;
		},
		newSampleToken({ state, dispatch }, { id_sample, lab_id }) {
			if (!id_sample) return;

			return new Promise((resolve, reject) => {
				dispatch("ajax", { action: "newSampleToken", id: id_sample, lab_id: lab_id })
					.then((result) => {
						resolve(result.msg);
					})
					.catch((e) => reject(e));
			});
		},

		// ----
		/*
        doLogin({ state }, { username, password }) {
            const all_users = state.users;
            
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    // TMP LOGIN
                    var user_info = {};
                    var exists = false;

                    for (var i = 0; i < all_users.length; i++) {
                        user_info = all_users[i];
                        if (user_info.user == username) {
                            exists = true;
                            if (user_info.password == password) {
                                localforage.setItem('cervitinLoggedUser', user_info).then((value) => {
                                    state.session = {
                                        loggedIn: true,
                                        user: user_info.user,
                                        user_info: user_info,
                                        credentials: credentials_default
                                    };
                                    resolve(true);
                                }).catch((err) => {
                                    reject(err);
                                });
                            } else
                                reject('Usuario y/o contraseña incorrecta');
                            break;
                        }
                        else 
                            reject('Usuario y/o contraseña incorrecta');
                    }

                    if (!exists)
                        reject('Usuario no existente');

                }, 1000);
            })
        },
        */
		doLogin({ state, dispatch }, { username, password }) {
			return new Promise((resolve, reject) => {
				dispatch("loginApi", { method: "POST", username: username, password: password, action: "login" })
					.then((login) => {
						if (login.error === undefined) {
							localforage
								.setItem("cervitinLoggedUser", login)
								.then((value) => {
									state.session = {
										loggedIn: true,
										user: login.username,
										user_info: login,
										credentials: credentials_default,
									};
									resolve(true);
								})
								.catch((err) => {
									reject(err);
								});
						} else {
							reject(login.error);
						}
					})
					.catch((e) => reject(e));
			});
		},
		doKeyLogin({ state, dispatch }, key) {
			return new Promise((resolve, reject) => {
				dispatch("loginApi", { method: "POST", key: key, action: "keyLogin" })
					.then((login) => {
						localforage
							.setItem("cervitinLoggedUser", login)
							.then((value) => {
								state.session = {
									loggedIn: true,
									user: login.username,
									user_info: login,
									credentials: credentials_default,
								};
								resolve(true);
							})
							.catch((err) => {
								reject(err);
							});
					})
					.catch((e) => reject(e));
			});
		},
		checkLogin({ state, dispatch }) {
			return new Promise((resolve, reject) => {
				localforage.getItem("cervitinLoggedUser").then((value) => {
					if (value) {
						state.session.loggedIn = true;
						state.session.user = value.user;
						state.session.user_info = value;

						dispatch("checkCredentials");
						resolve(true);
					} else resolve(false);
				});
			});
		},
		checkCredentials({ state }) {
			const current_role = state.session.user_info.role;
			//console.log(current_role);
		},
		doLogout({ state }) {
			localforage.removeItem("cervitinLoggedUser").then(() => {
				state.session = {
					loggedIn: false,
					current_user: null,
					current_user_info: null,
					credentials: credentials_default,
				};

				f7.views.main.router.refreshPage();
			});
		},

		// ----

		getSamplesConfig({ state, dispatch }) {
			return new Promise((resolve, reject) => {
				dispatch("ajax", { action: "getSamplesConfig" })
					.then((result) => {
						var res = [];

						for (var i = 0; i < result.msg.length; i++) {
							res[result.msg[i].id] = result.msg[i];
						}

						state.calc_config = res;
						resolve(res);
					})
					.catch((e) => console.log(e));
			});
		},
		getSampleVars({ state, dispatch }, id) {
			return new Promise((resolve, reject) => {
				dispatch("ajax", { action: "getSampleVars", id: id })
					.then((result) => {
						state.sample_ignored = result.msg;
						resolve(result.msg);
					})
					.catch((e) => console.log(e));
			});
		},

		// ----

		async getLabs({ state, dispatch }) {
			try {
				const q = await Ajax(`${window.api.gab360}/customers/`).Post({ method: "GET", page: 1, x_page: 5000, status: 1 });

				state.labs = q.data;
				return q.data;
			} catch (e) {
				console.error(e);
			}
			//return new Promise((resolve, reject) => {
			//dispatch("ajax", { action: "getLabs" })
			//	.then((result) => {
			//		state.labs = result.msg;
			//		resolve(result.msg);
			//	})
			//	.catch((e) => console.log(e));
			//});
		},
		async getAllLabs({ state, dispatch }) {
			try {
				const q = await Ajax(`${window.api.gab360}/customers/`).Post({ method: "GET", page: 1, x_page: 5000 });

				state.all_labs = q.data;
				return q.data;
			} catch (e) {
				console.error(e);
			}

			//return new Promise((resolve, reject) => {
			//dispatch('ajax', {action : 'getAllLabs'})
			//.then((result) => {
			//    state.all_labs = result.msg;
			//    resolve(result.msg);
			//})
			//.catch((e) => console.log(e));
			// })
		},
		getDisabledLabs({ state, dispatch }) {
			return new Promise((resolve, reject) => {
				dispatch("ajax", { action: "getDisabledLabs" })
					.then((result) => {
						state.disabled_labs = result.msg;
						resolve(result.msg);
					})
					.catch((e) => console.log(e));
			});
		},
		saveLabsOrder({ state, dispatch }, order) {
			return new Promise((resolve, reject) => {
				dispatch("ajax", { action: "orderLabs", order: order })
					.then((result) => {
						dispatch("getLabs").then(() => {
							resolve(true);
						});
					})
					.catch((e) => console.log(e));
			});
		},
		chLabStatus({ state, dispatch }, { id, status }) {
			if (status == 1) {
				return new Promise((resolve, reject) => {
					dispatch("ajax", { action: "enableLab", id: id })
						.then((result) => {
							resolve(true);
						})
						.catch((e) => {
							resolve(false);
							console.log(e);
						});
				});
			} else if (status == 0) {
				return new Promise((resolve, reject) => {
					dispatch("ajax", { action: "disableLab", id: id })
						.then((result) => {
							resolve(true);
						})
						.catch((e) => {
							resolve(false);
							console.log(e);
						});
				});
			}
		},

		// ----

		getParams({ state, dispatch }) {
			return new Promise((resolve, reject) => {
				dispatch("ajax", { action: "getParams" })
					.then((result) => {
						state.params = result.msg;
						resolve(result.msg);
					})
					.catch((e) => console.log(e));
			});
		},

		// ----

		preloader({ state }, status) {
			status = status || false;
			state.preload = state.preload || f7.preloader;

			if (status) {
				state.preload.show();
			} else {
				state.preload.hide();
			}

			state.preload_status = status;
		},
		preloaderText({ state }, { status, text }) {
			status = status || false;
			state.preload = state.preload || f7.dialog;

			if (status) state.preload.preloader(text);
			else state.preload.close();

			state.preload_status = status;
		},

		dialog({ state }, { text = false, preload = false }) {
			status = text ? true : false;

			state.dialog = state.dialog || f7.dialog;

			if (status) state.dialog.preloader(text);
			else state.dialog.close();

			state.dialog_status = status;
		},

		// ----

		getMethods({ state, dispatch }) {
			return new Promise((resolve, reject) => {
				dispatch("ajax", { action: "getAllMethods" })
					.then((result) => {
						state.methods = result.msg;
						resolve(result.msg);
					})
					.catch((e) => console.log(e));
			});
		},

		// ----
		gabApi({ state }, { controller, data }) {
			data["method"] = "POST";
			return new Promise((resolve, reject) => {
				f7.request({
					url: `${window.api.gab360}/${controller}/`,
					method: "POST",
					data: data,
					statusCode: {
						404: function (xhr) {
							alert("page not found");
						},
					},
				})
					.then((r) => {
						//console.log('d0 ==>', r);
						return JSON.parse(r.data);
					})
					.then((r) => resolve(r))
					.catch((e) => reject(e));
			});
		},
		loginApi({ state }, data) {
			return new Promise((resolve, reject) => {
				f7.request({
					url: `${window.api.gab360}/login/`,
					method: "POST",
					data: data,
					statusCode: {
						404: function (xhr) {
							alert("page not found");
						},
					},
				})
					.then((r) => {
						//console.log('d0 ==>', r);
						return JSON.parse(r.data);
					})
					.then((r) => resolve(r))
					.catch((e) => reject(e));
			});
		},
		ajax({ state }, data) {
			return new Promise((resolve, reject) => {
				f7.request({
					url: state.urls.api,
					method: "POST",
					data: data,
					statusCode: {
						404: function (xhr) {
							alert("page not found");
						},
					},
				})
					.then((r) => {
						//console.log('d0 ==>', r);
						return JSON.parse(r.data);
					})
					.then((r) => resolve(r))
					.catch((e) => reject(e));
			});
		},
		ajaxWithfiles({ state }, data) {
			return new Promise((resolve, reject) => {
				f7.request({
					url: state.urls.api,
					method: "POST",
					data: data,
					contentType: "multipart/form-data",
					statusCode: {
						404: function (xhr) {
							alert("page not found");
						},
					},
				})
					.then((r) => {
						console.log("d2 ==>", r);
						return JSON.parse(r.data);
					})
					.then((r) => resolve(r))
					.catch((e) => reject(e));
			});
		},

		// ----

		uploadFile({ state }, { pdf_blob, filename }) {
			const url = `${window.api.gab360}/certivin/`;
			const chunkSize = 2000000; //break into 5 MB chunks fat minimum
			var chunkCounter = 0;
			var chunkN = 1;
			var numberofChunks = Math.ceil(pdf_blob.size / chunkSize);
			var start = 0;
			var chunkEnd = start + chunkSize;

			createChunk(chunkN, start);

			function createChunk(chunkN, start, end) {
				chunkCounter++;

				chunkEnd = Math.min(start + chunkSize, pdf_blob.size);
				const chunk = pdf_blob.slice(start, chunkEnd);

				const chunkForm = new FormData();
				chunkForm.append("method", "POST");
				chunkForm.append("chunkN", chunkN);
				chunkForm.append("chuncks", numberofChunks);
				chunkForm.append("file", chunk, filename);

				uploadChunk(chunkForm, start, chunkEnd);
			}

			function uploadChunk(chunkForm, start, chunkEnd) {
				var oReq = new XMLHttpRequest();

				oReq.upload.addEventListener("progress", updateProgress);

				oReq.open("POST", url, true);

				var blobEnd = chunkEnd - 1;
				var contentRange = "bytes " + start + "-" + blobEnd + "/" + pdf_blob.size;

				oReq.setRequestHeader("Content-Range", contentRange);

				oReq.onload = function (oEvent) {
					// Uploaded
					var resp = JSON.parse(oReq.response);
					chunkN++;
					start += chunkSize;

					if (start < pdf_blob.size) {
						createChunk(chunkN, start);
					} else {
						console.log("respuesta : ", resp);
					}
				};

				oReq.send(chunkForm);
			}

			function updateProgress(oEvent) {
				if (oEvent.lengthComputable) {
					var percentComplete = Math.round((oEvent.loaded / oEvent.total) * 100);
					var totalPercentComplete = Math.round(((chunkCounter - 1) / numberofChunks) * 100 + percentComplete / numberofChunks);
					//console.log( "Chunk # " + chunkCounter + " is " + percentComplete + "% uploaded. Total uploaded: " + totalPercentComplete +"%" );
				} else {
					console.log("not computable");
				}
			}
		},
	},
});
export default store;
