<template>
    <f7-page name="home">
        <!-- Top Navbar -->
        <f7-navbar>
            <div id="pageMenu" slot="before-inner">
                <f7-menu id="pageMenu">
                    <f7-menu-item :animate="false" href="/" current text="Análisis"></f7-menu-item>
                    <f7-menu-item :animate="false" href="/labs/" text="Laboratorios"></f7-menu-item>
                    <f7-menu-item :animate="false" href="/params/" text="Parámetros"></f7-menu-item>
                    <f7-menu-item :animate="false" href="/methods/" text="Métodos"></f7-menu-item>
                </f7-menu>
            </div>
            <div slot="right">
                <f7-link icon-only icon-f7="gear_alt" popover-open="#optionsPopover"></f7-link>
            </div>
        </f7-navbar>
        <!-- Toolbar-->
        <f7-toolbar bottom>
            <f7-link><img :src="'./static/logotipo.png'" height="40"></f7-link>
            <span class="last_version">{{lastVersion}}</span>
        </f7-toolbar>
        <!-- Toolbar-->
        <f7-popover id="optionsPopover" class="popover-menu" :backdrop="false">
            <f7-list>
                <f7-list-item @click="doLogout" link="#" popover-close title="Cerrar sessión"></f7-list-item>
            </f7-list>
        </f7-popover>
        <!-- Toolbar-->
        <!-- Page content-->
        <f7-block strong>
            <f7-block-title>
                Información de la muestra
            </f7-block-title>
            <f7-block-header class="">
                <f7-row>
                    <f7-col width="5">
                        <f7-segmented>
                            <f7-button small title="Volver" icon-f7="arrow_left" back></f7-button>
                        </f7-segmented>
                    </f7-col>
                    <f7-col width="90">
                        <f7-searchbar
                            class="page-searchbar"
                            search-container=".search-list"
                            search-in=".item-title"
                            :disable-button="false"
                            :no-hairline="true"
                        ></f7-searchbar>
                    </f7-col>
                    <f7-col width="5">
                        <f7-segmented>
                            <f7-button small title="Refrescar" @click="loadPage" icon-material="autorenew"></f7-button>
                        </f7-segmented>
                    </f7-col>
                </f7-row>
                <f7-row class="table-header no-sort">
                    <f7-col class="text-align-center" width="5">
                        N
                    </f7-col>
                    <f7-col width="25">
                        Laboratorio
                    </f7-col>
                    <f7-col width="50">
                        Notas
                    </f7-col>
                    <f7-col width="10" class="text-align-center">
                        Enviado
                    </f7-col>
                    <f7-col width="10"></f7-col>
                </f7-row>
            </f7-block-header>
            <f7-block class="pageContent" style="height: calc(100% - 100px);">
                <f7-list class="search-list searchbar-found" media-list>
                    <f7-list-item v-for="(item, index) in tokenList">
                        <f7-row>
                            <f7-col class="text-align-center" width="5">
                                {{index+1}}
                            </f7-col>
                            <f7-col class="item-title"  width="25">
                                {{item.name}}
                            </f7-col>
                            <f7-col width="50">
                                {{notas && notas[item.id] ? notas[item.id] : ''}}
                            </f7-col>
                            <f7-col width="10" class="text-align-center">
                                <div class="smph" :active="item.sent === true ? true : false"></div>
                            </f7-col>
                            <f7-col width="10">
                                <f7-segmented v-show="item.token !== null">
                                    <f7-button :animate="false" :href="`/samples/data/${sample.id}/${item.id}/`" title="Ver datos" icon-f7="square_favorites"></f7-button>
                                    <f7-button title="Ver enlace" icon-f7="link_circle_fill" :animate="false" href="" :data-index="item.id" @click="viewToken"></f7-button>
                                </f7-segmented>
                                <f7-button v-show="item.token === null" title="Generar enlace" icon-f7="link_circle" :animate="false" href="" :data-index="item.id" @click="generateToken"></f7-button>
                            </f7-col>
                        </f7-row>
                    </f7-list-item>
                </f7-list>
            </f7-block>
        </f7-block>
    </f7-page>
</template>

<script>
import {
    f7,
    useStore
}
from 'framework7-vue';
export default {
    setup() {
        const doLogout = () => f7.store.dispatch('doLogout');
        const samples = useStore('getSamples');
        const getSamples = () => f7.store.dispatch('getSamples', {
            page: 1,
            count: 100
        });
        const apiUrls = useStore('getApiUrl');
        const lastVersion = useStore('lastVersion');
        return {
            doLogout,
            samples,
            getSamples,
            apiUrls,
            lastVersion
        }
    },
    data() {
        return {
            sample: null,
            sample_tokens: null,
            sample_labs_order : null,
            current: null,
            current_is_valid: false,
            current_is_sent: false,
            notas : false,
            popup: {}
        }
    },
    props: {
        id: null
    },
    computed : {
        tokenList () {
            const self = this;
            const tokens = self.sample_tokens;
            const labs_order = self.sample_labs_order;
            var order = [];

            for( let k in labs_order )
                if( tokens[labs_order[k]] !== undefined ) {
                    tokens[labs_order[k]].id = labs_order[k];
                    order.push(tokens[labs_order[k]]);
                }

            return order;
        }
    },
    methods: {
        selectCurrent(e) {
            const self = this;
            const _this = e.target.closest('.options');
            const index = parseInt(_this.dataset.index);
            self.current = index;
            self.current_is_valid = Boolean(self.sample.tokens[index].is_valid);
            self.current_is_sent = Boolean(self.sample.tokens[index].sent);
        },
        generateToken(e) {
            const self = this;
            const _this = e.target.closest('[data-index]');
            const current_sample = self.sample.id;

            f7.store.dispatch('newSampleToken', {
                    id_sample: current_sample,
                    lab_id: _this.dataset.index
                })
                .then((r) => {
                    self.openTokenPopup(r);
                    self.loadPage();
                })
                .catch((e) => console.error(e));
        },
        viewToken(e) {
            const self = this;
            const _this = e.target.closest('[data-index]');
            const current_token = self.sample_tokens[_this.dataset.index];
            self.openTokenPopup(current_token.token);
        },
        openTokenPopup(token) {
            const self = this;

            if (!self.popup[token]) 
                self.popup[token] = null;
            

            if ( self.popup[token] === null ) {

                self.popup[token] = f7.popup.create({
                    content: `
                          <div class="popup" style="--f7-popup-tablet-height:160px;">
                            <div class="page">
                              <div class="navbar">
                                <div class="navbar-bg"></div>
                                <div class="navbar-inner">
                                  <div class="title">Enlace del formulario</div>
                                  <div class="right"><a href="#" class="link popup-close"><i class="icon f7-icons">multiply</i></a></div>
                                </div>
                              </div>
                              <div class="page-content">
                              <div class="row">
                                <div class="padding text-align-center col-90">
                                    <input id="tokenContent-${token}" type="hidden" value="${self.apiUrls.domain}form.php?key=${token}"> 
                                    <a class="link external" href="${self.apiUrls.domain}form.php?key=${token}" target="_blank">${self.apiUrls.domain}form.php?key=${token}</a>
                                </div>
                                <div class="padding-right padding-top col-10"> 
                                    <div id="copyToken-${token}" class="button button-small"> 
                                        <i class="icon f7-icons">doc_on_clipboard</i>
                                    </div>
                                </div>
                              </div>
                                
                              </div>
                            </div>
                          </div>
                    `.trim(),
                    on: {
                        open: function() {

                            const btn = document.querySelector(`#copyToken-${token}`);
                            btn.addEventListener('click', function() {
                                const target = document.querySelector(`#tokenContent-${token}`);
                                target.setAttribute('type', 'text')
                                target.select()

                                try {
                                    if (document.execCommand('copy')) {
                                        f7.toast.create({
                                          text: 'Enlace copiado al portapapeles',
                                          position: 'center',
                                          closeTimeout: 1500,
                                          on : {
                                            closed : () => {
                                               self.popup[token].close(); 
                                            }
                                        }
                                        }).open();
                                    }
                                    else {
                                        f7.toast.create({
                                          text: 'Error, enlace no copiado',
                                          position: 'center',
                                          closeTimeout: 1500,
                                          on : {
                                            closed : () => {
                                               self.popup[token].close(); 
                                            }
                                        }
                                        }).open();
                                    }
                                } catch (err) {
                                    f7.toast.create({
                                        text: 'Error, incapaz de copiar',
                                        position: 'center',
                                        closeTimeout: 1500,
                                        on : {
                                            closed : () => {
                                               self.popup[token].close(); 
                                            }
                                        }
                                    }).open();
                                }

                                target.setAttribute('type', 'hidden')
                                window.getSelection().removeAllRanges()

                            }, false)
                        }
                    }
                });

            }
        // Open popup
            self.popup[token].open();
        },
        loadPage() {
            const self = this;
                self.getSamples()
                    .then(() => {
                        self.sample = self.samples[self.id];
                        self.sample_tokens = self.samples[self.id].tokens;
                        self.sample_labs_order = self.samples[self.id].labs;
                        self.notas = self.sample.form_comments;
                    });
        }
    },
    mounted() {
        const self = this;
            self.loadPage();
    }
}
</script>