import { f7, theme } from 'framework7-vue';

import LoginPage from '../pages/login.vue';
import HomePage from '../pages/home.vue';

import SamplePage from '../pages/samples/sample.vue';
import SampleInfoPage from '../pages/samples/sample-info.vue';
import SampleDataPage from '../pages/samples/sample-data.vue';
import SampleEditPage from '../pages/samples/edit/sample.vue';

import LabPage from '../pages/labs/labs.vue';
import LabEditPage from '../pages/labs/edit/labs.vue';

import ParamsPage from '../pages/params/params.vue';
import ParamsEditPage from '../pages/params/edit/params.vue';

import MethodsPage from '../pages/methods/methods.vue';
import MethodsEditPage from '../pages/methods/edit/methods.vue';

var toast = null;

const toastError = () => {
    if (!toast) {
        toast = f7.toast.create({
            icon: theme.ios || theme.aurora ? '<i class="f7-icons">hand_raised_slash</i>' : '<i class="material-icons">stop-alert-outline</i>',
            text: 'Acceso denegado',
            position: 'center',
            closeTimeout: 2000,
        });
    }
    // Open it
    toast.open();
}


var routes = [{
        path: '/',
        async ({ resolve, reject }) {
            // CHECK LOGIN
            f7.store.dispatch('checkLogin').then(function(is_logged) {
                if (is_logged)
                    resolve({ component: HomePage });
                else
                    resolve({ component: LoginPage });
            });
        }
    },
    {
        path: '/samples/',
        component: HomePage,
        routes: [{
            path: 'add/',
            component: SampleEditPage,
            options: {
                props: {
                    action: 'add'
                },
            },
        },
        {
            path: 'edit/:id/',
            component: SampleEditPage,
            options: {
                props: {
                    action: 'edit'
                },
            },
        },
        {
            path: 'info/:id/',
            component: SampleInfoPage
        },
        {
            path: 'results/:id/',
            component: SamplePage
        },
        {
            path: 'data/:id/:lab/',
            component: SampleDataPage
        }]
    },
    {
        path: '/labs/',
        component: LabPage,
        routes: [{
            path: '/add/',
            component: LabEditPage,
            options: {
                props: {
                    action: 'add'
                },
            },
        },
        {
            path: '/edit/:id/',
            component: LabEditPage,
            options: {
                props: {
                    action: 'edit'
                },
            },
        }]
    },
    {
        path: '/params/',
        component: ParamsPage,
        routes: [{
            path: '/add/',
            component: ParamsEditPage,
            options: {
                props: {
                    action: 'add'
                },
            },
        },
        {
            path: '/edit/:id/',
            component: ParamsEditPage,
            options: {
                props: {
                    action: 'edit'
                },
            },
        }]
    },
    {
        path: '/methods/',
        component: MethodsPage,
        routes: [{
            path: '/add/',
            component: MethodsEditPage,
            options: {
                props: {
                    action: 'add'
                },
            },
        },
        {
            path: '/edit/:id/',
            component: MethodsEditPage,
            options: {
                props: {
                    action: 'edit'
                },
            },
        }]
    },
];

export default routes;