// ----- 

import { f7 } from 'framework7-vue';

import { jsPDF } from "jspdf";

import * as d3 from "d3";
import Canvg from 'canvg';

// ----- 

import BG from '../static/pdf/cert/bg_cert.jpg';

import BG_P from '../static/pdf/cert/ok/portada.jpg';
import BG_1 from '../static/pdf/cert/ok/p1.jpg';
import BG_2 from '../static/pdf/cert/ok/p2.jpg';
import BG_3 from '../static/pdf/cert/ok/p3.jpg';
import BG_4 from '../static/pdf/cert/ok/p4.jpg';
import BG_5 from '../static/pdf/cert/ok/p5.jpg';
import BG_6 from '../static/pdf/cert/ok/p6.jpg';
import BG_7 from '../static/pdf/cert/ok/p7.jpg';
import BG_8 from '../static/pdf/cert/ok/p8.jpg';
import BG_9 from '../static/pdf/cert/ok/p9.jpg';
import BG_10 from '../static/pdf/cert/ok/p10.jpg';
import BG_11 from '../static/pdf/cert/ok/p11.jpg';
import BG_12 from '../static/pdf/cert/ok/p12.jpg';
import BG_13 from '../static/pdf/cert/ok/p13.jpg';
import BG_14 from '../static/pdf/cert/ok/p14.jpg';

// ----- 

export default class cert {
    constructor () {}

    // Getters
        // get fn(){return}
    
    // Methods
    cropText ( string, length, end ) {
        length = length || string.length;
        end = end || end === "" ? end : '...';

        return length < string.length ? string.slice(0, length) + end : string;
    }
    doGraph ( data ) {
        var data_def = {
            width : 750,
            height : 500,
            margin : {top: 35, right: 20, bottom: 40, left: 60},
            title : "1 - Grado Alcohólico",
            x_title : "Laboratorios",
            y_title : "Z-Score",
            data : []
        };

        data = Object.assign({}, data_def, data);

        var nn = [];

        for( var dn in data.data ) {
            nn.push(dn.ZScore)
        }

        const x = d3.scaleBand()
                .range([data.margin.left, data.width - data.margin.right])
                .padding(0.1);

        const y = d3.scaleLinear()
                .domain([0, d3.max(nn)])
                .range([data.height - data.margin.bottom, data.margin.top]);

        const n_format = (n) => {
            return (n.toFixed(1)).replace('.', ',');
        }

        const xAxis = g => g
                        .attr("transform", `translate(0,${y(0)})`)
                        .call(
                            d3.axisBottom(x)
                                .tickSizeOuter(0)
                                .tickSizeInner(0)
                                .tickPadding(10)
                                .offset(0)
                                .tickSize(0)
                        )
                        .attr('font-size', 16)
                        .attr("fill", 'black')
                        .attr("stroke", 'none')
                        .call(g => g.select(".domain").remove())

        const yAxis = g => g
                        .attr("transform", `translate(${data.margin.left},0)`)
                        .call(d3.axisRight(y)
                                .tickSize(data.width - data.margin.left - data.margin.right)
                                .tickFormat(function(d) {
                                    return parseFloat(d) < 0 ? `-${n_format(d*-1)}` : n_format(d);
                                })
                                .scale(y)
                        )
                        .attr('font-size', 16)
                        .attr("fill", 'black')
                        .attr("stroke", 'none')
                        .call(g => g.select(".domain").remove())
                        .call(g => g.selectAll(".tick line")
                                    .attr("stroke-opacity", 0.2)
                                    .attr("stroke-solid", 1)
                                    .attr("stroke", 'gray')
                        )
                        .call(g => g.selectAll(".tick text")
                                    .attr("x", function(d) {
                                        return parseFloat(d) < 0 ? -40 : -35;
                                    })
                        );

        var svg = d3.create("svg")                
        //var svg = d3.selectAll("#grp")
                    .attr("width", data.width + data.margin.left + data.margin.right)
                    .attr("height", data.height + data.margin.top + data.margin.bottom)
                    .attr("border", '1px solid lightgray')
                    .append("g")
                    .attr("transform", "translate(" + data.margin.left + "," + data.margin.top + ")")

            x.domain(data.data.map(function(d) {
                return d.Lab;
            }));
            y.domain(d3.extent(data.data, function(d) {
                return d.ZScore;
            })).nice();

            svg.selectAll(".bar")
                .data(data.data)
                .enter()
                .append("rect")
                .attr("stroke", 'none')
                .attr("fill", function(d) {
                    var n = Math.abs(d.ZScore);
                    if( n <= 2 )
                        return "#5b9bd5";
                    else if( n > 2 && n <= 3 )
                        return "#ed7d31";
                    else
                        return "#ff0000";
                })
                .attr("data-yr", function(d) {
                    return d.Lab;
                })
                .attr("data-c", function(d) {
                    return d.ZScore;
                })
                .attr("y", function(d) {
                    if (d.ZScore > 0){
                        return y(d.ZScore);
                    } else {
                        return y(0);
                    }
                    return y(0);
                })
                .attr("x", function(d) {
                    return x(d.Lab) + (15/2);
                })
                .attr("width", x.bandwidth() - 15)
                .attr("height", function(d) {
                    return Math.abs(y(d.ZScore) - y(0));
                });

                svg.append("g")
                    .attr("class", "y axis")
                    .call(yAxis);

                svg.append("g")
                    .attr("class", "y axis")
                    .attr("fill", 'black')
                    .attr("stroke", 'none')
                    .attr("transform", `translate(0, ${data.height/2}), rotate(-90)`)
                    .attr("font-weight", "bold")
                    .attr("font-size", 20)
                    .append("text")
                    .text(data.y_title)

                svg.append("g")
                    .attr("class", "x axis")
                    .call(xAxis);

                svg.append("g")
                    .attr("class", "x axis")
                    .attr("fill", 'black')
                    .attr("stroke", 'none')
                    .attr("text-anchor", 'middle')
                    .attr("transform", `translate(${(data.width/2)}, ${data.height})`)
                    .attr("font-weight", "bold")
                    .attr("font-size", 20)
                    .append("text")
                    .text(data.x_title);

                svg.append("g")
                    .attr("class", "x axis")
                    .attr("fill", 'black')
                    .attr("stroke", 'none')
                    .attr("text-anchor", 'middle')
                    .attr("transform", `translate(${(data.width/2)}, 0)`)
                    .attr("font-weight", "bold")
                    .attr("font-size", 20)
                    .append("text")
                    .text(data.title);

                svg.append("g")
                    .attr("class", "square")
                    .append("rect")
                        .attr("x", -data.margin.left)
                        .attr("y", -data.margin.top)
                        .attr("width", data.width + data.margin.left + data.margin.right)
                        .attr("height", data.height + data.margin.top + data.margin.bottom)
                        .attr("stroke", "gray")
                        .attr("fill", "none")

        return svg.node();
    }
    doGraphToImg ( graph ) {
        return new Promise(resolve => {
            var canvas = document.createElement("canvas");
                canvas. width = 850
                canvas. height = 600

            var xml = new XMLSerializer().serializeToString(graph);

            var tmp = async (canvas, svg) => {
                var v = await Canvg.from(canvas.getContext('2d'), svg);
                    v.start();
            }

            tmp(canvas, xml).then(() => {
                var c = canvas.toDataURL("image/png");
                    resolve(c);
            });
        });
    }
    doPDF ( data, images ) {
        const self = this;
        const months = [
            '',
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre'
        ];

        let doc = new jsPDF({
            orientation: 'p',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts:true,
            floatPrecision: 'smart', // or "smart", default is 16
        });

        // var nl2 = doc.getTextWidth(txt2);
        // doc.line(x1, y1, x2, y2);

        // VARS
            var fs = 0;
            var fs_diff = 2;

            var doc_w = 210;
            var doc_h = 297;
            var half_w = doc_w - (doc_w/2);

            var pad_x = 30;
            var pad_y = 25;

            var max_w = doc_w - (pad_x*2);

            var space = 5;

            var txt = [];
            var txt_w = [];

            var page = 1;
            
        // SET CORDS
            var x = pad_x;
            var y = pad_y;

        // PORTADA
            // XY
                x = pad_x;
                y = pad_y;

            // PAGE
                doc.addImage(BG_P, "JPEG", 0,0, doc_w, doc_h,undefined,'FAST'); 

                fs = 12
                doc.setFontSize(fs)
                doc.setFont('Helvetica', '')

                var [year, month, day] = data[0].sample.expire_date.split('-');

                doc.text( `${data[0].sample.lot}`, x + 135, y+235.5, {align : 'left'} );
                doc.text( `${month}/${year}`, x + 135, y+240, {align : 'left'} );
                doc.text( `${data[0].sample.ref}`, x + 135, y+245, {align : 'left'} ); 

        // P1
            doc.addPage();
            doc.addImage(BG_1, "JPEG", 0,0, doc_w, doc_h,undefined,'FAST'); 

            // PAGE N.
                doc.setFontSize(9);
                doc.text( `${++page}`, x + 150, y+261, {align : 'center'} );

        // P2
            doc.addPage();
            doc.addImage(BG_2, "JPEG", 0,0, doc_w, doc_h,undefined,'FAST'); 

            // XY
                x = pad_x;
                y = pad_y;

            // 
                fs = 9;
                doc.setFontSize(fs);
                doc.setFont('Helvetica', '')

                y += 25;

                var lbs = Object.values(data[0].labs);

                lbs.sort((a,b) => {
                    let fa = a.oficial_name === "" ? a.name.toLowerCase() : a.oficial_name.toLowerCase(),
                        fb = b.oficial_name === "" ? b.name.toLowerCase() : b.oficial_name.toLowerCase();

                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }

                    return 0;
                });

                var ext_sp = 1;

                for( var xy1 in lbs ) {
                    y += ((fs/4) + fs_diff) * ext_sp;

                    var name = lbs[xy1].oficial_name !== "" ? lbs[xy1].oficial_name : lbs[xy1].name;
                    var width = doc.getTextWidth(name);
                        ext_sp = Math.ceil(width/75);

                    doc.text( `${(parseInt(xy1)+1)}`, x, y, {align : 'left'} );
                    doc.text( name, x + 10, y, {maxWidth : 75, align : 'left'} );
                    doc.text( lbs[xy1].city + ( lbs[xy1].state !== "" ? ' (' + lbs[xy1].state + ')' : '' ), 75 + (x + 10), y, {maxWidth : max_w, align : 'left'} );
                }

            // PAGE N.
                doc.setFontSize(9);
                doc.text( `${++page}`, pad_x + 150, pad_y+261, {align : 'center'} );

        // P3
            doc.addPage();
            doc.addImage(BG_3, "JPEG", 0,0, doc_w, doc_h,undefined,'FAST'); 

            // XY
                x = pad_x;
                y = pad_y;

            //
                fs = 11;
                doc.setFontSize(fs);
                doc.setFont('Helvetica', '')

                y += 25;

                for( var xy1 in data ) {
                    y += (fs/4) + fs_diff;
                    doc.text( `${(parseInt(data[xy1].param.order) + 1)}`, x + 10, y, {maxWidth : max_w, align : 'left'} );
                    doc.text( data[xy1].param.name_cert + ( data[xy1].param.format !== "" ? ' (' + data[xy1].param.format + ')' : '' ) , x + 25, y, {maxWidth : max_w, align : 'left'} );
                }

            // PAGE N.
                doc.setFontSize(9);
                doc.text( `${++page}`, pad_x + 150, pad_y+261, {align : 'center'} );

        // P4
            doc.addPage();
            doc.addImage(BG_4, "JPEG", 0,0, doc_w, doc_h,undefined,'FAST'); 

            // PAGE N.
                doc.setFontSize(9);
                doc.text( `${++page}`, pad_x + 150, pad_y+261, {align : 'center'} );

        // P5
            doc.addPage();
            doc.addImage(BG_5, "JPEG", 0, 0, doc_w, doc_h, undefined, 'FAST');   

            // XY
                x = pad_x;
                y = pad_y;

            // TABLE
                var t_x1 = x;
                var t_x2 = max_w+pad_x;

                var t_y1 = y+53;
                var t_y2 = t_y1

                var cells = [
                    32,
                    19,
                    22,
                    22,
                    22,
                    33
                ];
                var x_cells = [
                    t_x1 + cells[0],
                    t_x1 + cells[0] + cells[1],
                    t_x1 + cells[0] + cells[1] + cells[2],
                    t_x1 + cells[0] + cells[1] + cells[2] + cells[3],
                    t_x1 + cells[0] + cells[1] + cells[2] + cells[3] + cells[4],
                    t_x1 + cells[0] + cells[1] + cells[2] + cells[3] + cells[4] + cells[5]
                ];

                // TOP
                    doc.line( t_x1, t_y1, t_x2, t_y1 );

                    // FS
                        fs = 7;
                        doc.setFontSize(fs);
                        doc.setFont('Helvetica', 'bold')

                        t_y2 += (fs/4) + fs_diff;

                        doc.text( 'PARÁMETRO', 
                            t_x1 + (cells[0]/2), 
                            t_y2+2.4, 
                            {maxWidth : cells[0], align : 'center'}
                        )
                        doc.text( 'Nº de laboratorios participantes',
                            x_cells[0] + (cells[1]/2), 
                            t_y2-1, 
                            {maxWidth : cells[1], align : 'center'}
                        )
                        doc.text( 'Nº de laboratorios participantes con resultado enviado', 
                            x_cells[1] + (cells[2]/2), 
                            t_y2-1, 
                            {maxWidth : cells[2], align : 'center'}
                        )
                        doc.text( 'Nº de laboratorios aceptados para el Test', 
                            x_cells[2] + (cells[3]/2), 
                            t_y2-1, 
                            {maxWidth : cells[3], align : 'center'}
                        )
                        doc.text( 'Nº de laboratorios eliminados por límites, <Lq o <Ld', 
                            x_cells[3] + (cells[4]/2), 
                            t_y2-1, 
                            {maxWidth : cells[4], align : 'center'}
                        )
                        doc.text( 'Código de laboratorios eliminados por límites', 
                            x_cells[4] + (cells[5]/2), 
                            t_y2+.5, 
                            {maxWidth : cells[5], align : 'center'}
                        )

                        t_y2 += 5

                // CONTENTS
                    doc.line( x_cells[4], t_y1, x_cells[4], t_y2 );
                    doc.line( t_x1, t_y2, t_x2, t_y2 );
                    doc.setFont('Helvetica', '')

                    for( var xy1 in data ) {
                        t_y2 += (fs/4) + fs_diff;
                        doc.text( self.cropText ( data[xy1].param.name_cert, 18, '' ), 
                            t_x1+2, 
                            t_y2, 
                            {maxWidth : cells[0], align : 'left'}
                        )
                        doc.text( `${data[xy1].labs_d.labs_p}`,
                            x_cells[0] + (cells[1]/2), 
                            t_y2, 
                            {maxWidth : cells[1], align : 'center'}
                        )
                        doc.text( `${data[xy1].labs_d.labs_sent}`, 
                            x_cells[1] + (cells[2]/2), 
                            t_y2, 
                            {maxWidth : cells[2], align : 'center'}
                        )
                        doc.text( `${data[xy1].labs_d.labs_acc}`, 
                            x_cells[2] + (cells[3]/2), 
                            t_y2, 
                            {maxWidth : cells[3], align : 'center'}
                        )
                        doc.text( `${data[xy1].labs_d.labs_del}`, 
                            x_cells[3] + (cells[4]/2), 
                            t_y2, 
                            {maxWidth : cells[4], align : 'center'}
                        )
                        doc.text( ( data[xy1].labs_d.labs_del_codes.length > 0 ? data[xy1].labs_d.labs_del_codes.join(', ') : '-' ), 
                            x_cells[4] + (cells[5]/2), 
                            t_y2, 
                            {maxWidth : cells[5], align : 'center'}
                        )
                    }

                    t_y2 += (fs/4) + 1;
                
                    doc.line( 
                        t_x1, 
                        t_y1, 
                        t_x1, 
                        t_y2
                    );
                    doc.line( 
                        x_cells[0], 
                        t_y1, 
                        x_cells[0], 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[1], 
                        t_y1, 
                        x_cells[1], 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[2], 
                        t_y1, 
                        x_cells[2], 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[3], 
                        t_y1, 
                        x_cells[3], 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[5], 
                        t_y1, 
                        x_cells[5], 
                        t_y2 
                    );

                    doc.line( t_x1, t_y2, t_x2, t_y2 );
            
            // TABLE
                t_y1 = t_y2+1;
                t_y2 = t_y1

                cells = [
                    32,
                    19,
                    22,
                    22,
                    22,
                    33
                ];
                x_cells = [
                    t_x1 + cells[0],
                    t_x1 + cells[0] + cells[1],
                    t_x1 + cells[0] + cells[1] + cells[2],
                    t_x1 + cells[0] + cells[1] + cells[2] + cells[3],
                    t_x1 + cells[0] + cells[1] + cells[2] + cells[3] + cells[4],
                    t_x1 + cells[0] + cells[1] + cells[2] + cells[3] + cells[4] + cells[5]
                ];

                // TOP
                    doc.line( t_x1, t_y1, t_x2, t_y1 );

                    // FS
                        fs = 7
                        doc.setFontSize(fs);
                        doc.setFont('Helvetica', 'bold')

                        t_y2 += (fs/4) + fs_diff;

                        doc.text( 'PARÁMETRO', 
                            t_x1 + (cells[0]/2), 
                            t_y2+2.5, 
                            {maxWidth : cells[0], align : 'center'}
                        )
                        doc.text( 'Nº de laboratorios aceptados por el Test',
                            x_cells[0] + (cells[1]/2), 
                            t_y2-1, 
                            {maxWidth : cells[1], align : 'center'}
                        )
                        doc.text( 'Nº de laboratorios eliminados por Test Mediana', 
                            x_cells[1] + (cells[2]/2), 
                            t_y2, 
                            {maxWidth : cells[2], align : 'center'}
                        )
                        doc.text( 'Código laboratorios eliminados por Test Mediana', 
                            x_cells[2] + (cells[3]/2), 
                            t_y2-1, 
                            {maxWidth : cells[3], align : 'center'}
                        )
                        doc.text( 'Nº de laboratorios no evaluados por el Test', 
                            x_cells[3] + (cells[4]/2), 
                            t_y2, 
                            {maxWidth : cells[4], align : 'center'}
                        )
                        doc.text( 'Código de laboratorios no evaluados', 
                            x_cells[4] + (cells[5]/2), 
                            t_y2+2, 
                            {maxWidth : cells[5], align : 'center'}
                        )

                        t_y2 += 8

                // CONTENTS
                    doc.line( x_cells[4], t_y1, x_cells[4], t_y2 );
                    doc.line( t_x1, t_y2, t_x2, t_y2 );
                    doc.setFont('Helvetica', '')

                    var pls_y = 1;

                    for( var xy1 in data ) {

                        t_y2 += ((fs/4) + fs_diff) * (pls_y > 0 ? pls_y : 1);

                        var wth_1 = doc.getTextWidth(( data[xy1].labs_d.labs_del_by_test_codes.length > 0 ? data[xy1].labs_d.labs_del_by_test_codes.join(', '): '-' ));
                        var wth_ex_1 = Math.ceil(wth_1/cells[3]);
                        var wth_2 = doc.getTextWidth(( data[xy1].labs_d.labs_disc_by_test_codes.length > 0 ? data[xy1].labs_d.labs_disc_by_test_codes.join(', '): '-' ));
                        var wth_ex_2 = Math.ceil(wth_2/cells[5]);

                        pls_y =  wth_ex_1 > wth_ex_2 ? wth_ex_1 : wth_ex_2;

                        doc.text( self.cropText ( data[xy1].param.name_cert, 18, '' ) , 
                            t_x1+2, 
                            t_y2, 
                            {maxWidth : cells[0], align : 'left'}
                        )
                        doc.text( `${data[xy1].labs_d.labs_acc_by_test}`,
                            x_cells[0] + (cells[1]/2), 
                            t_y2, 
                            {maxWidth : cells[1], align : 'center'}
                        )
                        doc.text( `${data[xy1].labs_d.labs_del_by_test}`, 
                            x_cells[1] + (cells[2]/2), 
                            t_y2, 
                            {maxWidth : cells[2], align : 'center'}
                        )
                        doc.text( ( data[xy1].labs_d.labs_del_by_test_codes.length > 0 ? data[xy1].labs_d.labs_del_by_test_codes.join(', '): '-' ), 
                            x_cells[2] + (cells[3]/2), 
                            t_y2, 
                            {maxWidth : cells[3], align : 'center'}
                        )
                        doc.text( `${data[xy1].labs_d.labs_disc_by_test}`, 
                            x_cells[3] + (cells[4]/2), 
                            t_y2, 
                            {maxWidth : cells[4], align : 'center'}
                        )
                        doc.text( ( data[xy1].labs_d.labs_disc_by_test_codes.length > 0 ? data[xy1].labs_d.labs_disc_by_test_codes.join(', '): '-' ), 
                            x_cells[4] + (cells[5]/2), 
                            t_y2, 
                            {maxWidth : cells[5], align : 'center'}
                        )
                    }

                    t_y2 += (fs/4) + 1;

                
                    doc.line( 
                        t_x1, 
                        t_y1, 
                        t_x1, 
                        t_y2
                    );
                    doc.line( 
                        x_cells[0], 
                        t_y1, 
                        x_cells[0], 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[1], 
                        t_y1, 
                        x_cells[1], 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[2], 
                        t_y1, 
                        x_cells[2], 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[3], 
                        t_y1, 
                        x_cells[3], 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[5], 
                        t_y1, 
                        x_cells[5], 
                        t_y2 
                    );

                    doc.line( t_x1, t_y2, t_x2, t_y2 );
            
            // PAGE N.
                doc.setFontSize(9);
                doc.text( `${++page}`, pad_x + 150, pad_y+261, {align : 'center'} );

        // P6
            doc.addPage();
            doc.addImage(BG_6, "JPEG", 0,0, doc_w, doc_h,undefined,'FAST');           

            // XY
                x = pad_x;
                y = pad_y;

            // TABLE
                var t_x1 = x;
                var t_x2 = max_w+pad_x;

                var t_y1 = y+115;
                var t_y2 = t_y1

                var cells = [
                    38,
                    35,
                    30,
                ];

                t_x1 += pad_x

                var x_cells = [
                    t_x1 + cells[0],
                    t_x1 + cells[0] + cells[1],
                    t_x1 + cells[0] + cells[1] + cells[2],
                ];

                // TOP
                    doc.line( t_x1, t_y1, x_cells[2], t_y1 );

                    // FS
                        fs = 10
                        doc.setFontSize(fs);
                        doc.setFont('Helvetica', 'bold')

                        t_y2 += (fs/4) + fs_diff;

                        doc.text( 'PARÁMETRO', 
                            t_x1 + (cells[0]/2), 
                            t_y2+3.5, 
                            {maxWidth : cells[0], align : 'center'}
                        )
                        doc.text( 'Valor asignado',
                            x_cells[0] + (cells[1]/2), 
                            t_y2+2, 
                            {maxWidth : cells[1], align : 'center'}
                        )
                        doc.text( 'Sigma experimental (o)', 
                            x_cells[1] + (cells[2]/2), 
                            t_y2+1, 
                            {maxWidth : cells[2], align : 'center'}
                        )

                        t_y2 += 9

                // CONTENTS
                    doc.line( t_x1, t_y2, x_cells[2], t_y2 );
                    doc.setFont('Helvetica', '')

                    for( var xy1 in data ) {
                        t_y2 += (fs/4) + fs_diff;
                        doc.text( self.cropText ( data[xy1].param.name_cert, 18, '' ), 
                            t_x1+2, 
                            t_y2, 
                            {maxWidth : cells[0], align : 'left'}
                        )
                        doc.text( `${data[xy1].data.val_ass}`,
                            x_cells[0] + (cells[1]/2), 
                            t_y2, 
                            {maxWidth : cells[1], align : 'center'}
                        )
                        doc.text( `${data[xy1].data.sigma_o}`, 
                            x_cells[1] + (cells[2]/2), 
                            t_y2, 
                            {maxWidth : cells[2], align : 'center'}
                        )
                        
                    }

                    t_y2 += (fs/4) + fs_diff;

                
                    doc.line( 
                        t_x1, 
                        t_y1, 
                        t_x1, 
                        t_y2
                    );
                    doc.line( 
                        x_cells[0], 
                        t_y1, 
                        x_cells[0], 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[1], 
                        t_y1, 
                        x_cells[1], 
                        t_y2 
                    );
                    
                    doc.line( 
                        x_cells[2], 
                        t_y1, 
                        x_cells[2], 
                        t_y2 
                    );

                    doc.line( t_x1, t_y2, x_cells[2], t_y2 );
            
            // PAGE N.
                doc.setFontSize(9);
                doc.text( `${++page}`, pad_x + 150, pad_y+261, {align : 'center'} );

        // P7
            doc.addPage();
            doc.addImage(BG_7, "JPEG", 0,0, doc_w, doc_h,undefined,'FAST'); 

            // XY
                x = pad_x;
                y = pad_y;

            // TABLE
                var t_x1 = x;
                var t_x2 = max_w+pad_x;

                var t_y1 = y+60;
                var t_y2 = t_y1

                var cells = [
                    37.5,
                    37.5,
                    37.5,
                    37.5,
                ];
                var x_cells = [
                    t_x1 + cells[0],
                    t_x1 + cells[0] + cells[1],
                    t_x1 + cells[0] + cells[1] + cells[2],
                    t_x1 + cells[0] + cells[1] + cells[2] + cells[3],
                ];
 
                // TOP
                    doc.line( t_x1, t_y1, x_cells[3], t_y1 );

                    // FS
                        fs = 9
                        doc.setFontSize(fs);
                        doc.setFont('Helvetica', 'bold')

                        t_y2 += (fs/4) + fs_diff;

                        doc.text( 'PARÁMETRO', 
                            t_x1 + (cells[0]/2), 
                            t_y2+2.5, 
                            {maxWidth : cells[0], align : 'center'}
                        )
                        doc.text( 'Nº de laboratorios participantes con resultado enviado',
                            x_cells[0] + (cells[1]/2), 
                            t_y2-1, 
                            {maxWidth : cells[1], align : 'center'}
                        )
                        doc.text( 'Nº de laboratorios participantes aceptados por el Test', 
                            x_cells[1] + (cells[2]/2), 
                            t_y2-1, 
                            {maxWidth : cells[2], align : 'center'}
                        )
                        doc.text( 'Valor asignado', 
                            x_cells[2] + (cells[3]/2), 
                            t_y2+2, 
                            {maxWidth : cells[3], align : 'center'}
                        )

                        t_y2 += 7

                // CONTENTS
                    doc.line( x_cells[3], t_y1, x_cells[3], t_y2 );
                    doc.line( t_x1, t_y2, t_x2, t_y2 );
                    doc.setFont('Helvetica', '')

                    for( var xy1 in data ) {
                        t_y2 += (fs/4) + fs_diff;

                        doc.text( self.cropText ( data[xy1].param.name_cert, 18, '' ), 
                            t_x1+2, 
                            t_y2, 
                            {maxWidth : cells[0], align : 'left'}
                        )
                        doc.text( `${data[xy1].labs_d.labs_sent}`,
                            x_cells[0] + (cells[1]/2), 
                            t_y2, 
                            {maxWidth : cells[1], align : 'center'}
                        )
                        doc.text( `${data[xy1].labs_d.labs_acc_by_test}`, 
                            x_cells[1] + (cells[2]/2), 
                            t_y2, 
                            {maxWidth : cells[2], align : 'center'}
                        )
                        doc.text( `${data[xy1].data.val_ass}`, 
                            x_cells[2] + (cells[3]/2), 
                            t_y2, 
                            {maxWidth : cells[3], align : 'center'}
                        )

                    }

                    t_y2 += (fs/4) + fs_diff;

                    doc.line( 
                        t_x1, 
                        t_y1, 
                        t_x1, 
                        t_y2
                    );
                    doc.line( 
                        x_cells[0], 
                        t_y1, 
                        x_cells[0], 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[1], 
                        t_y1, 
                        x_cells[1], 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[2], 
                        t_y1, 
                        x_cells[2], 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[3], 
                        t_y1, 
                        x_cells[3], 
                        t_y2 
                    );

                    doc.line( t_x1, t_y2, t_x2, t_y2 );
        
                t_y2 += 8

            // TABLE
                t_x1 = x;
                t_x2 = max_w+pad_x;

               t_y1 = t_y2

                var cells = [
                    30,
                    30,
                    30,
                    30,
                    30,
                ];
                var x_cells = [
                    t_x1 + cells[0],
                    t_x1 + cells[0] + cells[1],
                    t_x1 + cells[0] + cells[1] + cells[2],
                    t_x1 + cells[0] + cells[1] + cells[2] + cells[3],
                    t_x1 + cells[0] + cells[1] + cells[2] + cells[3] + cells[4],
                ];

                // TOP
                    doc.line( t_x1, t_y1, t_x2, t_y1 );

                    // FS
                        fs = 7
                        doc.setFontSize(fs);
                        doc.setFont('Helvetica', 'bold')

                        t_y2 += (fs/4) + fs_diff;

                        doc.text( 'PARÁMETRO', 
                            t_x1 + (cells[0]/2), 
                            t_y2+1, 
                            {maxWidth : cells[0], align : 'center'}
                        )
                        doc.text( 'Z-Satisfactorio',
                            x_cells[0] + (cells[1]/2), 
                            t_y2+1, 
                            {maxWidth : cells[1], align : 'center'}
                        )
                        doc.text( 'z-Cuestionable', 
                            x_cells[1] + (cells[2]/2), 
                            t_y2+1, 
                            {maxWidth : cells[2], align : 'center'}
                        )
                        doc.text( 'Z-no Satisfactorio', 
                            x_cells[2] + (cells[3]/2), 
                            t_y2+1, 
                            {maxWidth : cells[3], align : 'center'}
                        )
                        doc.text( 'No evaluados', 
                            x_cells[3] + (cells[4]/2), 
                            t_y2+1, 
                            {maxWidth : cells[4], align : 'center'}
                        )

                        t_y2 += (fs/4) + fs_diff

                // CONTENTS
                    doc.line( x_cells[4], t_y1, x_cells[4], t_y2 );
                    doc.line( t_x1, t_y2, t_x2, t_y2 );
                    doc.setFont('Helvetica', '')

                    for( var xy1 in data ) {
                        t_y2 += (fs/4) + fs_diff;
                        doc.text( self.cropText ( data[xy1].param.name_cert, 18, '' ), 
                            t_x1+2, 
                            t_y2, 
                            {maxWidth : cells[0], align : 'left'}
                        )
                        doc.text( `${data[xy1].data.r_zscore.st.value}`,
                            x_cells[0] + (cells[1]/2), 
                            t_y2, 
                            {maxWidth : cells[1], align : 'center'}
                        )
                        doc.text( `${data[xy1].data.r_zscore.cu.value}`, 
                            x_cells[1] + (cells[2]/2), 
                            t_y2, 
                            {maxWidth : cells[2], align : 'center'}
                        )
                        doc.text( `${data[xy1].data.r_zscore.ns.value}`, 
                            x_cells[2] + (cells[3]/2), 
                            t_y2, 
                            {maxWidth : cells[3], align : 'center'}
                        )
                        doc.text( `${data[xy1].data.r_zscore.ne.value}`, 
                            x_cells[3] + (cells[4]/2), 
                            t_y2, 
                            {maxWidth : cells[4], align : 'center'}
                        )
                    }

                    t_y2 += (fs/4) + fs_diff;

                
                    doc.line( 
                        t_x1, 
                        t_y1, 
                        t_x1, 
                        t_y2
                    );
                    doc.line( 
                        x_cells[0], 
                        t_y1, 
                        x_cells[0], 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[1], 
                        t_y1, 
                        x_cells[1], 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[2], 
                        t_y1, 
                        x_cells[2], 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[3], 
                        t_y1, 
                        x_cells[3], 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[4], 
                        t_y1, 
                        x_cells[4], 
                        t_y2 
                    );

                    doc.line( t_x1, t_y2, t_x2, t_y2 );

            // PAGE N.
                doc.setFontSize(9);
                doc.text( `${++page}`, pad_x + 150, pad_y+261, {align : 'center'} );

        // P8
            doc.addPage();
            doc.addImage(BG_8, "JPEG", 0,0, doc_w, doc_h,undefined,'FAST');         

            // XY
                x = pad_x;
                y = pad_y;

            // TABLE
                var t_x1 = x;
                var t_x2 = max_w+pad_x;

                var t_y1 = y+35;
                var t_y2 = t_y1

                var cells = [
                    25,
                    22,
                    21,
                    17,
                    12,
                    54
                ];
                var x_cells = [
                    t_x1 + cells[0],
                    t_x1 + cells[0] + cells[1],
                    t_x1 + cells[0] + cells[1] + cells[2],
                    t_x1 + cells[0] + cells[1] + cells[2] + cells[3],
                    t_x1 + cells[0] + cells[1] + cells[2] + cells[3] + cells[4],
                    t_x1 + cells[0] + cells[1] + cells[2] + cells[3] + cells[4] + cells[5]
                ];

                // TOP
                    doc.line( t_x1, t_y1, x_cells[5], t_y1 );

                    // FS
                        fs = 7
                        doc.setFontSize(fs);
                        doc.setFont('Helvetica', 'bold')

                        t_y2 += (fs/4) + fs_diff;

                        doc.text( 'PARÁMETRO', 
                            t_x1 + (cells[0]/2), 
                            t_y2+2, 
                            {maxWidth : cells[0], align : 'center'}
                        )
                        doc.text( 'Nº de laboratorios aceptados por el Test',
                            x_cells[0] + (cells[1]/2), 
                            t_y2-1, 
                            {maxWidth : cells[1], align : 'center'}
                        )
                        doc.text( 'Valor asignado', 
                            x_cells[1] + (cells[2]/2), 
                            t_y2+2, 
                            {maxWidth : cells[2], align : 'center'}
                        )
                        doc.text( 'Incertidumbre U', 
                            x_cells[2] + (cells[3]/2), 
                            t_y2+2, 
                            {maxWidth : cells[3], align : 'center'}
                        )
                        doc.text( 'U (%)', 
                            x_cells[3] + (cells[4]/2), 
                            t_y2+2, 
                            {maxWidth : cells[4], align : 'center'}
                        )
                        doc.text( 'Comentarios', 
                            x_cells[4] + (cells[5]/2), 
                            t_y2+2, 
                            {maxWidth : cells[5], align : 'center'}
                        )

                        t_y2 += 6

                // CONTENTS
                    doc.line( t_x1, t_y2, x_cells[5], t_y2 );
                    doc.setFont('Helvetica', '')

                    for( var xy1 in data ) {
                        t_y2 += (fs/4) + fs_diff;
                        doc.text( self.cropText ( data[xy1].param.name_cert, 18, '' ), 
                            t_x1+2, 
                            t_y2, 
                            {maxWidth : cells[0], align : 'left'}
                        )
                        doc.text( `${data[xy1].labs_d.labs_acc_by_test}`,
                            x_cells[0] + (cells[1]/2), 
                            t_y2, 
                            {maxWidth : cells[1], align : 'center'}
                        )
                        doc.text( `${data[xy1].data.val_ass}`, 
                            x_cells[1] + (cells[2]/2), 
                            t_y2, 
                            {maxWidth : cells[2], align : 'center'}
                        )
                        doc.text( `${data[xy1].data.inc.in_u}`, 
                            x_cells[2] + (cells[3]/2), 
                            t_y2, 
                            {maxWidth : cells[3], align : 'center'}
                        )
                        doc.text( `${data[xy1].data.inc.u_per_cent}`, 
                            x_cells[3] + (cells[4]/2), 
                            t_y2, 
                            {maxWidth : cells[4], align : 'center'}
                        )
                        doc.text( `${data[xy1].data.inc.comment}` == "true" ? "Intervalo No válido para certifivar el parámetro" : "Intervalo válido para certifivar el parámetro", 
                            x_cells[4] + (cells[5]/2), 
                            t_y2, 
                            {maxWidth : cells[5], align : 'center'} 
                        )
                    }

                    t_y2 += (fs/4) + fs_diff;

                
                    doc.line( 
                        t_x1, 
                        t_y1, 
                        t_x1, 
                        t_y2
                    );
                    doc.line( 
                        x_cells[0], 
                        t_y1, 
                        x_cells[0], 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[1], 
                        t_y1, 
                        x_cells[1], 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[2], 
                        t_y1, 
                        x_cells[2], 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[3], 
                        t_y1, 
                        x_cells[3], 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[4], 
                        t_y1, 
                        x_cells[4], 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[5], 
                        t_y1, 
                        x_cells[5], 
                        t_y2 
                    );

                    doc.line( t_x1, t_y2, x_cells[5], t_y2 );

            // PAGE N.
                doc.setFontSize(9);
                doc.text( `${++page}`, pad_x + 150, pad_y+261, {align : 'center'} );

        // P9
            doc.addPage();
            doc.addImage(BG_9, "JPEG", 0,0, doc_w, doc_h,undefined,'FAST'); 

            // PAGE N.
                doc.setFontSize(9);
                doc.text( `${++page}`, pad_x + 150, pad_y+261, {align : 'center'} );

            // XY
                x = pad_x;
                y = pad_y;

            // TABLES
                var t_x1 = x;
                var t_x2 = max_w+pad_x;

                var t_y1 = y+55;
                var t_y2 = t_y1

                var cells = [
                        40,
                        35,
                        70,
                    ];

                var x_cells = [
                    t_x1 + cells[0],
                    t_x1 + cells[0] + cells[1],
                    t_x1 + cells[0] + cells[1] + cells[2],
                ];

                var n = 0;
                var rounds = 0;

                for( var xy1 in data ) {
                    // TABLE NAME
                        if( rounds == 0 )
                            t_y2+40;
                        
                        t_y1 = t_y2;

                        // TABLE LINES
                            doc.setFillColor(255,204,153);
                            doc.rect(t_x1, t_y2, cells[0], 5, "F")

                            doc.line( 
                                t_x1, 
                                t_y2, 
                                t_x1+cells[0], 
                                t_y2 
                            );

                            t_y2 += 5;

                            doc.line( 
                                t_x1+cells[0], 
                                t_y1, 
                                t_x1+cells[0], 
                                t_y2 
                            );

                            doc.line( 
                                t_x1+cells[0], 
                                t_y2, 
                                x_cells[2], 
                                t_y2
                            );

                            t_y2 += 5;

                            doc.line( 
                                t_x1, 
                                t_y2, 
                                x_cells[2], 
                                t_y2
                            );

                             t_y2 += 5;

                            doc.line( 
                                t_x1, 
                                t_y2, 
                                x_cells[2], 
                                t_y2 
                            );

                        // TABLE NAME TEXT
                            fs = 8;
                            doc.setFontSize(fs);
                            doc.setFont('Helvetica', 'bold')
                            doc.text( `${parseInt(data[xy1].param.order) + 1} - ` + self.cropText(data[xy1].param.name_cert, 19), t_x1+2, t_y1+3, {maxWidth : cells[0]});

                            doc.setFont('Helvetica', '')
                            // doc.text("RESULTADOS INICIALES APORTADOS POR LOS LABORATORIOS", (x_cells[2] - (x_cells[2]/2)) + 18, t_y1+8.5, {maxWidth : x_cells[2], align:'left'});
                            doc.text("RESULTADOS INICIALES APORTADOS POR LOS LABORATORIOS", (x_cells[2] - (x_cells[2]/2)) + 18 , t_y1+8.5, { align:'center'});

                            doc.text("Código participantes", x_cells[0] - (cells[0]/2), t_y1+13.5, {maxWidth : cells[0], align:'center'});
                            doc.text("Resultado", x_cells[1] - (cells[1]/2), t_y1+13.5, {maxWidth : cells[1], align:'center'});
                            doc.text("Método", x_cells[2] - (cells[2]/2), t_y1+13.5, {maxWidth : cells[2], align:'center'});

                            t_y2 += 5;

                        // CONTENT
                            //console.log(data[xy1].param.name_cert, data[xy1].bylab)
                            for ( var xy2 in data[xy1].bylab ) {

                                doc.text(`${parseInt(xy2)+1}`, x_cells[0] - (cells[0]/2), t_y2, {maxWidth : cells[0], align:'center'});
                                doc.text(`${data[xy1].bylab[xy2].res}`, x_cells[1] - 15, t_y2, {maxWidth : cells[1], align:'right'});
                                
                                doc.text( data[xy1].bylab[xy2].res === "" ? "" : ( data[xy1].bylab[xy2].is_calc == 1 ? ( data[xy1].bylab[xy2].method !== "" && data[xy1].bylab[xy2].method !== undefined ? data[xy1].bylab[xy2].method : "Cálculo" ) : data[xy1].bylab[xy2].method )  , x_cells[2] - (cells[2]/2), t_y2, {maxWidth : cells[2], align:'center'}) 

                                t_y2 += (fs/4) + fs_diff;

                            }

                        // CONTENT LINES
                            doc.line( 
                                t_x1, 
                                t_y1, 
                                t_x1, 
                                t_y2 
                            );
                            doc.line( 
                                x_cells[0], 
                                t_y1+10, 
                                x_cells[0], 
                                t_y2 
                            );
                            doc.line( 
                                x_cells[1], 
                                t_y1+10, 
                                x_cells[1], 
                                t_y2 
                            );
                            doc.line( 
                                x_cells[2], 
                                t_y1+5, 
                                x_cells[2], 
                                t_y2 
                            );

                            doc.line( 
                                t_x1, 
                                t_y2, 
                                x_cells[2], 
                                t_y2 
                            );

                        t_y2 += 2.5;
                        
                        n++;
                        if( ( n > 0 && n%2 == 0 && rounds < Object.keys(data).length -1 ) || rounds == 0 ) {
                            n=0;
                            t_y2 = y+5;

                            doc.addPage();
                            doc.addImage(BG, "JPEG", 0,0, doc_w, doc_h,undefined,'FAST');

                            // PAGE N.
                                doc.setFontSize(9);
                                doc.text( `${++page}`, pad_x + 150, pad_y+261, {align : 'center'} );
                        }

                        rounds++;
                }

        // P10
            doc.addPage();
            doc.addImage(BG_10, "JPEG", 0,0, doc_w, doc_h,undefined,'FAST'); 

            // PAGE N.
                doc.setFontSize(9);
                doc.text( `${++page}`, pad_x + 150, pad_y+261, {align : 'center'} );

            // XY
                x = pad_x;
                y = pad_y;

            // TABLES
                var t_x1 = x;
                var t_x2 = max_w + pad_x;

                var t_y1 = y + 30;
                var t_y2 = t_y1

                var cells = [
                        35,
                        37.5,
                        37.5,
                        45
                    ];

                var x_cells = [
                    t_x1 + cells[0],
                    t_x1 + cells[0] + cells[1],
                    t_x1 + cells[0] + cells[1] + cells[2],
                    t_x1 + cells[0] + cells[1] + cells[2] + cells[3],
                ];

                var n = 0;
                var rounds = 0;

                for( var xy1 in data ) {
                    // TABLE NAME
                        t_y1 = t_y2;

                        // TABLE LINES
                            doc.line( 
                                t_x1, 
                                t_y2, 
                                t_x1+cells[0], 
                                t_y2 
                            );

                            t_y2 += 5;

                            doc.line( 
                                t_x1+cells[0], 
                                t_y1, 
                                t_x1+cells[0], 
                                t_y2 
                            );

                            doc.line( 
                                t_x1+cells[0], 
                                t_y2, 
                                x_cells[3], 
                                t_y2
                            );

                            t_y2 += 5;

                            doc.line( 
                                t_x1, 
                                t_y2, 
                                x_cells[3], 
                                t_y2
                            );

                            t_y2 += 5;

                            doc.line( 
                                t_x1, 
                                t_y2, 
                                x_cells[3], 
                                t_y2 
                            );

                        // TABLE NAME TEXT 
                            fs = 8;
                            doc.setFontSize(fs);
                            doc.setFont('Helvetica', 'bold')
                            doc.text( `${parseInt(data[xy1].param.order) + 1} - ` + self.cropText(data[xy1].param.name_cert, 18), t_x1+2, t_y1+3, {maxWidth : cells[0]});

                            doc.setFont('Helvetica', '')
                            doc.text("RESULTADOS INICIALES APORTADOS POR LOS LABORATORIOS", (x_cells[3] - (x_cells[3]/2)) + 18 , t_y1+8.5, { align:'center'});

                            doc.text("Código participantes", x_cells[0] - (cells[0]/2), t_y1+13.5, {maxWidth : cells[0], align:'center'});
                            doc.text("Resultado", x_cells[1] - (cells[1]/2), t_y1+13.5, {maxWidth : cells[1], align:'center'});
                            doc.text("Z-Score", x_cells[2] - (cells[2]/2), t_y1+13.5, {maxWidth : cells[2], align:'center'});
                            doc.text("Evaluación", x_cells[3] - (cells[3]/2), t_y1+13.5, {maxWidth : cells[3], align:'center'});

                            t_y2 += 5;

                        // CONTENT
                            for ( var xy2 in data[xy1].bylab ) {
                                doc.text(`${parseInt(xy2)+1}`, x_cells[0] - (cells[0]/2), t_y2, {maxWidth : cells[0], align:'center'});
                                doc.text(`${data[xy1].bylab[xy2].res}`, x_cells[1] - (cells[1]/2), t_y2, {maxWidth : cells[1], align:'center'});

                                var score_v = Math.abs(data[xy1].bylab[xy2].zscore.value);
                                if( score_v <= 2 )
                                    doc.setTextColor(91,155,213);
                                else if( score_v > 2 && score_v <= 3 )
                                    doc.setTextColor(237,125,49);
                                else
                                    doc.setTextColor(255,0,0);

                                doc.text(`${data[xy1].bylab[xy2].zscore.value}`, x_cells[2] - 15, t_y2, {maxWidth : cells[2], align:'right'}) 
                                doc.text(`${data[xy1].bylab[xy2].zscore.eval}`, x_cells[3] - (cells[3]/2), t_y2, {maxWidth : cells[3], align:'center'}) 

                                doc.setTextColor(0,0,0);

                                t_y2 += (fs/4) + fs_diff;
                            }

                        // CONTENT LINES
                            doc.line( 
                                t_x1, 
                                t_y1, 
                                t_x1, 
                                t_y2 
                            );
                            doc.line( 
                                x_cells[0], 
                                t_y1+10, 
                                x_cells[0], 
                                t_y2 
                            );
                            doc.line( 
                                x_cells[1], 
                                t_y1+10, 
                                x_cells[1], 
                                t_y2 
                            );
                            doc.line( 
                                x_cells[2], 
                                t_y1+10, 
                                x_cells[2], 
                                t_y2 
                            );
                            doc.line( 
                                x_cells[3], 
                                t_y1+5, 
                                x_cells[3], 
                                t_y2 
                            )                    

                            doc.line( 
                                t_x1, 
                                t_y2, 
                                x_cells[3], 
                                t_y2 
                            );

                    t_y2 += 2.5;
     
                    n++;

                    if( ( n > 0 && n%2 == 0 && rounds < Object.keys(data).length -1 ) || rounds == 0 ) {
                        n=0;
                        t_x1 = x;
                        t_y2 = y+5;

                        doc.addPage();
                        doc.addImage(BG, "JPEG", 0,0, doc_w, doc_h,undefined,'FAST');

                        // PAGE N.
                            doc.setFontSize(9);
                            doc.text( `${++page}`, pad_x + 150, pad_y+261, {align : 'center'} );
                    }

                    rounds++;
                }

        // P11
            doc.addPage();
            doc.addImage(BG_11, "JPEG", 0,0, doc_w, doc_h,undefined,'FAST'); 

            // PAGE N.
                doc.setFontSize(9);
                doc.text( `${++page}`, pad_x + 150, pad_y+261, {align : 'center'} );

            // XY
                x = pad_x;
                y = pad_y;

                var n = 0;
                var rounds = 0;

                var t_y1 = y+45;
                var t_y2 = t_y1

                for( var x = 0; x < images.length; x++ )
                {
                    doc.addImage(images[x], "JPEG", pad_x, ( n==0 ? t_y2 : ((max_w*600)/850) + t_y2 ) , max_w, (max_w*600)/850 ,undefined,'FAST'); 
                    n++;
                    if( ( n > 0 && n%2 == 0 && rounds < Object.keys(data).length -1 ) || rounds == 0 ) {
                        n=0;
                        t_y2 = y+5;

                        doc.addPage();
                        doc.addImage(BG, "JPEG", 0,0, doc_w, doc_h,undefined,'FAST'); 

                        // PAGE N.
                            doc.setFontSize(9);
                            doc.text( `${++page}`, pad_x + 150, pad_y+261, {align : 'center'} );
                    }

                    rounds++;
                }

        // P12
            doc.addPage();
            doc.addImage(BG_12, "JPEG", 0,0, doc_w, doc_h,undefined,'FAST'); 
            
            // XY
                x = pad_x;
                y = pad_y;

            //
                fs = 11;
                doc.setFontSize(fs);
                doc.setFont('Helvetica', '')

                y += 140;

               for( var xy1 in data ) {
                    y += (fs/4) + fs_diff;
                    doc.text( `${(parseInt(data[xy1].param.order) + 1)}`, x + 10, y, {maxWidth : max_w, align : 'left'} );
                    doc.text( data[xy1].param.name + ( data[xy1].param.format !== "" ? ' (' + data[xy1].param.format + ')' : '' ) , x + 25, y, {maxWidth : max_w, align : 'left'} );
                }

            // PAGE N.
                doc.setFontSize(9);
                doc.text( `${++page}`, pad_x + 150, pad_y+261, {align : 'center'} );

        // P13
            doc.addPage();
            doc.addImage(BG_13, "JPEG", 0,0, doc_w, doc_h,undefined,'FAST'); 
            
            // XY
                x = pad_x;
                y = pad_y;

            // TABLE
                var t_x1 = x;
                var t_x2 = max_w+pad_x;

                var t_y1 = y+20;
                var t_y2 = t_y1

                var cells = [
                    70,
                    70,
                ];
                var x_cells = [
                    t_x1 + cells[0],
                    t_x1 + cells[0] + cells[1],
                ]

                // TOP LINES
                    doc.line( t_x1, t_y1, x_cells[1], t_y1 );

                // TOP TEXT
                    fs = 9
                    doc.setFontSize(fs);
                    doc.setFont('Helvetica', 'bold')

                    doc.text( 
                        "Actividad", 
                        t_x1 + 2, 
                        t_y2 + 3.5, 
                        {maxWidth : cells[0], align : 'left'} 
                    );
                     doc.text( 
                        "Fecha", 
                        x_cells[0] + 2, 
                        t_y2 + 3.5, 
                        {maxWidth : cells[1], align : 'left'} 
                    ); 

                    t_y2 += 5;

                    doc.line( t_x1, t_y2, x_cells[1], t_y2 );

                    doc.setFont('Helvetica', '')

                    t_y2 += (fs/4) + fs_diff;


                    var [s_y, s_m, s_d] = data[0].sample.send_samples_date.split('-');
                    var [r_y, r_m, r_d] = data[0].sample.send_results_date.split('-');

                    var s_date = new Date(s_y, s_m, s_d);
                    var r_date = new Date(r_y, r_m, r_d);

                    doc.text( 
                        "Envío de las muestras", 
                        t_x1 + 2, 
                        t_y2, 
                        {maxWidth : cells[1], align : 'left'} 
                    ); 
                    doc.text( 
                        `${months[s_date.getMonth()]} ${s_date.getFullYear()}`, 
                        x_cells[0] + 2, 
                        t_y2, 
                        {maxWidth : cells[1], align : 'left'} 
                    ); 

                    t_y2 += (fs/4) + fs_diff;;

                    doc.text( 
                        "Envío de los resultados", 
                        t_x1 + 2, 
                        t_y2, 
                        {maxWidth : cells[1], align : 'left'} 
                    ); 
                    doc.text( 
                        `Hasta el ${r_date.getDate()} de ${months[s_date.getMonth()]} del ${r_date.getFullYear()}`, 
                        x_cells[0] + 2, 
                        t_y2, 
                        {maxWidth : cells[1], align : 'left'} 
                    ); 

                    t_y2+= (fs/4) + fs_diff;;

                    doc.text( 
                        "Informe", 
                        t_x1 + 2, 
                        t_y2, 
                        {maxWidth : cells[1], align : 'left'} 
                    ); 
                    doc.text( 
                        "Menos de 1 mes desde el envío de resultados", 
                        x_cells[0] + 2, 
                        t_y2, 
                        {maxWidth : cells[1], align : 'left'} 
                    ); 

                    t_y2 += (fs/4) + fs_diff;

                // CONTENT LINES  
                    doc.line( 
                        t_x1, 
                        t_y1, 
                        t_x1, 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[0], 
                        t_y1, 
                        x_cells[0], 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[1], 
                        t_y1, 
                        x_cells[1], 
                        t_y2  
                    );

                    doc.line( 
                        t_x1, 
                        t_y2, 
                        x_cells[1], 
                        t_y2 
                    );

            // PAGE N.
                doc.setFontSize(9);
                doc.text( `${++page}`, pad_x + 150, pad_y+261, {align : 'center'} );

        // P14
            doc.addPage();
            doc.addImage(BG_14, "JPEG", 0,0, doc_w, doc_h,undefined,'FAST'); 
            
            // XY
                x = pad_x;
                y = pad_y;

            // TABLE
                var t_x1 = x;
                var t_x2 = max_w+pad_x;

                var t_y1 = y+23;
                var t_y2 = t_y1

                var cells = [
                    20,
                    38.5,
                    37,
                    37.5,
                ]

                var x_cells = [
                    t_x1 + cells[0],
                    t_x1 + cells[0] + cells[1],
                    t_x1 + cells[0] + cells[1] + cells[2],
                    t_x1 + cells[0] + cells[1] + cells[2] + cells[3],
                ]

                // TOP LINES
                    doc.line( t_x1, t_y1, x_cells[3], t_y1 );

                    // FS
                        fs = 8
                        doc.setFontSize(fs);
                        doc.setFont('Helvetica', 'bold')

                        doc.text( 
                            "Determinación analítica", 
                            x_cells[1]-(cells[1]/2), 
                            t_y2+7, 
                            {maxWidth : cells[1], align : 'center'} 
                        );
                        doc.text( 
                            "Decimales para trabajar estadísticamente (a ser posible)", 
                            x_cells[2]-(cells[2]/2), 
                            t_y2+4, 
                            {maxWidth : cells[2], align : 'center'} 
                        );
                        doc.text( 
                            "Decimales a mostrar en el informe", 
                            x_cells[3]-(cells[3]/2),                                     
                            t_y2+7, 
                            {maxWidth : cells[3], align : 'center'} 
                        );

                        t_y2 += 13;
                        doc.line( x_cells[0], t_y2, x_cells[3], t_y2 );
                        doc.setFont('Helvetica', '')

                // TOP TEXT
                    for( var xy1 in data ) {

                        var decimals = (data[xy1].param.decimals == 0 ? 'sin' : `${data[xy1].param.decimals}`) + " decimales";
                        var decimals_in = (data[xy1].param.decimals == 0 ? 'sin' : ( data[xy1].param.decimals > 4 ? "4" : `${data[xy1].param.decimals}` )) + " decimales";
                        t_y2 +=4;

                        doc.text( 
                            `${(parseInt(data[xy1].param.order) + 1)}`,
                            x_cells[0] - (cells[0]/2), 
                            t_y2, 
                            {maxWidth : cells[0], align : 'center'} 
                        );
                        doc.text( 
                            self.cropText(data[xy1].param.name, 19), 
                            x_cells[0]+2, 
                            t_y2, 
                            {maxWidth : cells[1], align : 'left'} 
                        );
                        doc.text( 
                            decimals, 
                            x_cells[2] - (cells[2]/2), 
                            t_y2, 
                            {maxWidth : cells[2], align : 'center'} 
                        );
                        doc.text( 
                            decimals_in, 
                            x_cells[3] - (cells[3]/2), 
                            t_y2, 
                            {maxWidth : cells[3], align : 'center'} 
                        );
                        
                    }
                    t_y2 +=4;

                // CONTENT LINES
                    doc.line( 
                        t_x1, 
                        t_y1, 
                        t_x1, 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[0], 
                        t_y1, 
                        x_cells[0], 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[1], 
                        t_y1, 
                        x_cells[1], 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[2], 
                        t_y1, 
                        x_cells[2], 
                        t_y2 
                    );
                    doc.line( 
                        x_cells[3], 
                        t_y1, 
                        x_cells[3], 
                        t_y2 
                    );

                    doc.line( 
                        t_x1, 
                        t_y2, 
                        x_cells[3], 
                        t_y2 
                    );

            // PAGE N.
                doc.setFontSize(9);
                doc.text( `${++page}`, pad_x + 150, pad_y+261, {align : 'center'} );
  
        // SAVE
            const date = new Date(data[0].sample.expire_date);

            var mnt = date.getMonth()+1;
                mnt = mnt < 10 ? '0'+ mnt : mnt;

            var pdf = btoa(doc.output());
            //var fd = new FormData();
            //    fd.append('action', 'savePDF')
            //    fd.append('name', `informe-${data[0].sample.lot}-${data[0].sample.name}`)
            //    fd.append('data', pdf)
/*
            var fd = {}
                fd['name'] = `informe-${data[0].sample.lot}-${data[0].sample.name}`
                fd['data'] = pdf


                //f7.store.dispatch('ajax', fd)
                f7.store.dispatch('gabApi', {
                    controller: 'certivin',
                    data: fd
                })
*/


            var pdf = doc.output('blob');
            const filename = `informe-${data[0].sample.lot}-${data[0].sample.name}`;

            f7.store.dispatch('uploadFile', {
                pdf_blob: pdf,
                filename: filename
            })
        
            doc.save(`Informe-${data[0].sample.lot}-${data[0].sample.name}.pdf`)

            /*
            var pdf = btoa(doc.output());
            var fd = new FormData();
                fd.append('action', 'savePDF')
                fd.append('name', `${data[0].sample.lot}_${mnt}${date.getFullYear()}.pdf`)
                fd.append('data', pdf)
                
            f7.store.dispatch('ajax', fd)
            */

    }
    generatePDF ( data ) {
        var self = this;
                var graphs = [];

                for( var x in data ) {
                    graphs.push(
                        self.doGraphToImg ( 
                            self.doGraph( 
                                { 
                                    title : (parseInt(data[x].param.order)+1) + ' - ' + data[x].param.name,
                                    data : data[x].graphs
                                } 
                            )
                        )
                    )   
                }

                Promise.all(graphs).then(r => {
                    self.doPDF( data, r );
                });
        
    }
}