<template>
    <f7-page name="labs">
        <!-- Top Navbar -->
        <f7-navbar>
            <div id="pageMenu" slot="before-inner">
                <f7-menu id="pageMenu">
                    <f7-menu-item :animate="false" href="/" current text="Análisis"></f7-menu-item>
                    <f7-menu-item :animate="false" href="/labs/" text="Laboratorios"></f7-menu-item>
                    <f7-menu-item :animate="false" href="/params/" text="Parámetros"></f7-menu-item>
                    <f7-menu-item :animate="false" href="/methods/" text="Métodos"></f7-menu-item>
                </f7-menu>
            </div>
            <div slot="right">
                <f7-link icon-only icon-f7="gear_alt" popover-open="#optionsPopover"></f7-link>
            </div>
        </f7-navbar>
        <!-- Toolbar-->
        <f7-toolbar bottom>
            <f7-link><img :src="'./static/logotipo.png'" height="40"></f7-link>
            <span class="last_version">{{lastVersion}}</span>
        </f7-toolbar>
        <!-- Toolbar-->
        <f7-popover id="optionsPopover" class="popover-menu" :backdrop="false">
            <f7-list>
                <f7-list-item @click="doLogout" link="#" popover-close title="Cerrar sessión"></f7-list-item>
            </f7-list>
        </f7-popover>
        <f7-popover id="actionsPopover" class="popover-menu" :backdrop="false">
            <f7-list>
                <f7-list-item popover-close title="Nuevo Análisis"></f7-list-item>
            </f7-list>
        </f7-popover>
        <!-- Toolbar-->
        <!-- Page content-->
        <f7-block strong>
            <f7-block-title>
                {{title}}
            </f7-block-title>
            <f7-block-header class="">
                <f7-row>
                    <f7-col width="5">
                        <f7-segmented>
                            <f7-button small title="Volver" icon-f7="arrow_left" back></f7-button>
                        </f7-segmented>
                    </f7-col>
                    <f7-col width="90"></f7-col>
                    <f7-col width="5">
                        <f7-segmented>
                            <f7-button small title="Guardar" form="editForm" type="submit"  icon-f7="floppy_disk"></f7-button>
                        </f7-segmented>
                    </f7-col>
                </f7-row>
            </f7-block-header>
            <f7-block class="pageContent" style="height: calc(100% - 50px);">
                <form id="editForm" @submit="sendForm">
                    <f7-block-title>
                        Información
                    </f7-block-title>
                    <f7-block>
                        <f7-list no-hairlines>
                            <f7-list-input name="name" label="Nombre del lote" type="text" placeholder="Escribe el nombre del lote" clear-button required v-model:value="form_values.name" />
                            <f7-list-input name="lot" label="Número de lote" type="text" placeholder="Escribe un número de lote" clear-button required v-model:value="form_values.lot" />
                            <f7-list-input name="ref" label="Número de referencia" type="text" placeholder="Escribe un número de referencia" clear-button required v-model:value="form_values.ref" />
                            <f7-list-input name="expire_date" label="Fecha caducidad" type="date" required v-model:value="form_values.expire_date" />

                            <f7-list-input name="send_samples_date" @change="putRDate" label="Fecha envío muestras" type="date" required v-model:value="form_values.send_samples_date" />
                            <f7-list-input name="send_results_date" label="Fecha envío resultados" type="date" required v-model:value="form_values.send_results_date" />

                        </f7-list>
                    </f7-block>
                    <f7-block-title>
                        Validez del formulario
                    </f7-block-title>
                    <f7-block>
                        <f7-list no-hairlines>
                            <f7-list-input name="token_validity" label="Tiempo de validez" type="number" placeholder="Escribe un número" clear-button required v-model:value="form_values.token_validity" />
                            <f7-list-input label="Formato de tiempo" type="select" required v-model:value="form_values.token_validity_time">
                                <f7-icon icon="demo-list-icon" slot="media"></f7-icon>
                                <option value="">Elige un formato</option>
                                <option value="days">Día/s</option>
                                <option value="months">Mes/es</option>
                                <option value="years">Año/s</option>
                            </f7-list-input>
                        </f7-list>
                    </f7-block>
                    <f7-block-title>
                        Laboratorios
                    </f7-block-title>
                    <f7-block>
                        <f7-list no-hairlines>
                            <f7-list-item class="smartSelectLabs" title="Lista laboratorios" @smartselect:closed="smartValueLab" smart-select :smart-select-params="{openIn: 'popup', searchbar: true, searchbarPlaceholder: 'Buscar laboratorio', renderSearchbar : allSelector}">
                                <select multiple>
                                    <option v-for="(item, index) in labs" :value="item.id">{{item.name}}</option>
                                </select>
                            </f7-list-item>
                        </f7-list>

                        <f7-list id="sortableLabs" no-hairlines v-if="lab_list.length > 0" @sortable:sort="sortLabs" sortable sortable-enabled sortable-opposite>
                            <f7-list-item v-for="(item, index) in lab_list" :title="(parseInt(index)+1) + '. ' + returnLab(item)"></f7-list-item>
                        </f7-list>
                    </f7-block>

                    <f7-block-title>
                        Parámetros
                    </f7-block-title>
                    <f7-block>
                        <f7-list no-hairlines>
                            <f7-list-item class="smartSelectParams" title="Lista Parámetros" @smartselect:closed="smartValueParam" smart-select :smart-select-params="{openIn: 'popup', searchbar: true, searchbarPlaceholder: 'Buscar parametros', renderSearchbar : allSelector}">
                                <select multiple>
                                    <option v-for="(item, index) in params" :value="item.id">{{item.name}}</option>
                                </select>
                            </f7-list-item>
                        </f7-list>

                        <f7-list id="sortableParams" no-hairlines v-if="params_list.length > 0" @sortable:sort="sortParams" sortable sortable-enabled sortable-opposite>
                            <f7-list-item v-for="(item, index) in params_list" :title="(parseInt(index)+1) + '. ' + returnParam(item)"></f7-list-item>
                        </f7-list>
                    </f7-block>

                </form>
            </f7-block>
        </f7-block>
    </f7-page>
</template>
<script>
import {
    f7,
    useStore
}
from 'framework7-vue';

export default {
    setup() {
        const doLogout = () => f7.store.dispatch('doLogout');
        const samples = useStore('getSamples');
        const getSamples = () => f7.store.dispatch('getSamples', { page: 1, count: 100 });
        const labs = useStore('getLabs');
        const getLabs = () => f7.store.dispatch('getLabs');
        const params = useStore('getParams');
        const getParams = () => f7.store.dispatch('getParams');
        const lastVersion = useStore('lastVersion');
        return {
            doLogout,
            samples,
            getSamples,
            labs,
            getLabs,
            params,
            getParams,
            lastVersion
        }
    },
    data() {
        return {
            form_values: {},
            lab_list : [],
            params_list : [],
            title: '',
        }
    },
    props: {
        id: null,
        action: null,
        f7route: Object,
        f7router: Object,
    },
    methods: {
        sortLabs( e ) {
            const self = this;
            var tmp = [];
                tmp = self.lab_list;
            
            var t = tmp[e.from];
                tmp.splice( e.from, 1 );
                tmp.splice( e.to, 0, t );
        },
        sortParams( e ) {
            const self = this;
            var tmp = [];
                tmp = self.params_list;
            
            var t = tmp[e.from];
                tmp.splice( e.from, 1 );
                tmp.splice( e.to, 0, t );
        },
        allSelector() {
            return `<form class="searchbar">
                <div class="searchbar-inner">
                    <div>
                        <label class="item-checkbox item-content">
                            <input class="checkAll" type="checkbox" value="all"><i class="icon icon-checkbox"></i>
                        </label>
                    </div>
                    <div class="searchbar-input-wrap">
                        <input type="search" spellcheck="false" placeholder="Buscar laboratorio"><i class="searchbar-icon"></i><span class="input-clear-button"></span>
                    </div>
                </div>
            </form>`;
        },
        returnLab (v) {
            const self = this;
            const labs = self.labs;

            const k = Object.keys(labs).find(key => labs[key].id == v);

            return labs[k] !== undefined ? labs[k].name : '';
        },
        returnParam (v) {
            const self = this;
            const params = self.params;

            const k = Object.keys(params).find(key => params[key].id == v);

            return params[k] !== undefined ? params[k].name : '';
        },
        removeFromArray(array, value) {
            const index = array.indexOf(value);
                if (index > -1) {
                    array.splice(index, 1);
                }
            return array;
        },
        smartValueLab(e) {
            const self = this;
            const smart = f7.smartSelect.get(e.target);
            var values = smart.getValue();
            var crt_values = [...self.lab_list];

                if( crt_values.length < 1 ) {
                    self.lab_list = values;
                    return;
                }

            var exist = []; // EXIST
            var to_remove = []; // TO REMOVE

                for (var i = 0; i < crt_values.length; i++) {
                    if ( values.includes(crt_values[i]) ) 
                        exist.push(crt_values[i]);
                    else
                        to_remove.push(crt_values[i]);
                }

                for (var i = 0; i < exist.length; i++) {
                    values = self.removeFromArray(values, exist[i]);
                }

                self.lab_list =  exist.concat(values);
        },
        smartValueParam(e) {
            const self = this;
            const smart = f7.smartSelect.get(e.target);
            var values = smart.getValue();
            var crt_values = [...self.params_list];
            
            var k = Object.keys(values).find(key => values[key] === "all");

                if( crt_values.length < 1 ) {
                    self.params_list = values;
                    return;
                }

            var exist = []; // EXIST
            var to_remove = []; // TO REMOVE

                for (var i = 0; i < crt_values.length; i++) {
                    if ( values.includes(crt_values[i]) ) 
                        exist.push(crt_values[i]);
                    else
                        to_remove.push(crt_values[i]);
                }

                for (var i = 0; i < exist.length; i++) {
                    values = self.removeFromArray(values, exist[i]);
                }

                self.params_list =  exist.concat(values);
        },
        sendForm(e) {
            const self = this;
            const values = this.form_values;

            values.labs = self.lab_list;
            values.params = self.params_list;

            if (values.labs.length == 0) {
                f7.dialog.alert('No se han seleccionado laboratorios');
            }
             if (values.params.length == 0) {
                f7.dialog.alert('No se han seleccionado parámetros');
            } else {
                if (self.action == 'edit') {
                    values['action'] = 'updateSample';

                    f7.store.dispatch('ajax', values).then((result) => {
                        if (result.code == 200) {
                            f7.dialog.alert('', 'Guardado', () => self.f7router.back());
                        } else
                            f7.dialog.alert(result.msg);
                    })
                } else {
                    values['action'] = 'newSample';

                    f7.store.dispatch('ajax', values).then((result) => {
                        if (result.code == 200) {
                            f7.dialog.alert('', 'Guardado', () => self.f7router.back());
                        } else
                            f7.dialog.alert(result.msg);
                    })
                }
            }
            
            e.preventDefault();
            return false;
        },
        putRDate(e) {
            var el = e.target;
            var target = document.querySelector('[name="send_results_date"]');

            var value = el.value;

            var [year, month, day] = value.split('-');

            var date = new Date(year, month, day);
                //date.setMonth(date.getMonth()+1)

             console.log(year, month, day, ' - ', date);
/*
            var date = new Date(value);
                date.setMonth(date.getMonth()+1)

            var lastDayDate = new Date(date.getFullYear(), date.getMonth(), 0);


             console.log(date, date.getMonth());
                //console.log(date, date.getFullYear(), date.getMonth(), lastDayDate);
    */
/*
            
            var d  = `${lastDayDate.getFullYear()}-${lastDayDate.getMonth() < 10 ? '0'+lastDayDate.getMonth() : lastDayDate.getMonth()}-${lastDayDate.getDate()}`;



                this.form_values.send_results_date = d

            console.log(lastDayDate, d);
            */
        }
    },

    mounted() {
        const self = this;
            self.getLabs();
            self.getParams();

        const action = self.action;

        switch (action) {
            case 'add':
                self.title = 'Nueva Muestra';
                self.form_values = {
                    name: '',
                    ref : '',
                    lot: '',
                    token_validity: '',
                    token_validity_time: '',
                    expire_date : '',
                    send_samples_date:'',
                    send_results_date:'',
                    labs: [],
                    params : []
                };

                break;
            case 'edit':
                const sample = self.samples[self.id];

                delete sample.created;
                delete sample.forms;
                delete sample.forms_vars;
                delete sample.last_change;
                delete sample.tokens;

                self.form_values = sample;
                
                self.title = `Editar Muestra ${sample.lot}`;

                self.lab_list = sample.labs
                self.params_list = sample.params

                setTimeout(() => {
                    f7.smartSelect.get('.smartSelectLabs > .smart-select').setValue(sample.labs);
                    f7.smartSelect.get('.smartSelectParams > .smart-select').setValue(sample.params);
                }, 100);


                break;
        }

        f7.$(document).on('change', '.checkAll', function() {
            const _this = this;
            const parent = _this.closest('.smart-select-page');
            const box = _this.checked;

            var els = parent.querySelectorAll('[name^="checkbox-"]');

            for( var x = 0; x < els.length; x++ )
            {
               f7.$(els[x]).prop('checked', box).trigger('change');
            }
        })
    }
}
</script>