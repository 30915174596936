<template>
    <f7-page name="params">
        <!-- Top Navbar -->
        <f7-navbar>
            <div id="pageMenu" slot="before-inner">
                <f7-menu id="pageMenu">
                    <f7-menu-item :animate="false" href="/" text="Análisis"></f7-menu-item>
                    <f7-menu-item :animate="false" href="/labs/" text="Laboratorios"></f7-menu-item>
                    <f7-menu-item :animate="false" href="/params/" current text="Parámetros"></f7-menu-item>
                    <f7-menu-item :animate="false" href="/methods/" text="Métodos"></f7-menu-item>
                </f7-menu>
            </div>
            <div slot="right">
                <f7-link icon-only icon-f7="gear_alt" popover-open="#optionsPopover"></f7-link>
            </div>
        </f7-navbar>
        <!-- Toolbar-->
        <f7-toolbar bottom>
            <f7-link><img :src="'./static/logotipo.png'" height="40"></f7-link>
            <span class="last_version">{{lastVersion}}</span>
        </f7-toolbar>
        <!-- Toolbar-->
        <f7-popover id="optionsPopover" class="popover-menu" :backdrop="false">
            <f7-list>
                <f7-list-item @click="doLogout" link="#" popover-close title="Cerrar sessión"></f7-list-item>
            </f7-list>
        </f7-popover>
        <f7-popover id="actionsPopover" class="popover-menu" :backdrop="false">
            <f7-list>
                <f7-list-item popover-close title="Nuevo Análisis"></f7-list-item>
            </f7-list>
        </f7-popover>
        <!-- Toolbar-->
        <!-- Page content-->
        <f7-block strong>
            <f7-block-title>
                {{title}}
            </f7-block-title>
            <f7-row>
                <f7-col width="5">
                    <f7-segmented>
                        <f7-button small  title="Volver" icon-f7="arrow_left" back></f7-button>
                    </f7-segmented>
                </f7-col>
                <f7-col width="90"></f7-col>
                <f7-col width="5">
                    <f7-segmented>
                        <f7-button small title="Guardar" form="editForm" type="submit"  icon-f7="floppy_disk"></f7-button>
                    </f7-segmented>
                </f7-col>
            </f7-row>
            <f7-block class="pageContent">
                <form id="editForm" @submit="sendForm">

                    <f7-block-title>
                        Información
                    </f7-block-title>

                    <f7-block>
                        <f7-list no-hairlines>
                            <f7-list-input label="Abreviación" type="text" placeholder="Abreviación" clear-button v-model:value="form_values.shortname"></f7-list-input>
                            <f7-list-input label="Nombre" type="text" placeholder="Nombre" clear-button v-model:value="form_values.name"></f7-list-input>
                            <f7-list-input label="Nombre en Inglés" type="text" placeholder="Nombre en Inglés" clear-button v-model:value="form_values.name_en"></f7-list-input>
                            <f7-list-input label="Nombre en Francés" type="text" placeholder="Nombre en Francés" clear-button v-model:value="form_values.name_fr"></f7-list-input>
                            <f7-list-input label="Nombre abreviado" type="text" placeholder="Nombre abreviado" clear-button v-model:value="form_values.name_cert"></f7-list-input>
                        </f7-list>
                    </f7-block>

                    <f7-block>
                        <f7-list no-hairlines>

                            <f7-list-input label="Unidad de medida" type="text" placeholder="Unidad de medida" clear-button v-model:value="form_values.unit"></f7-list-input>
                            <f7-list-input label="Número de decimales" type="text" placeholder="Número de decimales" clear-button v-model:value="form_values.decimals"></f7-list-input>
                            <f7-list-input label="Método" type="text" placeholder="Método" clear-button v-model:value="form_values.method"></f7-list-input>
                            
                        </f7-list>
                    </f7-block>

                    <f7-block>
                        <f7-list no-hairlines>

                            <f7-list-item>
                                <span>¿Es un cálculo?</span>
                                <f7-toggle  v-model:checked="form_values.is_calc"></f7-toggle>
                            </f7-list-item>
                            <f7-list-input label="Cálculo" type="text" placeholder="Cálculo" clear-button v-model:value="form_values.calc"></f7-list-input>

                        </f7-list>
                    </f7-block>

                    <f7-block>
                        <f7-list no-hairlines>

                            <f7-list-item>
                                <span>¿Mostrar en el formulario?</span>
                                <f7-toggle v-model:checked="form_values.in_form"></f7-toggle>
                            </f7-list-item>
                            
                        </f7-list>
                    </f7-block>
                </form>
            </f7-block>
        </f7-block>
    </f7-page>
</template>
<script>
import {
    f7,
    useStore
}
from 'framework7-vue';

export default {
    setup() {
        const doLogout = () => f7.store.dispatch('doLogout');
        const params = useStore( 'getParams' );
        const getParams = () => f7.store.dispatch( 'getParams' );
        const lastVersion = useStore('lastVersion');
        return {
            doLogout,
            params,
            getParams,
            lastVersion
        }
    },
    data() {
        return {
            form_values: {},
            title: ''
        }
    },
    props: {
        id: null,
        action: null,
        f7route: Object,
        f7router: Object,
    },
    methods: {

        sendForm(e) {
            const self = this;
            const values = this.form_values;

            if (self.action == 'edit') {
                values['action'] = 'updateParam';

                f7.store.dispatch('ajax', values).then((result) => {

                    if (result.code == 200)
                         f7.dialog.alert('', 'Guardado', () => self.f7router.back());
                    else
                        f7.dialog.alert(result.msg);
                })
            } else {
                values['action'] = 'newParam';

                f7.store.dispatch('ajax', values).then((result) => {
                    if (result.code == 200)
                        f7.dialog.alert('', 'Guardado', () => self.f7router.back());
                    else
                        f7.dialog.alert(result.msg);
                })
            }
            
            e.preventDefault();
            return false;

        }
    },
    mounted() {
        const self = this;
        const param = self.params[self.id];
        var p = {};

            for( let k in param ) {
                if( k == "is_calc" || k == "in_form" )
                    p[k] = Boolean(param[k]);
                else
                    p[k] = param[k];
            }

            self.form_values = p;

        const action = self.action;

        switch (action) {
            case 'add':
                self.title = 'Nuevo Parámetro';
                self.form_values = {
                    id : '',
                    p_order : '',
                    name : '',
                    name_en : '',
                    name_fr : '',
                    shortname : '',
                    name_cert : '',
                    unit : '',
                    decimals : '',
                    method : '',
                    is_calc : 1,
                    calc : '',
                    in_form : 1,
                    status : 1,
                };
                break;
            case 'edit':
                self.title = `Editar Parámetro ${param.name}`;
                break;
        }
    
    }
}
</script>