<template>
	<f7-page name="home">
		<!-- Top Navbar -->
		<f7-navbar>
			<div
				id="pageMenu"
				slot="before-inner">
				<f7-menu id="pageMenu">
					<f7-menu-item
						:animate="false"
						href="/"
						current
						text="Análisis"></f7-menu-item>
					<f7-menu-item
						:animate="false"
						href="/labs/"
						text="Laboratorios"></f7-menu-item>
					<f7-menu-item
						:animate="false"
						href="/params/"
						text="Parámetros"></f7-menu-item>
					<f7-menu-item
						:animate="false"
						href="/methods/"
						text="Métodos"></f7-menu-item>
				</f7-menu>
			</div>
			<div slot="right">
				<f7-link
					icon-only
					icon-f7="gear_alt"
					popover-open="#optionsPopover"></f7-link>
			</div>
		</f7-navbar>
		<!-- Toolbar-->
		<f7-toolbar bottom>
			<f7-link
				><img
					:src="'./static/logotipo.png'"
					height="40"
			/></f7-link>
			<span class="last_version">{{ lastVersion }}</span>
		</f7-toolbar>
		<!-- Toolbar-->
		<f7-popover
			id="optionsPopover"
			class="popover-menu"
			:backdrop="false">
			<f7-list>
				<f7-list-item
					@click="doLogout"
					link="#"
					popover-close
					title="Cerrar sessión"></f7-list-item>
			</f7-list>
		</f7-popover>
		<f7-popover
			id="actionsPopover"
			class="popover-menu"
			:backdrop="false">
			<f7-list>
				<f7-list-item
					popover-close
					title="Nuevo Análisis"></f7-list-item>
			</f7-list>
		</f7-popover>
		<!-- Toolbar-->
		<!-- Page content-->
		<f7-block strong>
			<f7-block-title> Cálculo de las muestras - {{ current_s !== null ? current_s.name : "" }} {{ current_s !== null ? current_s.lot : "" }} </f7-block-title>

			<f7-block-header class="">
				<f7-row>
					<f7-col width="5">
						<f7-segmented>
							<f7-button
								small
								title="Volver"
								icon-f7="arrow_left"
								back></f7-button>
						</f7-segmented>
					</f7-col>
					<f7-col width="80"></f7-col>
					<f7-col width="5">
						<f7-segmented>
							<f7-button
								small
								@click="loadPageContent"
								title="Recargar"
								icon-material="autorenew"></f7-button>
						</f7-segmented>
					</f7-col>
					<f7-col width="5">
						<f7-segmented>
							<f7-button
								small
								title="Ver certificado"
								@click="doPDF"
								icon-f7="doc_text"></f7-button>
						</f7-segmented>
					</f7-col>
					<f7-col width="5">
						<f7-segmented>
							<f7-button
								small
								title="Ver informe"
								@click="doCertificate"
								icon-f7="doc_chart"></f7-button>
						</f7-segmented>
					</f7-col>
				</f7-row>
			</f7-block-header>

			<f7-block
				class="pageContent"
				style="height: calc(100% - 50px)">
				<!-- VALUES -->
				<f7-block-title></f7-block-title>
				<f7-block-title>VALORES DE LAS MUESTRAS</f7-block-title>
				<f7-block
					class="data-table"
					v-if="getSamples">
					<table class="">
						<thead>
							<tr>
								<td
									class="label-cell"
									style="width: 40px">
									N
								</td>
								<td class="label-cell">Laboratorio</td>
								<td
									class="label-cell text-align-right certilink"
									v-for="(item, index) in getHeads"
									:width="col_size[index]"
									@click="goTo(`param-${item.id}`)">
									{{ item.name }}
								</td>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in getSamples">
								<td class="label-cell">{{ index + 1 }}</td>
								<td class="label-cell tableLabName">{{ item.name }}</td>
								<td
									class="label-cell text-align-right"
									style="white-space: nowrap"
									v-for="(subitem, subindex) in item"
									v-show="subindex != 'name' && subindex != 'id_lab'"
									:class="paramClass(subitem)">
									{{ isNaN(subitem.rounded) ? subitem.raw : subitem.rounded }}

									<div class="mvChanger">
										<f7-checkbox
											v-if="subitem.params !== undefined && !Number.isNaN(subitem.value) && subitem.params.id == 3"
											@change="changeMass"
											type="checkbox"
											:value="index + ':false'"
											:checked="!subitem.mass"
											:disabled="!subitem.mass"></f7-checkbox>
										<f7-checkbox
											v-if="subitem.params !== undefined && !Number.isNaN(subitem.value) && subitem.params.id == 14"
											@change="changeMass"
											type="checkbox"
											:value="index + ':true'"
											:checked="subitem.mass"
											:disabled="subitem.mass"></f7-checkbox>

										<f7-checkbox
											v-if="subitem.params !== undefined && !Number.isNaN(subitem.value) && subitem.params.id == 6"
											@change="changeAtt"
											type="checkbox"
											:value="index + ':true'"
											:checked="subitem.att"
											:disabled="subitem.att"></f7-checkbox>
										<f7-checkbox
											v-if="subitem.params !== undefined && !Number.isNaN(subitem.value) && subitem.params.id == 29"
											@change="changeAtt"
											type="checkbox"
											:value="index + ':false'"
											:checked="!subitem.att"
											:disabled="!subitem.att"></f7-checkbox>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</f7-block>

				<!-- VALUES -->
				<f7-block
					class="data-table"
					v-if="getMedianas">
					<table class="">
						<thead>
							<tr>
								<td
									class="label-cell"
									style="width: 170px"></td>
								<td
									class="label-cell text-align-right"
									v-for="(item, index) in getHeads.filter((item) => item.id != 29)"
									:width="col_size[index]">
									{{ item.name }}
								</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th
									class="label-cell"
									style="width: 170px">
									Mediana Robusta
								</th>
								<td
									class="label-cell text-align-right"
									v-for="(item, index) in getMedianas">
									{{ item.rounded }}
								</td>
							</tr>
							<tr>
								<th
									class="label-cell"
									style="width: 170px">
									MAD
								</th>
								<td
									class="label-cell text-align-right"
									v-for="(item, index) in getMedianasAbs">
									{{ item.rounded }}
								</td>
							</tr>
						</tbody>
					</table>
				</f7-block>

				<!-- ABS -->
				<f7-block-title>ABS</f7-block-title>

				<f7-block
					class="data-table"
					v-if="getAbs">
					<table>
						<thead>
							<tr>
								<td
									class="label-cell"
									style="width: 40px">
									N
								</td>
								<td class="label-cell">Laboratorio</td>
								<td
									class="label-cell text-align-right"
									v-for="(item, index) in getHeads.filter((item) => item.id != 29)"
									:width="col_size[index]">
									{{ item.name }}
								</td>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in getAbs">
								<td
									class="label-cell"
									style="width: 40px">
									{{ parseInt(index) + 1 }}
								</td>
								<td
									class="label-cell tableLabName"
									style="width: 170px">
									{{ item.name }}
								</td>
								<td
									class="label-cell text-align-right"
									v-for="(subitem, subindex) in item"
									v-show="subindex != 'name'">
									{{ subitem.display }}
								</td>
							</tr>
						</tbody>
					</table>
				</f7-block>

				<!-- DISCREPANTE -->
				<f7-block-title>DISCREPANTE 1 (K >= {{ getK }})</f7-block-title>
				<f7-block
					class="data-table"
					v-if="getDisc">
					<table>
						<thead>
							<tr>
								<td
									class="label-cell"
									style="width: 40px">
									N
								</td>
								<th class="label-cell">Laboratorio</th>
								<td
									class="label-cell text-align-right"
									v-for="(item, index) in getHeads.filter((item) => item.id != 29)"
									:width="col_size[index]">
									{{ item.name }}
								</td>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in getDisc">
								<td
									class="label-cell"
									style="width: 40px">
									{{ parseInt(index) + 1 }}
								</td>
								<td
									class="label-cell tableLabName"
									style="width: 170px">
									{{ item.name }}
								</td>
								<td
									class="label-cell text-align-right"
									v-for="(subitem, subindex) in item"
									v-show="subindex != 'name'"
									:class="subitem.disc ? 'disc' : subitem.rounded > 2 && subitem.rounded < 3 ? 'int' : ''">
									{{ subitem.display }}
								</td>
							</tr>
						</tbody>
					</table>
				</f7-block>

				<!-- CÀLCULOS ESTADISTICOS -->
				<f7-block-title>CÀLCULOS ESTADISTICOS</f7-block-title>
				<f7-block
					class="data-table"
					v-if="getCE">
					<f7-block
						class="data-table-el"
						v-for="(item1, index1) in getCE"
						:id="`param-${item1.params.id}`">
						<f7-row>
							<f7-col
								class="sectionTitle certilink"
								@click="goTo('sigma')"
								>P{{ parseInt(index1) + 1 }} . {{ item1.params.name }}</f7-col
							>
						</f7-row>
						<f7-row>
							<f7-col width="75">
								<table class="tableList">
									<thead>
										<tr>
											<td
												class="label-cell"
												style="width: 40px">
												N
											</td>
											<td class="label-cell">Labs</td>
											<td class="label-cell">N lab</td>
											<td class="label-cell text-align-right">Valores orden</td>
											<td class="label-cell text-align-right">ABS</td>
											<td class="label-cell text-align-right">Discrepante</td>
											<td class="label-cell text-align-right"></td>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(item2, index2) in item1.samples"
											:class="item1.disc[index2].ignore ? 'ignored' : ''">
											<td
												class="label-cell"
												style="width: 40px">
												{{ parseInt(index2) + 1 }}
											</td>
											<td class="label-cell tableLabName">{{ item1.sorted[index2].name }}</td>
											<td class="label-cell">{{ item1.sorted[index2].order }}</td>
											<td class="label-cell text-align-right">{{ item1.disc[index2].ignore ? "-" : isNaN(item1.sorted[index2].rounded) ? "-" : item1.sorted[index2].rounded }}</td>
											<td class="label-cell text-align-right">{{ item1.disc[index2].ignore ? "-" : isNaN(item1.abs[index2].rounded) ? "-" : item1.abs[index2].rounded }}</td>
											<td
												class="label-cell text-align-right"
												:class="item1.disc[index2].disc ? 'disc' : item1.disc[index2].rounded > 2 && item1.disc[index2].rounded < 3 ? 'int' : ''">
												{{ item1.disc[index2].ignore ? "-" : isNaN(item1.disc[index2].rounded) ? "-" : item1.disc[index2].rounded }}
											</td>
											<td class="label-cell text-align-center">
												<f7-checkbox
													@change="disableDisc"
													type="checkbox"
													:value="index1 + ':' + item1.sorted[index2].id"
													:checked="!item1.disc[index2].ignore"></f7-checkbox>
											</td>
										</tr>
									</tbody>
								</table>
							</f7-col>
							<f7-col width="25">
								<table class="tableList margin-bottom">
									<tbody>
										<tr>
											<th>Mediana</th>
											<td>{{ getCeMeds[index1].mediana.rounded }}</td>
										</tr>
										<tr>
											<th>MAD</th>
											<td>{{ getCeMeds[index1].mad.rounded }}</td>
										</tr>
										<tr>
											<th>K</th>
											<td>{{ getK }}</td>
										</tr>
										<tr>
											<td></td>
											<td></td>
										</tr>
										<tr
											v-for="(item3, index3) in getCalcs[index1]"
											v-if="getCalcs">
											<td
												class="label-cell"
												:class="item3.strong ? 'strong' : ''">
												{{ item3.name }}
											</td>
											<td
												class="label-cell"
												:class="item3.strong ? 'strong' : ''">
												{{ item3.rounded }}
											</td>
										</tr>
									</tbody>
								</table>
								<div class="text-align-center">
									Revisado
									<f7-checkbox
										class="check"
										@change="changeCheck"
										type="checkbox"
										:value="item1.params.id"
										:data-next="getCE[parseInt(index1) + 1] !== undefined ? 'param-' + getCE[parseInt(index1) + 1].params.id : 'sigma'"
										:checked="getVars.check !== undefined ? (`${getVars.check[item1.params.id]}` === 'true' ? true : false) : false"></f7-checkbox>
								</div>
							</f7-col>
						</f7-row>
					</f7-block>
				</f7-block>

				<!-- SIGMA -->
				<f7-block-title
					id="sigma"
					class="highlightTitle"
					style="font-weight: bold"
					>PREVIA CERTIFICADO</f7-block-title
				>
				<f7-block
					class="data-table"
					v-if="getCalcs">
					<table class="tableList">
						<thead>
							<tr>
								<td class="label-cell">Parametro</td>

								<td class="label-cell text-align-right">Valor asignado</td>
								<td class="label-cell text-align-right">+/- U</td>

								<td class="label-cell text-align-center">n</td>

								<td
									class="label-cell text-align-center"
									width="300px">
									Límite de aceptación (1*σ, 67%)
								</td>
								<td class="label-cell text-align-center">Límite de aceptación (2*σ, 95%)</td>

								<td
									class="label-cell text-align-center"
									width="20">
									Indic.
								</td>
								<td
									class="label-cell text-align-center"
									width="20">
									Revisado
								</td>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(item, index) in getCalcs"
								v-if="getCalcs">
								<td class="label-cell">
									<span
										class="certilink"
										@click="goTo('param-' + item.sigma.params.id)"
										>{{ item.sigma.param }}</span
									>
								</td>

								<td class="label-cell text-align-right">{{ item.sigma.promedio.rounded }}</td>
								<td class="label-cell text-align-right">{{ item.sigma.uKu.rounded }}</td>

								<td class="label-cell text-align-center border-right">{{ item.sigma.n }}</td>

								<td
									class="label-cell text-align-right"
									:class="item.sigma.o.d.rounded < 0 ? 'disc' : ''">
									<table class="sigma-table">
										<tbody>
											<tr>
												<td
													width="45%"
													class="no-borders text-align-center">
													{{ item.sigma.o.d.rounded < 0 ? 0 : item.sigma.o.d.rounded }}
												</td>
												<td
													width="10%"
													class="no-borders text-align-center">
													-
												</td>
												<td
													width="45%"
													class="no-borders text-align-center">
													{{ item.sigma.o.s.rounded < 0 ? 0 : item.sigma.o.s.rounded }}
												</td>
											</tr>
										</tbody>
									</table>
								</td>
								<td
									class="label-cell text-align-right"
									:class="item.sigma.o2.d.rounded < 0 ? 'disc' : ''">
									<table class="sigma-table">
										<tbody>
											<tr>
												<td
													width="45%"
													class="no-borders text-align-center">
													{{ item.sigma.o2.d.rounded < 0 ? 0 : item.sigma.o2.d.rounded }}
												</td>
												<td
													width="10%"
													class="no-borders text-align-center">
													-
												</td>
												<td
													width="45%"
													class="no-borders text-align-center">
													{{ item.sigma.o2.s.rounded < 0 ? 0 : item.sigma.o2.s.rounded }}
												</td>
											</tr>
											<tr>
												<td
													colspan="3"
													class="no-borders text-align-center">
													({{ item.sigma.o2.r.rounded }} / {{ item.sigma.o2.r2.rounded }})
												</td>
											</tr>
										</tbody>
									</table>
								</td>

								<td class="label-cell text-align-center">
									<f7-checkbox
										@change="changeIndicative"
										type="checkbox"
										:value="index"
										:checked="item.sigma.indicative"></f7-checkbox>
								</td>
								<td class="label-cell text-align-center">
									<f7-icon
										v-if="`${getVars.check !== undefined ? getVars.check[item.id_param] : 'false'}` === 'true'"
										material="check"></f7-icon>
									<f7-icon
										v-else
										material="close"></f7-icon>
								</td>
							</tr>
						</tbody>
					</table>
				</f7-block>

				<f7-block-title>Z-SCORE LABORATORIOS</f7-block-title>
				<f7-block
					class="data-table"
					v-if="getZScore">
					<f7-block
						class="data-table-el"
						v-for="(item1, index1) in getZScore">
						<f7-row>
							<f7-col class="sectionTitle">Z{{ parseInt(index1) + 1 }} . {{ item1.params.name }}</f7-col>
						</f7-row>
						<f7-row>
							<f7-col width="75">
								<table class="tableList">
									<thead>
										<tr>
											<td
												class="label-cell"
												style="width: 40px">
												N
											</td>
											<td class="label-cell">Labs</td>
											<td class="label-cell text-align-center">N Lab</td>
											<td class="label-cell text-align-right">Valores orden</td>
											<td class="label-cell text-align-right">V.Lab-V.mig</td>
											<td class="label-cell text-align-right">Discrepant?</td>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item2, index2) in item1.values">
											<td
												class="label-cell"
												style="width: 40px">
												{{ parseInt(index2) + 1 }}
											</td>
											<td class="label-cell tableLabName">{{ item2.name }}</td>
											<td class="label-cell text-align-center">{{ item2.id_lab }}</td>
											<td class="label-cell text-align-right">{{ isNaN(item2.value.rounded) ? "-" : item2.value.rounded }}</td>
											<td class="label-cell text-align-right">{{ isNaN(item2.vlab_vmig.rounded) ? "-" : item2.vlab_vmig.rounded }}</td>
											<td
												class="label-cell text-align-right"
												:class="chooseClass(item2.disc.type)">
												{{ isNaN(item2.disc.rounded) ? "-" : item2.disc.rounded }}
											</td>
										</tr>
									</tbody>
								</table>
							</f7-col>
							<f7-col width="25">
								<table class="tableList margin-bottom">
									<tbody>
										<tr>
											<th class="label-cell">Promedio</th>
											<td class="label-cell text-align-right">{{ getCalcs[index1].promedio.rounded }}</td>
										</tr>
										<tr>
											<th class="label-cell">σ</th>
											<td class="label-cell text-align-right">{{ getCalcs[index1].o.rounded }}</td>
										</tr>
										<tr>
											<th class="label-cell"></th>
											<td class="label-cell text-align-right"></td>
										</tr>
										<tr>
											<th class="label-cell">{{ item1.results.st.name }}</th>
											<td class="label-cell text-align-right">{{ item1.results.st.value }}</td>
										</tr>
										<tr>
											<th class="label-cell">{{ item1.results.cu.name }}</th>
											<td class="label-cell text-align-right">{{ item1.results.cu.value }}</td>
										</tr>
										<tr>
											<th class="label-cell">{{ item1.results.ns.name }}</th>
											<td class="label-cell text-align-right">{{ item1.results.ns.value }}</td>
										</tr>
										<tr>
											<th class="label-cell">{{ item1.results.ne.name }}</th>
											<td class="label-cell text-align-right">{{ item1.results.ne.value }}</td>
										</tr>
										<tr>
											<th class="label-cell border-black">{{ item1.results.total.name }}</th>
											<td class="label-cell text-align-right border-black">{{ item1.results.total.value }}</td>
										</tr>
									</tbody>
								</table>
							</f7-col>
						</f7-row>
					</f7-block>
				</f7-block>
			</f7-block>
		</f7-block>
	</f7-page>
</template>
<script>
	import { f7, useStore } from "framework7-vue";

	//import calc from '../components/calc.js';
	import calc from "../../js/calc.js";
	import cert from "../../components/cert.js";

	import { jsPDF } from "jspdf";

	// -----

	import CERT_BG1 from "../../static/pdf/cert/analisis_bg1.jpg";
	import CERT_BG2 from "../../static/pdf/cert/analisis_bg2.jpg";
	import CERT_SYM from "../../static/pdf/cert/symbol.png";

	// -----

	export default {
		setup() {
			// GLOBAL
			const doLogout = () => f7.store.dispatch("doLogout");

			// DATA
			const samples = useStore("getSamples");
			const getS = () => f7.store.dispatch("getSamples", { page: 1, count: 100 });

			const sConfig = useStore("getCalcConfig");
			const getSamplesConfig = () => f7.store.dispatch("getSamplesConfig");

			const labs = useStore("getLabs");
			const allLabs = useStore("getAllLabs");
			const getAllLabs = () => f7.store.dispatch("getAllLabs");

			const sampleVars = useStore("getSampleVars");
			const getSampleVars = (id) => f7.store.dispatch("getSampleVars", id);

			// CALC
			const getSamples = useStore(calc, "getSamples");
			const getMedianas = useStore(calc, "getMedianas");
			const getAbs = useStore(calc, "getAbs");
			const getMedianasAbs = useStore(calc, "getMedianasAbs");
			const getDisc = useStore(calc, "getDisc");
			const getCE = useStore(calc, "getCE");
			const getHeads = useStore(calc, "getHeads");
			const getVars = useStore(calc, "getVars");
			const getCalcs = useStore(calc, "getCalcs");
			const getZScore = useStore(calc, "getZScore");
			const getCeMeds = useStore(calc, "getCeMeds");
			const getK = useStore(calc, "getK");
			const getSFull = useStore(calc, "getS");

			const getResult = () => calc.dispatch("calcResults");

			const setConfig = (array) => calc.dispatch("setConfig", array);
			const setSamples = (array) => calc.dispatch("setSamples", array);
			const setVars = (array) => calc.dispatch("setVars", array);
			const setLabs = (array) => calc.dispatch("setLabs", array);
			const setParams = (array) => calc.dispatch("setParams", array);
			const setSampleLabs = (array) => calc.dispatch("setSampleLabs", array);
			const lastVersion = useStore("lastVersion");

			return {
				doLogout,

				samples,
				getS,
				sConfig,
				getSamplesConfig,

				labs,
				allLabs,
				getAllLabs,

				sampleVars,
				getSampleVars,

				getResult,
				getSamples,
				getMedianas,
				getAbs,
				getMedianasAbs,
				getDisc,
				getCE,
				getHeads,
				getVars,
				getCalcs,
				getZScore,
				getCeMeds,
				getK,
				getSFull,

				setConfig,
				setSamples,
				setVars,
				setLabs,
				setParams,
				setSampleLabs,
				lastVersion,
			};
		},
		data() {
			return {
				first_row: [],
				col_size: [
					"90px", // '1'
					"90px", // '2'
					"90px", // '3'
					"90px", // '4'
					"90px", // '5'
					"90px", // '6'
					"90px", // '7'
					"90px", // '8'
					"90px", // '9'
					"90px", // '10''
					"90px", // '11''
					"90px", // '12''
					"90px", // '13''
					"90px", // '14'
					"90px", // '15'
				],
				k: 0,
				current_s: null,
				check: {},
			};
		},
		props: {
			id: null,
		},
		computed: {},
		methods: {
			goTo(el) {
				var tg = document.getElementById(el);
				tg.scrollIntoView({ behavior: "smooth" });
			},
			paramClass(params) {
				if (params !== undefined) {
					var classes = [];

					if (params.unit == "g/cm3 DR" && !params.mass) {
						classes.push("disc");
					}

					return classes.join(" ");
				}
			},
			round(number, decimal) {
				const factorOfTen = Math.pow(10, decimal);
				const r = Math.round((number + Number.EPSILON) * factorOfTen) / factorOfTen;
				return r.toFixed(decimal);
			},
			findKeyInObject(array, param, value) {
				return Object.keys(array).find((key) => (array[key] !== undefined ? array[key][param] == value : 0));
			},
			findKeyInObject_s(array, value) {
				return Object.keys(array).find((key) => (array[key] !== undefined ? array[key] == value : 0));
			},

			chooseClass(n) {
				switch (n) {
					case 1:
						return "status_c";
						break;
					case 2:
						return "status_ns";
						break;
				}
			},
			changeMass(e) {
				const self = this;
				const _this = e.target;
				const value = _this.value.split(":");

				var vars = self.getVars.length === undefined ? self.getVars : {};

				if (vars.mass === undefined) vars.mass = {};

				vars.mass[value[0]] = value[1];

				f7.store.dispatch("ajax", { action: "saveSampleVars", id: self.samples[self.id].id, value: vars }).then((r) => {
					f7.store.dispatch("getSampleVars", self.samples[self.id].id).then((result) => {
						self.setVars(vars);
						self.getResult();
					});
				});
			},
			changeAtt(e) {
				const self = this;
				const _this = e.target;
				const value = _this.value.split(":");

				var vars = self.getVars.length === undefined ? self.getVars : {};

				if (vars.att === undefined) vars.att = {};

				vars.att[value[0]] = value[1];

				f7.store.dispatch("ajax", { action: "saveSampleVars", id: self.samples[self.id].id, value: vars }).then((r) => {
					f7.store.dispatch("getSampleVars", self.samples[self.id].id).then((result) => {
						self.setVars(vars);
						self.getResult();
					});
				});
			},
			disableDisc(e) {
				const self = this;
				const _this = e.target;
				const value = _this.value.split(":");
				var vars = self.getVars.length === undefined ? self.getVars : {};

				if (vars.ignore === undefined) vars.ignore = {};

				if (vars.ignore[value[0]] === undefined) vars.ignore[value[0]] = {};

				vars.ignore[value[0]][value[1]] = _this.checked ? false : true;

				f7.store.dispatch("ajax", { action: "saveSampleVars", id: self.samples[self.id].id, value: vars }).then(() => {
					f7.store.dispatch("getSampleVars", self.samples[self.id].id).then((result) => {
						self.setVars(vars);
						self.getResult();
					});
				});
			},
			changeIndicative(e) {
				const self = this;
				const _this = e.target;
				const index = _this.value;

				var vars = self.getVars.length === undefined ? self.getVars : {};

				if (vars.indicative === undefined) vars.indicative = {};

				if (vars.indicative[index] === undefined) vars.indicative[index] = {};

				vars.indicative[index] = _this.checked;

				f7.store.dispatch("ajax", { action: "saveSampleVars", id: self.samples[self.id].id, value: vars }).then(() => {
					f7.store.dispatch("getSampleVars", self.samples[self.id].id).then((result) => {
						self.setVars(vars);
						self.getResult();
					});
				});
			},

			changeCheck(e) {
				const self = this;
				const _this = e.target;
				const index = _this.value;
				const next = _this.parentNode.dataset.next;

				//@click="goTo(`param-${getCalcs[parseInt(index)+1].id_param}`)"

				var vars = self.getVars.length === undefined ? self.getVars : {};

				if (vars.check === undefined) vars.check = {};

				if (vars.check[index] === undefined) vars.check[index] = {};

				vars.check[index] = _this.checked;

				if (_this.checked) self.goTo(`${next}`);
				else self.goTo(`param-${index}`);

				f7.store.dispatch("ajax", { action: "saveSampleVars", id: self.samples[self.id].id, value: vars }).then(() => {
					f7.store.dispatch("getSampleVars", self.samples[self.id].id).then((result) => {
						self.setVars(vars);
						self.getResult();
					});
				});
			},

			calcDate(date, v, vt) {
				var newDate = new Date(date);

				switch (vt) {
					case "days":
						newDate.setDate(newDate.getDate() + parseInt(v));
						break;
					case "months":
						newDate.setMonth(newDate.getMonth() + parseInt(v));
						break;
					case "years":
						newDate.setYear(newDate.getYear() + parseInt(v));
						break;
				}

				return newDate;
			},

			isNumeric(str) {
				if (typeof str != "string") return false; // we only process strings!
				return (
					!isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
					!isNaN(parseFloat(str))
				); // ...and ensure strings of whitespace fail
			},

			doPDF() {
				// -- VARS
				const self = this;
				const current_sample = self.current_s;
				const calcs = self.getCalcs;
				const date = new Date(current_sample.expire_date);

				// -- DATA
				var indicative = {};
				var no_indicative = {};

				for (let id in calcs) {
					if (calcs[id].sigma.indicative) indicative[id] = calcs[id];
					else no_indicative[id] = calcs[id];
				}

				// -- INIT PDF
				const doc = new jsPDF({
					orientation: "p",
					unit: "mm",
					format: "a4",
					putOnlyUsedFonts: true,
					floatPrecision: 16, // or "smart", default is 16
				});

				// --
				// - setFontSize(size)
				// - setFont(font, weight)
				// - text (text, x, y, {option1 : value1})
				// - line (x1, y1, x2, y2, style)
				// - addImage (imageData, format, x, y, width, height, alias, compression, rotation)

				// -- PARAMS
				const h1 = 8;
				const rST = 12.1;
				const rND = 197.9;
				const yv1 = 31;
				const xv1_1 = 120.5;
				const xv1_2 = 167.5;
				const xv1_3 = 186;
				var rw = yv1 + h1;

				var mnt = date.getMonth() + 1;
				mnt = mnt < 10 ? `0${mnt}` : mnt;

				// -- Page 1
				// -- Background
				doc.addImage(CERT_BG1, "JPEG", 0, 0, 210, 297, undefined, "FAST");

				// -- Header

				doc.setFontSize(7);
				doc.setFont("helvetica", "bold");
				doc.text("PARÁMETRO", rST, yv1 + 2.5, { align: "left" });
				doc.setFontSize(6);
				doc.setFont("helvetica", "normal");
				doc.text("PARAMETER", rST, yv1 + 5, { align: "left" });
				doc.text("PARAMETER", rST, yv1 + 7.5, { align: "left" });

				doc.setFontSize(7);
				doc.setFont("helvetica", "bold");
				doc.text("VALOR ASIGNADO", xv1_1 - 3, yv1 + 2.5, { align: "right" });
				doc.setFontSize(6);
				doc.setFont("helvetica", "normal");
				doc.text("ASSIGNED VALUE", xv1_1 - 3, yv1 + 5, { align: "right" });
				doc.text("VALEUR ATTRIBUÉE", xv1_1 - 3, yv1 + 7.5, { align: "right" });

				doc.setFontSize(7);
				doc.setFont("helvetica", "bold");
				doc.text("LÍMITE DE ACEPTACIÓN", xv1_2 - 11, yv1 + 2.5, { align: "right" });
				doc.setFont("helvetica", "normal");
				doc.text("(95%)", xv1_2 - 3, yv1 + 2.5, { align: "right" });
				doc.setFontSize(6);
				doc.text("ACCEPTANCE LIMIT", xv1_2 - 10.5, yv1 + 5, { align: "right" });
				doc.text("(95%)", xv1_2 - 3, yv1 + 5, { align: "right" });
				doc.text("LIMITE D'ACCEPTATION", xv1_2 - 10.5, yv1 + 7.5, { align: "right" });
				doc.text("(95%)", xv1_2 - 3, yv1 + 7.5, { align: "right" });

				doc.setFontSize(7);
				doc.setFont("helvetica", "bold");
				doc.text("+/- U", xv1_3 - 3, yv1 + 2.5, { align: "right" });

				doc.setFontSize(7);
				doc.setFont("helvetica", "bold");
				doc.text("n", rND - 3, yv1 + 2.5, { align: "right" });

				doc.line(rST, rw, rND, rw);

				// -- No indicatives

				for (let id in no_indicative) {
					doc.setFontSize(7);
					doc.setFont("helvetica", "bold");
					doc.text(`${no_indicative[id].sigma.params.name} (${no_indicative[id].sigma.params.format})`, rST, rw + 3, { align: "left" });
					doc.setFontSize(6);
					doc.setFont("helvetica", "normal");
					doc.text(`${no_indicative[id].sigma.params.name_en} | ${no_indicative[id].sigma.params.name_fr}`, rST, rw + 5.5, { align: "left" });

					rw += 7;

					doc.setFontSize(7);
					doc.setFont("helvetica", "bold");
					doc.text(`${no_indicative[id].promedio.rounded < 0 ? 0 : no_indicative[id].promedio.rounded}`, xv1_1 - 3, rw - 3, { align: "right" });
					doc.text(`${no_indicative[id].sigma2_1.rounded < 0 ? 0 : no_indicative[id].sigma2_1.rounded}` + " - " + `${no_indicative[id].sigma2_2.rounded < 0 ? 0 : no_indicative[id].sigma2_2.rounded}`, xv1_2 - 3, rw - 3, { align: "right" });
					doc.setFontSize(6);
					doc.setFont("helvetica", "normal");
					doc.text(`${no_indicative[id].uKu.rounded < 0 ? 0 : no_indicative[id].uKu.rounded}`, xv1_3 - 3, rw - 3, { align: "right" });
					doc.text(`${no_indicative[id].p.rounded < 0 ? 0 : no_indicative[id].p.rounded}`, rND - 3, rw - 3, { align: "right" });

					doc.line(rST, rw, rND, rw);
				}

				// -- Vertical lines
				doc.line(xv1_1, yv1, xv1_1, rw);
				doc.line(xv1_2, yv1, xv1_2, rw);
				doc.line(xv1_3, yv1, xv1_3, rw);

				// -- Indicatives
				const yv2 = (rw += h1);

				if (Object.keys(indicative).length > 0) {
					// -- Title
					doc.setFontSize(8);
					doc.setFont("helvetica", "bold");
					doc.text("VALORES INDICATIVOS |", 62, rw - 3, { align: "left" });
					doc.setFont("helvetica", "normal");
					doc.text("INDICATIVE VALUES | VALEURS INDICATIF", 97, rw - 3, { align: "left" });

					doc.line(rST, rw, rND, rw);

					// -- Each
					for (let id in indicative) {
						doc.setFontSize(7);
						doc.setFont("helvetica", "bold");
						doc.text(`${indicative[id].sigma.params.name} (${indicative[id].sigma.params.format})`, rST, rw + 3, { align: "left" });
						doc.setFontSize(6);
						doc.setFont("helvetica", "normal");
						doc.text(`${indicative[id].sigma.params.name_en} | ${indicative[id].sigma.params.name_fr}`, rST, rw + 5.5, { align: "left" });

						rw += 7;

						doc.addImage(CERT_SYM, "PNG", xv1_2 - 13, rw - 5, 2, 2, undefined, "FAST");

						doc.setFontSize(7);
						doc.setFont("helvetica", "bold");
						doc.text(`${indicative[id].sigma2_2.rounded}`, xv1_2 - 3, rw - 3, { align: "right" });
						doc.setFontSize(6);
						doc.setFont("helvetica", "normal");
						doc.text(`${indicative[id].p.rounded}`, rND - 3, rw - 3, { align: "right" });

						doc.line(rST, rw, rND, rw);
					}

					// -- Vertical lines
					doc.line(xv1_2, yv2, xv1_2, rw);
					doc.line(xv1_3, yv2, xv1_3, rw);
				}

				// -- Footer info
				doc.setFontSize(10);
				doc.text(`${mnt}/${date.getFullYear()}`, 49.5, 268, { align: "right" });

				doc.setFontSize(14);
				doc.text(`${current_sample.lot}`, 39, 282, { align: "right" });

				// -- Page 2
				doc.addPage();
				doc.addImage(CERT_BG2, "JPEG", 0, 0, 210, 297, undefined, "FAST");

				// -- Upload
				var pdf = doc.output("blob");
				const filename = `certificate-${current_sample.lot}-${current_sample.name}`;

				f7.store.dispatch("uploadFile", {
					pdf_blob: pdf,
					filename: filename,
				});

				// -- Save
				doc.save(`Certificado-${current_sample.lot}_${mnt}${date.getFullYear()}.pdf`);
			},

			loadPageContent() {
				const self = this;
				self.getS().then((s) => {
					const current_sample = self.samples[self.id];
					self.current_s = current_sample;

					self.getSamplesConfig().then((config) => {
						self.setConfig(config);

						self.getAllLabs().then((labs) => {
							self.setLabs(labs);
							self.setSampleLabs(current_sample.labs);
							self.setParams(current_sample.params);
							self.setSamples(current_sample.forms);
							self.getSampleVars(current_sample.id).then((ignored) => {
								self.setVars(ignored);
								self.getResult();

								/****/
								//self.doCertificate();
								/****/
							});
						});
					});
				});
			},
			doCertificate() {
				f7.store.dispatch("preloader", true);
				setTimeout(this.certificate, 500);
				//this.certificate();
			},
			certificate() {
				const self = this;

				const fullForms = self.getSFull;
				const ce = self.getCE;
				const calcs = self.getCalcs;
				const zscore = self.getZScore;
				const vars = self.getVars;
				const allLabs = self.allLabs;
				const c_sample = self.current_s;

				var crt = {};
				var labs_ids = [];

				for (var x = 0; x < fullForms.length; x++) {
					var f = { ...fullForms[x] };

					const param_29 = Object.keys(f).find((key) => f[key].params && f[key].params.id === 29);

					const id_lab = f.id_lab;
					const lab_name = f.name;

					labs_ids.push(id_lab);

					delete f.id_lab;
					delete f.name;

					for (var p_k in f) {
						if (p_k == param_29) continue;

						var p_info = f[p_k];
						var method = p_info.method;
						var c = ce[p_k];
						var calc = calcs[p_k];
						var score = zscore[p_k];

						if (score && !crt[score.params.order])
							crt[score.params.order] = {
								labs: {},
								labs_d: {},
								data: {},
								bylab: {},
								graphs: [],
								param: {},
								sample: c_sample,
							};

						// LABS_D
						var l_n_p = 0;
						var l_n_p_a = [];

						var l_n_l = 0;
						var l_n_l_a = [];
						if (c) {
							for (var l_i in c.samples) {
								if (Number.isNaN(c.samples[l_i].value)) {
									l_n_p++;
									l_n_p_a.push(parseInt(l_i) + 1);
								}

								if (`${c.samples[l_i].raw}`.includes("<") || `${c.samples[l_i].raw}`.includes(">")) {
									l_n_l++;
									l_n_l_a.push(parseInt(l_i) + 1);
								}
							}
						}

						var ign = 0;
						var ign_a = [];

						if (score && vars.ignore && vars.ignore[score.params.order]) {
							if (vars.ignore[score.params.order].length) {
								for (var i = 0; i < vars.ignore[score.params.order].length; i++) {
									if (`${vars.ignore[score.params.order][i]}` == "true") {
										ign++;
										ign_a.push(c.samples[i].order);
									}
								}
							} else {
								for (var li in vars.ignore[score.params.order]) {
									if (`${vars.ignore[score.params.order][li]}` == "true") {
										ign++;
										ign_a.push(c.samples[li].order);
									}
								}
							}
						}

						crt[score.params.order].labs_d = {
							labs_p: c.samples.length,
							labs_sent: c.samples.length - (l_n_p - l_n_l),
							labs_acc: c.samples.length - l_n_p,
							labs_del: l_n_l,
							labs_del_codes: l_n_l_a,

							labs_acc_by_test: calc.p.value,
							labs_del_by_test: ign,
							labs_del_by_test_codes: ign_a,
							labs_disc_by_test: l_n_p_a.length,
							labs_disc_by_test_codes: l_n_p_a,
						};

						// DATA
						crt[score.params.order].data = {
							val_ass: Number.isNaN(parseInt(calc.promedio.rounded)) ? "" : calc.promedio.rounded,
							sigma_o: Number.isNaN(calc.promedio.rounded) ? "" : calc.o.rounded,
							r_zscore: Number.isNaN(calc.promedio.rounded) ? "" : score.results,
							inc: {
								in_u: calc.uKu.rounded,
								comment: vars.indicative && vars.indicative[score.params.order] ? vars.indicative[score.params.order] : false,
								u_per_cent: self.round((parseFloat(calc.uKu.value) / parseFloat(calc.promedio.value)) * 100, 1),
							},
						};

						// BYLAB
						var graphs_tmp = [];

						for (var xy = 0; xy < score.values.length; xy++) {
							var zscore_v = parseFloat(score.values[xy].disc.rounded);
							var k_lab = self.findKeyInObject(c.samples, "id_lab", score.values[xy].id_lab);

							if (Number.isNaN(zscore_v)) {
								crt[score.params.order].bylab[score.values[xy].lab_order] = {
									id_lab: score.values[xy].id_lab,
									res: c.samples[k_lab].raw,
									method: c.samples[k_lab].method,
									is_calc: "",
									zscore: {
										value: "",
										eval: "",
									},
								};
							} else {
								crt[score.params.order].bylab[score.values[xy].lab_order] = {
									id_lab: score.values[xy].id_lab,
									res: score.values[xy].value.rounded,
									method: c.samples[k_lab].method,
									is_calc: score.params.is_calc,
									zscore: {
										value: `${self.round(zscore_v, 2)}`,
										eval: Math.abs(zscore_v) > 3 ? "NO Satisfactorio" : Math.abs(zscore_v) > 2 && Math.abs(zscore_v) < 3 ? "Cuestionable" : "Satisfactorio",
									},
								};
							}

							graphs_tmp[score.values[xy].lab_order] = { Lab: parseInt(score.values[xy].lab_order) + 1, ZScore: Number.isNaN(zscore_v) ? 0 : zscore_v };
						}

						graphs_tmp.sort((a, b) => (parseInt(a.Lab) > parseInt(b.Lab) ? 1 : parseInt(b.Lab) > parseInt(a.Lab) ? -1 : 0));

						crt[score.params.order].graphs = graphs_tmp;

						// PARAM
						crt[score.params.order].param = score.params;
					}
				}

				// LABS
				var k = "";
				var labs = {};

				for (var i = 0; i < labs_ids.length; i++) {
					k = self.findKeyInObject(allLabs, "id", labs_ids[i]);
					labs[labs_ids[i]] = allLabs[k];
				}

				for (var x in crt) crt[x].labs = labs;

				const certi = new cert();
				certi.generatePDF(crt);

				f7.store.dispatch("preloader", false);
			},
		},
		mounted() {
			const self = this;
			self.loadPageContent();
		},
	};

	/*
https://ishwar-rimal.medium.com/generating-pdf-with-electron-js-31b59ac93249
https://jsreport.net/blog/using-electron-to-create-pdf-reports
*/
</script>
