<template>
    <f7-page id="loginPage" name="login" login-screen>
        <!-- Top Navbar -->
        <f7-navbar>
            <f7-nav-left>
                <f7-link><img src="static/logotipo.png"></f7-link>
            </f7-nav-left>
        </f7-navbar>
        <!-- Toolbar-->
        <f7-toolbar id="loginToolbar" bottom>
            <f7-link>Términos y Condiciones</f7-link>
            <f7-link>Contacto</f7-link>
        </f7-toolbar>
        <!-- Page content-->
        <f7-block>
            <f7-row no-gap>
                <f7-col width="20"></f7-col>
                <f7-col>
                    <f7-block-title class="loginTitle">Sign In</f7-block-title>
                    <f7-list form @submit="doLogin" no-hairlines>
                        <f7-list-input class="loginEl" type="text" placeholder="Nombre de usuario" validate-on-blur @input="username = $event.target.value">
                            <f7-icon slot="media"><img src="static/icons/icono-usuario.png"></f7-icon>
                        </f7-list-input>
                        <f7-list-input class="loginEl" type="password" placeholder="Contraseña" validate-on-blur @input="password = $event.target.value">
                            <f7-icon slot="media"><img src="static/icons/icono-password.png"></f7-icon>
                        </f7-list-input>
                        <f7-button type="submit">Entrar</f7-button>
                    </f7-list>
                </f7-col>
            </f7-row>
        </f7-block>
    </f7-page>
</template>

<style>
    :root {
        --f7-navbar-height: 60px;
    }
    #loginToolbar .toolbar-inner {
        justify-content: flex-start;
    }

    .login-screen-page .block {
        margin: auto auto auto 0;
    }

    .loginEl .item-input {
        flex-direction: row-reverse;
        border-bottom: 1px solid;
        padding-left: 0;
    }

    .loginEl .item-media {
        margin-left: var(--f7-list-item-media-margin);
    }

    .loginEl .item-media i {
        margin: 0 auto;
    }

    .loginEl .item-inner {
        margin-left: 0;
    }
</style>

<script>
    import { f7, useStore } from 'framework7-vue';

    export default {
        setup() {},
        props: {
            f7router: Object,
            f7route: Object
        },
        data() {
            return {
                username: '',
                password: ''
            };
        },
        methods: {
            doLogin(e) {
                const self = this;

                const username = self.username;
                const password = self.password;

                f7.store.dispatch('doLogin', { username, password }).then(() => {
                    self.f7router.refreshPage();
                }).catch(function(err) {
                    f7.dialog.alert(err);
                });

                e.preventDefault();
                return false;
            },
            doKeyLogin() { 
                const self = this;
                f7.store.dispatch ( 'doKeyLogin', self.f7route.hash)
                .then ( r => self.f7router.refreshPage() )
                .catch ( err => f7.dialog.alert( err ) );
            }
        },
        mounted() {
            if( this.f7route.hash !== undefined )
                this.doKeyLogin()
        },
    }
</script>